import { useFormContext } from 'react-hook-form'

const Checkbox = ({
  label,
  name,
  value,
  disabled = false,
  ariaLabel = null,
  onChange = () => {},
  checkboxClass = '',
  required = false,
  children
}) => {
  const { register, formState: { errors } } = useFormContext()

  return (
    <div className={`input-group checkbox ${errors[name] ? 'error' : ''} ${checkboxClass !== '' && checkboxClass}`} role='button'>
      <label className='checkbox-label'>
        <input
          type='checkbox'
          {...register(name, {
            onChange,
            required
          })}
          value={value}
          disabled={disabled}
          aria-label={ariaLabel || label || ''}
          data-testid={name}
        />
        <div className='checkmark'/>
        <span className='medium_1 checkbox-label__text'>{label || children}</span>
      </label>
    </div>
  )
}

export default Checkbox
