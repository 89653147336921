import { useGoogleLogin } from '@react-oauth/google'
import { images } from 'assets'
import { Mixpanel, Fullstory } from 'utils'
import { Button } from 'components'

const GoogleSignInBtn = ({ copy, onOAuthSuccess, type, style = '' }) => {
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      onOAuthSuccess(tokenResponse.access_token, 'Google')
    },
    onError: errorResponse => console.log(errorResponse)
  })

  const handleClick = () => {
    Mixpanel.track(`Click Google OAuth on Sign ${type === 'Signup' ? 'Up' : 'In'} Page`)
    Fullstory.track(`Click Google OAuth on Sign ${type === 'Signup' ? 'Up' : 'In'} Page`)
    googleLogin()
  }
  if (style === 'icon') {
    return <Button ariaLabel={copy} className='link' onClick={handleClick}><img src={images['referfriend-google']} alt={copy} /></Button>
  }

  return <Button className='medium_2 signup-oath-btn' onClick={handleClick} ariaLabel={copy}>
    <img alt={`Google Sign ${type}`} src={images['google-btn']}/>
    {copy}
  </Button>
}

export default GoogleSignInBtn
