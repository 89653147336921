import { seoTitleTemplate, Mixpanel } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { useEffect } from 'react'
import { PageLoading } from 'components'
import { getCoreUser } from 'slices/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import AddClientsLink from './partials/AddClientsLink'
import ClientList from './partials/ClientList'
import ActAsMyselfButton from './partials/ActAsMyselfButton'
import { getRiaUsersList, impersonateUser } from 'slices/riaSlice'

const RIADashboard = () => {
  const { pageLoading } = useSelector((state) => state.ria)

  const dispatch = useDispatch()
  const history = useHistory()

  const viewAs = (impersonationUserId) => {
    dispatch(impersonateUser(impersonationUserId)).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        dispatch(getCoreUser())
        history.push('/portfolio')
      }
    })
  }

  useEffect(() => {
    dispatch(getRiaUsersList())
    Mixpanel.track('View Advisor Dashboard')
  }, [])

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Advisor Dashboard')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Advisor Dashboard')} />
      <div className='page-container'>
        <div className='inner-container'>
          <h1>Advisor Dashboard</h1>
          <div className='large_1'>Client List</div>
          <ClientList viewAs={viewAs} />
          <ActAsMyselfButton viewAs={viewAs} />
          <AddClientsLink />
        </div>
      </div>
    </>
  )
}

export default RIADashboard
