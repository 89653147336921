import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ConditionalRender, PageLoading, Button } from 'components'
import SeoMeta from 'components/Global/SeoMeta'
import { seoTitleTemplate, MOBILE_WIDTH_SIZE, Mixpanel, Fullstory } from 'utils'
import InvestSection from './partials/InvestSection'
import { getBundles, getInvestPage } from 'slices/investSlice'
import useFirstRender from 'hooks/useFirstRender'
import MessagingCarousel from 'components/Global/MessagingCarousel'
import InvestAccountBanner from './partials/InvestAccountBanner'
import { images } from 'assets'
import SearchCompaniesInput from './partials/SearchCompaniesInput'
import useWindowSize from 'hooks/useWindowSize'
import useScrollEndHeight from 'hooks/useScrollEndHeight'
import LinqtoCapitalPlacementCopy from 'components/Global/LinqtoCapitalPlacementCopy'

const InvestRetailBanner = ({ updateAccreditation }) => (
  <div className='flex flex-col items-start px-4 py-6 mb-6 bg-white border rounded-lg border-gray6 md:flex-row md:items-center'>
    <div className='flex flex-row md:max-w-[70%] items-start md:items-center'>
      <img src={images['lock-person']} alt='Update Accreditation' className='h-6 mr-6'/>
      <div className=' text-gray4 medium_1 md:max-w-[80%]'>These investment options are only available to accredited investors. Please update your accreditation status to place an order.</div>
    </div>
    <Button className='ml-12  mt-[8px] md:mt-0 md:ml-auto text-connectedCobalt medium_2' onClick={updateAccreditation}>Update Accreditation</Button>
  </div>
)

const InvestRevamp = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const firstRender = useFirstRender()
  const { width } = useWindowSize()
  const heightRef = useScrollEndHeight()
  
  const { isRetailPurchaser, companies, accreditedStatus, bundle, retailCompanies, nonRetailCompanies, verticals, featuredCompanies } = useSelector(state => state.investSlice)
  const { featureFlags } = useSelector(state => state.userSlice)
  const { investContentCards, isMixpanelExist } = useSelector(state => state.commonSlice)
  
  const { BundlePurchaseEnabled, RetailPurchaseAllowed } = featureFlags || {}

  const [pageLoading, setPageLoading] = useState(true)
  const [showBanner, setShowBanner] = useState(true)

  const pageTitle = seoTitleTemplate('Invest')
  const pageDescription = 'Linqto is private investing made simple. Browse our product list to learn more about our offerings and to begin your journey in private equity investing.'
  const isMobile = width <= MOBILE_WIDTH_SIZE

  useEffect(() => {
    (async () => {
      const res = await dispatch(getInvestPage('', '', '', firstRender))
      const { meta } = res
      if (meta.requestStatus === 'fulfilled') {
        await dispatch(getBundles())
        // after fetching data, go down to the users last scroll position
        const investFeedScroll = localStorage?.getItem('investFeedScroll')
        if (investFeedScroll) {
          window.scrollTo({ top: parseInt(investFeedScroll) })
          localStorage?.removeItem('investFeedScroll')
        } else {
          window.scrollTo({ top: 0 })
        }
        setPageLoading(false)
      }
    })()
  }, [])

  useEffect(() => {
    return () => {
      if (heightRef?.current > 0) {
        Mixpanel.track('Scroll on Product Invest Page', { 'Amount scrolled': `${heightRef?.current}%` })
      }
    }
  }, [])

  useEffect(() => {
    if (isMixpanelExist) {
      Mixpanel.setPeople({ 'Is Accredited': accreditedStatus === 'ACCREDITED' })
    }
    Fullstory.setUserProperty({ is_accredited: accreditedStatus === 'ACCREDITED' })
  }, [isMixpanelExist, accreditedStatus])

  const viewAllCompanies = () => { 
    if(RetailPurchaseAllowed && isRetailPurchaser && accreditedStatus !== 'ACCREDITED') {
      history.push('/products-list?list=retail')
    } else {
      history.push('/products-list')
    }
  }

  const showProductDetails = (item, section = '') => {   
    localStorage?.setItem('investFeedScroll', window.scrollY)
    history.push(`/products/${item?.urlName}`)
    Mixpanel.track('Click Company on Product Invest Page', { 'Company Name': item?.companyName, 'Share Price': item?.sharePrice, 'Section': section })
    Fullstory.track('Card Click', { name: 'Company', company: item?.companyName, share_price: item?.sharePrice })
  }

  const showBundleDetails = (item) => {
    localStorage?.setItem('investFeedScroll', window.scrollY)
    Mixpanel.track('Click Bundle on Product Invest Page', { Companies: item?.companies, 'Bundle ID': item?.bundleId })
    history.push(`/bundle/${item?.bundleId}`)
  }

  const updateAccreditation = () => { 
    history.push('/investor-status')
  }

  const onItemClick = (vertical) => {
    if(vertical === 'all') {
      history.push('/products-list')
    } else {
      history.push(`/products-list?vertical=${encodeURIComponent(vertical)}`)
    }
  }

  const maxVerticalCount = () => {
    if (width < 768) return 4;
    if (width < 1024) return 2;
    if (width < 1280) return 3;
    return 4
  }

  if (pageLoading) {
    return <>
      <SeoMeta title={pageTitle} description={pageDescription} />
      <PageLoading />
    </>
  }
  
  return (
    <>
      <SeoMeta title={pageTitle} description={pageDescription} />
      <div className='page-container invest-container'>
        <div className='w-full ml-auto mr-auto lg:max-w-6xl'>
          <div className='container'>
            <InvestAccountBanner setShowBanner={setShowBanner} />
            <div className='relative'>
              <div className='flex mb-4 -mr-6 lg:mb-8 md:mr-0'>
                {width >= MOBILE_WIDTH_SIZE && <div className='flex gap-2 pr-6 overflow-x-auto md:pr-0' role='group' aria-label='Filter sectors' style={{scrollbarWidth: 'none'}}>
                  {verticals?.length > 0 && verticals?.slice(0, maxVerticalCount()).map((vertical, i) =>
                    <Button
                      className='px-4 rounded-[100px] bg-gray1 flex-[0_0_auto] whitespace-nowrap body_1 h-10 md:h-8'
                      key={`vertical${i}`}
                      data-testid={`vertical-${i}`}
                      ariaLabel={`Filter by ${vertical}`} onClick={() => onItemClick(vertical)}>{vertical}</Button>
                  )}
                  <select
                    onChange={(e) => {
                      onItemClick(e.target.value)
                    }}
                    data-testid='sector-select'
                    className='appearance-none rounded-[100px] bg-gray1 body_1 border-0 hover:border-0 w-[110px] pr-0 h-10 md:h-8'
                    aria-label='Filter by sectors'>
                    <option value=''>Sectors</option>
                    <option value='all'>All Sectors</option>
                    { verticals && verticals.length > 0 && verticals.map((e, i) => <option value={e} key={`vertical-${i}`}>{e}</option>) }
                  </select>
                </div>}
                {!isMobile && <SearchCompaniesInput />}
              </div>
              {isMobile && <SearchCompaniesInput />}  
              {investContentCards?.length ? (
                <div className={`messaging-carousel-container ${!showBanner ? '-mt-5 md:-mt-9' : 'mb-10'}`}>
                  <MessagingCarousel data={investContentCards} page='Invest' />
                </div>) : null}
            </div>
            <ConditionalRender isVisible={!RetailPurchaseAllowed || !isRetailPurchaser || accreditedStatus === 'ACCREDITED'}>
              {featuredCompanies?.length > 0 && <InvestSection title='Featured' list={featuredCompanies} listType='products' listStyle='thumbnail' onItemClick={showProductDetails} />}
              {BundlePurchaseEnabled && bundle?.bundles?.length > 0 && <InvestSection title='Bundles' list={bundle?.bundles} listType='bundles' listStyle='thumbnail' onItemClick={showBundleDetails} />}
              <InvestSection title='Companies' list={companies?.slice(0, isMobile ? 10 : 20) } listType='products' listStyle='list' onItemClick={showProductDetails} verticals={verticals} />
              <div className='flex justify-center w-full'>
                <Button onClick={viewAllCompanies} mode='secondary'>View All</Button>
              </div>
            </ConditionalRender>
          </div>
        </div>
        <ConditionalRender isVisible={RetailPurchaseAllowed && isRetailPurchaser && accreditedStatus !== 'ACCREDITED'}>
          <div className='inner-container'>
            <div className='container'>
              <InvestSection title='Invest Now' list={retailCompanies} listType='products' listStyle='thumbnail' onItemClick={showProductDetails} />
            </div>
          </div>
          <div className='bg-[#F9F9F9] pt-8 md:pt-16 -ml-12 -mr-12 pl-12 pr-12 md:ml-[-150px] md:mr-[-150px] md:pl-0 md:pr-0'>
            <div className='inner-container'>
              <div className='container'>
                <h2 className='mb-4 large_1'>Get Access to All Our Companies</h2>
                <InvestRetailBanner updateAccreditation={updateAccreditation} />
                <InvestSection title='' list={nonRetailCompanies?.slice(0, isMobile ? 10 : 20)} listType='products' listStyle='list' onItemClick={showProductDetails}/>
                <div className='flex justify-center w-full'>
                  <Button onClick={viewAllCompanies} mode='secondary'>View All</Button>
                </div>
              </div>
            </div>
          </div>
        </ConditionalRender>
      </div>
      <LinqtoCapitalPlacementCopy />
    </>
  )
}

export default InvestRevamp
