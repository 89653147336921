import Button from '../../Global/Button'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import Checkbox from 'components/Global/Inputs/Checkbox'

const OriginationOfferCompanyCTA = ({ submitOrder }) => {
  const { submitLoading } = useSelector((state) => state.origination)
  const [disabled, setDisabled] = useState(true)
  const methods = useForm()
  const { getValues } = methods

  return (
    <div className={'section-full origination-detail-cta'}>
      <FormProvider {...methods}>
        <form>
          <div className='origination-detail-confirm'>
            <Checkbox
              label='I confirm I have read these documents and agree to be bound by
            their provisions, and that I have the authority to sell the
            shares I am offering. I understand that the transaction is not
            complete until the shares have been successfully transferred to
            Linqto.'
              name='confirmOrder'
              value='1'
              onChange={() => setDisabled(!getValues('confirmOrder'))}
              ariaLabel='confirm-origination'
            />
          </div>
          <div className='section-nar'>
            <Button
              customClass='place-order-button'
              onClick={submitOrder}
              loading={submitLoading}
              disabled={submitLoading || disabled}
              type='submit'
            >
              Submit
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}

export default OriginationOfferCompanyCTA
