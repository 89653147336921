import Modal from 'components/Global/Modal'

const SaveProfileModal = ({ hideModal }) => {
  return (
    <Modal
      modalHeader='Successful Save'
      crossToClose
      clickToClose
      pressToClose
      hideModal={() => hideModal(false)}
    >
      <div className='medium_1 content'>
          Profile Successfully Updated!
      </div>
    </Modal>
  )
}

export default SaveProfileModal
