import { Button } from 'components'
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import Checkbox from 'components/Global/Inputs/Checkbox'
import { useHistory, useLocation } from 'react-router-dom'
import { Mixpanel, formatDecimal } from 'utils'

const sellCompanyIdError = 'If you wish to Pay with Shares, please select company or toggle off. '

const OrderConfirmationForm = ({
  purchaseWithShares,
  purchaseWithCash,
  handleCommitOrder,
  selectedAccount
}) => {
  const history = useHistory()
  const location = useLocation()
  const { handleSubmit, formState: { errors } } = useFormContext()
  const { commitLoading, order: { total } } = useSelector((state) => state.placeOrderSlice)
  const cashBalance = selectedAccount?.amountAvailable

  const goToAddFunds = () => {
    Mixpanel.track('Click Add Funds on Buy Order Page')
    // Send the required amount to the add funds page and set buy page path in local storage
    const amount = formatDecimal(total - cashBalance, true, 2, true)
    localStorage?.setItem('from-buy-order-page', location.pathname + location.search)
    history.push(`/cash-account/add-funds?amountRequired=${amount}${selectedAccount?.entityId ? `&entityId=${selectedAccount?.entityId}` : ''}`)
  }

  const trackPlaceOrder = () => {
    Mixpanel.track('Click Place Order Button', {
      Amount: total,
      'Cash Balance': cashBalance
    })
  }

  const disabled = commitLoading || Object.keys(errors).length > 0 || total > cashBalance || (!purchaseWithShares && !purchaseWithCash)

  return (
    <form onSubmit={handleSubmit(handleCommitOrder)}>
      <div className='buy-order-section'>
        <div className='buy-order-section-detail buy-order-end'>
          <div className='buy-order-row'>
            <Checkbox
              label='I confirm I have read these documents and agree to be bound by their provisions.'
              name='confirmOrderCheckbox'
              ariaLabel='confirmOrderCheckbox'
              disabled={commitLoading}
              checkboxClass='confirm-checkbox'
            />
          </div>
          <div className='buy-order-row'>
            <span className='medium_1 gray4 final-price'>Final price may vary up to 5% during periods of high volume.</span>
          </div>
          {total > cashBalance && <div className='buy-order-row sell-offer-row'>
            <span className='red'>Please <Button ariaLabel='Add funds to your account' className='inline-text-link' onClick={goToAddFunds}>add funds</Button> to complete this order</span>
          </div>}
          {errors?.sellCompanyId && <div className='buy-order-row total-spaced'>
            <span className='red'>{sellCompanyIdError}</span>
          </div>}
          {errors?.sharesInsufficent && <div className='buy-order-row total-spaced'>
            <span className='red'>{errors?.sharesInsufficent?.message}</span>
          </div>}
          <div className='buy-order-row flex-end'>
            <Button
              type='submit'
              loading={commitLoading}
              disabled={disabled}
              onClick={trackPlaceOrder}
              customClass='place-order-button'
            >
            Place Order
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default OrderConfirmationForm
