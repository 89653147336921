import { useSelector } from 'react-redux'
import InvestmentItem from '../InvestmentItem'
import InvestmentItemTitle from '../InvestmentItemTitle'
import { ConditionalRender } from 'components'
import CashBalanceItem from '../CashBalanceItem'

const CurrentInvestmentsTab = ({ groupBy }) => {
  const { companies, entities, totalCashAvailable, userCashAvailable, cashPaymentEnabled, hasWalletDeposit } = useSelector((state) => state.portfolioSlice)
  const { firstName, lastName } = useSelector((state) => state.userSlice)
  const hasEntities = entities?.length > 0

  const accountTotal = (filteredCompanies, totalValue) => {
    if (cashPaymentEnabled) {
      return totalValue + filteredCompanies.reduce((total, obj) => obj.purchased + total, 0)
    } else {
      return filteredCompanies.reduce((total, obj) => obj.purchased + total, 0)
    }
  }
  const accountShares = (filteredCompanies) => {
    return filteredCompanies.reduce((total, obj) => obj.shares + total, 0)
  }

  const groupedCompanies = companies?.reduce((acc, current) => {
    const x = acc.find((item) => item.companyId === current.companyId)
    if (!x) {
      return acc.concat([current])
    } else {
      return acc
    }
  }, [])
  const groupedByInvestment = []

  groupedCompanies?.forEach((company) =>
    groupedByInvestment.push({
      ...company,
      purchased: accountTotal(
        companies.filter((a) => a.companyId === company.companyId), 0
      ),
      shares: accountShares(
        companies.filter((a) => a.companyId === company.companyId), 0
      )
    })
  )

  const companiesByAccount = (filteredCompanies) => {
    if (filteredCompanies.length > 0) {
      return filteredCompanies.map((company, i) => (
        <InvestmentItem
          key={`sub_${i}${company.companyId}`}
          company={company}
          type='current'
        />
      ))
    } else {
      return (
        <div className='current-investment-item current-investment-empty'>
          You haven&apos;t made any investments in this account yet.
          {cashPaymentEnabled && !hasWalletDeposit === 0 && 'Add funds to your account to start investing.'}
        </div>
      )
    }
  }
  const sortedArr = []

  if (entities?.length > 0) {
    sortedArr.push({
      entityId: null,
      cashAvailable: userCashAvailable,
      name: `${firstName} ${lastName}`,
      total: accountTotal(companies.filter((item) => !('entityId' in item)), userCashAvailable)
    })

    entities.forEach((entity) =>
      sortedArr.push({
        entityId: entity.entityId,
        cashAvailable: entity.cashAvailable,
        name: entity.name,
        total: accountTotal(
          companies.filter((a) => a.entityId === entity.entityId), entity.cashAvailable
        )
      })
    )
    sortedArr.sort((a, b) => b.total - a.total)
  }

  return (
    <div className='mt-6'>
      <ConditionalRender
        isVisible={!hasEntities || (hasEntities && groupBy === 'investment')}
      >
        {groupedByInvestment.map((company) => (
          <InvestmentItem
            key={company.companyId}
            company={company}
            type='current'
          />
        ))}
        {cashPaymentEnabled && <CashBalanceItem groupBy='investment' totalCash={totalCashAvailable} hasEntities={hasEntities} />}
      </ConditionalRender>
      <ConditionalRender isVisible={hasEntities && groupBy === 'account'}>
        {sortedArr.map((entity, i) => (
          <div key={`entity_${i}`}>
            <InvestmentItemTitle
              entityName={entity.name}
              total={entity.total}
            />
            {entity.entityId === null
              ? companiesByAccount(
                companies.filter((item) => !('entityId' in item))
              )
              : companiesByAccount(
                companies.filter((a) => a.entityId === entity.entityId)
              )}
            {cashPaymentEnabled && <CashBalanceItem groupBy='account' totalCash={entity.cashAvailable} entityId={entity.entityId} hasEntities={hasEntities}/>}
          </div>
        ))}
      </ConditionalRender>
    </div>
  )
}

export default CurrentInvestmentsTab
