import { images } from 'assets'
import SeoMeta from 'components/Global/SeoMeta'
import { Button, PageLoading } from 'components'
import RadioButton from 'components/Global/Inputs/RadioButton'
import useWindowSize from 'hooks/useWindowSize'
import { useEffect, useState } from 'react'
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form'
import { MOBILE_WIDTH_SIZE, seoTitleTemplate, formatDateMDY, truncate, Mixpanel } from 'utils'
import { getCompanyIpoData, resetSubmitSelection, saveUserCompanyIpoChoice } from 'slices/exitSlice'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import TooltipLabel from 'components/Global/TooltipLabel'
import Select from 'components/Global/Inputs/Select'
import { getFaqs } from 'slices/commonSlice'

const OPTIONS = [
  { label: 'Transfer shares to my brokerage account', value: 'BROKERAGE' },
  { label: 'Deliver proceeds to my Linqto cash account', value: 'CASH' }
]

const IPOprocess = ['Pre-IPO', 'arrow', 'IPO Day', 'arrow', 'Lock-up Period', 'arrow', 'Post Lock-up']

const OptionsContainer = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { companyId } = useParams()
  const [loading, setLoading] = useState(false)
  const { sharesOwners, didSubmitSelection, canChangeDecision, companyName } = useSelector(state => state.exit)
  const { handleSubmit, formState: { errors }, watch, control, setValue, setError, clearErrors } = useFormContext()

  const selectedSharesOwner = watch('selectedSharesOwner')
  const choice = watch('choice')

  // initialize form state when user first comes to page (sets radio button to checked if previous choice exists - transferChoice)
  useEffect(() => {
    if (sharesOwners.length) {
      if (!selectedSharesOwner) {
        setValue('selectedSharesOwner', sharesOwners?.[0])
        setValue('choice', sharesOwners?.[0]?.transferChoice?.choice || '')
      }
    }
  }, [sharesOwners, selectedSharesOwner])

  // clear brokerInfo error when the user choice changes
  useEffect(() => {
    clearErrors('brokerInfo')
  }, [choice])

  /**
   * get the transferChoice - previously selected user choice (if exists), entityId (if exists) and userId (if exists - user can select entity)
   * and call POST API with object, then follow up by calling GET API to update all UI with new state
   * @param {string} choice
   */
  const onSubmit = async ({ choice }) => {
    if (choice === 'BROKERAGE' && !selectedSharesOwner?.brokerInfo) {
      setError('brokerInfo', { message: 'To submit, add your Brokerage Account' })
    } else {
      clearErrors('brokerInfo')
      const { transferChoice, entityId, userId } = selectedSharesOwner || {}
      const obj = {
        choice,
        companyId,
        userCompanyIpoChoiceId: transferChoice?.userCompanyIpoChoiceId,
        entityId,
        userId
      }
      setLoading(true)
      await dispatch(saveUserCompanyIpoChoice(obj))
      // receive response from API with updated sharesOwners array of objects
      const response = await dispatch(getCompanyIpoData(companyId))
      Mixpanel.track('Click Submit on IPO Page', { 'Company Name': companyName, Method: choice })
      const { payload } = response || {}
      // find updated sharesOwner object to update RHF state (to update UI)
      let findShareOwnerObject = {}
      if (entityId) {
        findShareOwnerObject = payload?.sharesOwners?.find(obj => obj.entityId === entityId) || {}
      } else {
        findShareOwnerObject = payload?.sharesOwners?.find(obj => obj.userId === userId) || {}
      }
      setValue('selectedSharesOwner', findShareOwnerObject)
      setLoading(false)
    }
  }

  // if user clicks edit button, reset flag to allow user to select radio button
  const handleEdit = () => {
    dispatch((resetSubmitSelection(selectedSharesOwner?.entityId ? 'entity' : 'user')))
    Mixpanel.track('Click Edit on IPO Page')
  }

  /**
   * if user has multiple accounts, this function runs when user selects value from "Transact as" dropdown
   * @param {string} option
   */
  const handleChange = (option) => {
    // check if option that user selected exists within shareOwners array
    const currentSelectedShareOwner = sharesOwners?.find(item => item.userName === option || item.entityName === option) || {}
    // if option exists in array, set state to that object
    if (currentSelectedShareOwner) {
      setValue('selectedSharesOwner', currentSelectedShareOwner)
      // if newly selected object does not have transferChoice (previously selected choice), initialize choice to empty (no radio button selected)
      if (!currentSelectedShareOwner?.transferChoice) {
        setValue('choice', '')
      } else {
        // otherwise, set radio button to previously selected choice for current selectedSharesOwner object
        setValue('choice', currentSelectedShareOwner?.transferChoice?.choice)
      }
    }
  }

  // push to broker information page (append entityId to URL if exists for creation for entity)
  const handlePush = () => {
    const { entityId } = selectedSharesOwner || {}
    const brokerPush = `/broker-information${entityId ? `/${entityId}` : ''}?redirect=exit`
    history.push(brokerPush)
  }

  // check if user has broker account and render JSX for each flow (no broker account vs does have broker account)
  let shareOwnersContainer = ''
  let topCopy = ''
  let editAddImage = ''
  let brokerEquityFirmName = ''
  const doesUserHaveBrokerAccount = !!selectedSharesOwner?.brokerInfo
  const { accountNumber, equityFirmName } = selectedSharesOwner?.brokerInfo || {}
  const fourDigitAccountNumber = accountNumber?.substring(accountNumber?.length - 4)

  if (doesUserHaveBrokerAccount) {
    topCopy = `Shares will be sent to your provided brokerage account ending in ${fourDigitAccountNumber}`
    editAddImage = 'edit'
    brokerEquityFirmName = <span className='medium_1'>{equityFirmName}</span>
  } else {
    topCopy = 'You currently don’t have a brokerage account on file. We’ll need this to distribute your shares.'
    editAddImage = 'add'
  }

  shareOwnersContainer = (
    <>
      <span className='medium_1'>{topCopy}</span>
      <div className='brokerage-item'>
        <span className='medium_1'>My Brokerage</span>
        {brokerEquityFirmName}
        <Button onClick={handlePush} ariaLabel={`${editAddImage} Brokerage`}>
          <img src={images[editAddImage]} aria-label={editAddImage} alt={brokerEquityFirmName}/>
        </Button>
      </div>
      {errors?.brokerInfo && <span className='body_1 error-message'>{errors?.brokerInfo?.message}</span>}
    </>
  )

  // if user previously submitted a submission for ANY account
  if (((didSubmitSelection.user && !selectedSharesOwner?.entityId) || (didSubmitSelection?.entity && !!selectedSharesOwner?.entityId)) && selectedSharesOwner?.transferChoice) {
    const { transferChoice } = selectedSharesOwner || {}
    const { status } = transferChoice || {}
    const dropdownOptions = sharesOwners?.map(item => item.userName || item.entityName)
    let boxTitle = ''
    let boxSubCopy = ''
    let imgToRender = ''

    // if CASH - render CASH copy + image (depending on status)
    if (choice === 'CASH') {
      if (status === 'PENDING' || status === 'NEEDS_REVIEW') {
        boxTitle = 'Your selection has been submitted'
        boxSubCopy = 'We will be delivering proceeds to your Linqto cash account'
        imgToRender = images['selection-submitted']
      } else {
        boxTitle = 'Distribution successfully transferred'
        boxSubCopy = 'We have delivered the proceeds to your Linqto cash account'
        imgToRender = images.paid
      }
    } else {
      // else, render BROEKRAGE copy + image (depending on status)
      if (status === 'PENDING' || status === 'NEEDS_REVIEW') {
        boxTitle = 'Your selection has been submitted'
        boxSubCopy = `We will be sending the shares to your ${equityFirmName} account ending in ${fourDigitAccountNumber}.`
        imgToRender = images['selection-submitted']
      } else {
        boxTitle = 'Distribution successfully transferred'
        boxSubCopy = `Your shares were transferred to your ${equityFirmName} account ending in ${fourDigitAccountNumber}.`
        imgToRender = images.paid
      }
    }

    return (
      <div className='exit-choice-container box'>
        <h6 className='connectedCobalt'>What would you like to do with your shares?</h6>
        <div className='exit-choice-container__completed-ipo-request-container'>
          {dropdownOptions.length > 1 && <Controller
            control={control}
            name='transactAs'
            setValue={setValue}
            render={({ field }) => (
              <Select
                field={field}
                label='Transact as'
                name='transactAs'
                ariaLabel='transactAs'
                options={dropdownOptions}
                setValue={setValue}
                handleChange={e => handleChange(e)}
                className='w-100'
              />
            )}
          />}
          <img src={imgToRender} height={56} width={56} alt={boxTitle}/>
          <span className='text-center medium_2'>{boxTitle}</span>
          <span className='text-center medium_1'>{boxSubCopy}</span>
        </div>
        <div className='exit-choice-container__options-container__submit-btn'>
          <Button disabled={status === 'PROCESSED' || !canChangeDecision} onClick={handleEdit} mode='secondary'>Edit</Button>
        </div>
      </div>
    )
  } else {
    // get list of user and entity names to populate dropdown
    const dropdownOptions = sharesOwners?.map(item => item.userName || item.entityName)
    // if user wants to edit or submit for the first time, they will see this view
    if (selectedSharesOwner?.transferChoice?.status !== 'PROCESSED') {
      return (
        <div className='exit-choice-container box'>
          <h6 className='connectedCobalt'>What would you like to do with your shares?</h6>
          <div className='exit-choice-container__options-container'>
            {dropdownOptions.length > 1 && <Controller
              control={control}
              name='transactAs'
              setValue={setValue}
              render={({ field }) => (
                <Select
                  field={field}
                  label='Transact as'
                  name='transactAs'
                  ariaLabel='transactAs'
                  options={dropdownOptions}
                  setValue={setValue}
                  handleChange={e => handleChange(e)}
                />
              )}
            />}
            <span className='medium_2'>Select one choice:</span>
            <div className='radio-group'>
              {OPTIONS.map(({ label, value }, i) => <div key={i} className='flex-justify-start'>
                <RadioButton required className={`pointer medium_1 ${errors.choice && 'error'}`} label={label} name='choice' value={value} />
                {value === 'CASH' && <TooltipLabel label='' content='By picking the Cash Account option, you are agreeing for Linqto to place a market sell order of your shares and move the entirety of the proceeds in your Linqto Cash Account.' />}
              </div>)}
            </div>
          </div>
          {choice === 'BROKERAGE' && (
            <div className='exit-choice-container__brokerage-container'>
              <span className='medium_2'>My Brokerage Account</span>
              <br />
              {shareOwnersContainer}
            </div>)}
          {choice === 'CASH' && <span className='medium_1'>We will sell your shares as soon as we receive them and transfer that balance to your Linqto cash balance account</span>}
          <div className='exit-choice-container__options-container__submit-btn'>
            <Button loading={loading} onClick={handleSubmit(onSubmit)}>Submit</Button>
          </div>
        </div>
      )
    }
  }
}

const ExitIPO = () => {
  const { companyId } = useParams()
  const dispatch = useDispatch()
  const { width } = useWindowSize()
  const {
    companyName,
    companyIconUrl,
    ipoDetails,
    sharesOwners
  } = useSelector((state) => state.exit)
  const { faqs } = useSelector(state => state.commonSlice)
  const methods = useForm({ defaultValues: { choice: '' } })
  const [pageLoading, setPageLoading] = useState(true)
  const [selected, setSelected] = useState('')
  const { lockUpPeriodEndDisplayDate, latestNews } = ipoDetails || {}

  useEffect(() => {
    (async () => {
      const res = await dispatch(getCompanyIpoData(companyId))
      await dispatch(getFaqs('exitIpo'))
      setPageLoading(false)
      Mixpanel.track('View IPO Page', { 'Company Name': res?.payload?.companyName, 'Is Holder': !!res?.payload?.sharesOwners?.length })
    })()
  }, [])

  // click FAQ
  const handleFAQ = (i, faq) => {
    Mixpanel.track('Expand IPO FAQ Answer', { 'FAQ Question': faq?.question })
    setSelected(i === selected ? '' : i)
  }

  // news article click
  const handleClick = ({ articleTitle, articleUrl }) => {
    Mixpanel.track('Click News & Insights Article on IPO Page', { 'Article Title': articleTitle, 'Company Name': companyName })
    window.open(articleUrl)
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('IPO Company')} />
        <PageLoading />
      </>
    )
  }

  return (
    <FormProvider {...methods}>
      <div className='exit-ipo-container'>
        <img className='exit-ipo-image-background' src={width >= MOBILE_WIDTH_SIZE ? images.bgx2 : images['bgx2-mobile']} alt='Exit ipo background'/>
        <div className='page-container'>
          <div className='inner-container'>
            <div className='exit-ipo-container__box1'>
              <div className='exit-ipo-container__box1__company-info'>
                <h1 className='codec-cold-regular accessibleIndigo'>
                  <img className='rounded-full exit-ipo-container__logo' src={companyIconUrl} height={30} width={30} alt={`${companyName} IPO`} />
                  {companyName} IPO:
                </h1>
                <h2 className='exit-ipo-container__box1__title source-serif-4 connectedCobalt'>What Happens Next?</h2>
                <div className='mt-4 exit-ipo-container__box1 medium_4 accessibleIndigo'>With {companyName} publicly filing for IPO, we will use this page to provide pertinent updates, share information about the IPO process, and inform you as to what is needed to receive your shares in a timely manner.</div>
              </div>
              {sharesOwners?.length ? <OptionsContainer /> : null}
            </div>
          </div>
        </div>
        <div className='page-container'>
          <div className='inner-container'>
            <div className='exit-ipo-container__box2'>
              <div className='lg:mt-9'>
                <span className='heading_8_eyebrow gray3'>GET TO KNOW</span>
                <h3 className='accessibleIndigo exit-ipo-container__box2__title'>Actions that you need to take as a <span className='giant_1 connectedCobalt'>{companyName} shareholder</span></h3>
              </div>
              <div className='exit-ipo-container__box2__copy-container'>
                <div>
                  <span className='medium_2 gray5'>Decide how you want to receive your shares</span>
                  <br />
                  <span className='medium_1 gray5'>Once shares are distributed you will have the choice to receive cash proceeds or shares-in-kind from Linqto’s broker. If you know how you would like to receive your shares you can make a selection using the form above.</span>
                </div>
                {lockUpPeriodEndDisplayDate && <div>
                  <span className='medium_2'>Make selection before {formatDateMDY(lockUpPeriodEndDisplayDate)}</span>
                  <br />
                  <span className='medium_1'>Please be advised that if you do not make a selection by {formatDateMDY(lockUpPeriodEndDisplayDate)} and have a brokerage account on file, the shares will be sent to that brokerage account. Otherwise, the proceeds will be delivered to your Linqto Cash Account.</span>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='latest-news-container bg-gray1'>
        <div className='page-container'>
          <div className='inner-container'>
            <span className='heading_8_eyebrow gray3'>CATCH UP ON</span>
            <div>
              <h3 className='latest-news-container__title accessibleIndigo'>Latest News</h3>
            </div>
            <div className='news-container'>
              {latestNews.map((n, id) =>
                <Button key={n.articleUrl + id} className='bg-white news-card' onClick={() => handleClick(n)} ariaLabel={n.articleCategory} >
                  <div className='text-start news-category body_1 connectedCobalt'>{n.articleCategory}</div>
                  <div className='text-start news-title large_1 accessibleIndigo'>{truncate(n.articleTitle, 60)}</div>
                  <div className='text-start news-snippet medium_1 gray3'>{truncate(n.articleSnippet, 140)}</div>
                </Button>)}
            </div>
          </div>
        </div>
      </div>
      <div className='exit-ipo-container__box3'>
        <img className='exit-ipo-container__box3__background' src={width >= MOBILE_WIDTH_SIZE ? images['bgx-process'] : images['bgx-process-mobile']} alt='The IPO Process for Linqto Members'/>
        <div className='page-container'>
          <div className='inner-container'>
            <span className='heading_8_eyebrow gray1'>A DEEP DIVE INTO</span>
            <div>
              <h3 className='white exit-ipo-container__box3__title'>The IPO Process for<span className='giant_1 freshMint'> Linqto Members</span></h3>
            </div>
            <div className='exit-ipo-container__box3__ipo-process-container'>
              {IPOprocess.map((p, id) => p === 'arrow' ? <img key={p + id} className='arrow-image' src={images['decorative-arrow']} alt={p} />
                : <div key={p + id} className='exit-ipo-container__box3__ipo-process-container__box'>
                  <h4 className='exit-ipo-container__box3__ipo-process-container__box__title freshMint'>0{id / 2 + 1}</h4>
                  <div className='medium_4 connectedCobalt'>{p}</div>
                </div>)}
            </div>
          </div>
        </div>
      </div>
      <div className='exit-ipo-container__box3'>
        <div className='page-container'>
          <div className='inner-container'>
            <h2 className='freshMint'>01</h2>
            <h3 className='exit-ipo-container__box3__title connectedCobalt'>Pre-IPO</h3>
            <div className='gray5'>To learn more about the various types of routes companies can take to become public, please visit our latest <a className='link primary' href='https://www.linqto.com/blog/ipo-process/' target='_blank' rel='noopener noreferrer'>blog article.</a></div>
            <hr/>
            <div className='exit-ipo-container__box3__step'>
              <h2 className='freshMint'>02</h2>
              <h3 className='exit-ipo-container__box3__title connectedCobalt'>IPO Day</h3>
              <div className='gray5'>The day is finally here! The day that company founders and investors have been waiting years for, sometimes decades. This is the day that company shares will be available to purchase on a public exchange.</div>
              <img className='exit-ipo-container__box3__image' src={images['ipo-day']} alt='IPO Day'/>
            </div>
            <hr/>
            <h2 className='freshMint'>03</h2>
            <h3 className='exit-ipo-container__box3__title connectedCobalt'>Lock-up Period</h3>
            <p className='gray5'>A lockup period refers to a specific timeframe during which certain shareholders, typically company insiders, employees, or early investors, are prohibited from selling their shares following an initial public offering (IPO) or another significant corporate event.</p>
            <p>This restriction is imposed to prevent large shareholders from flooding the market with their shares immediately after the company goes public, which could potentially destabilize the stock price.
            </p>
            <div>The lockup period also applies to Linqto and its members and can last between 60 and 180 days. We will communicate updates to our members during this period. They are typically unique to each deal and can vary widely.</div>
            <hr/>
            <div className='exit-ipo-container__box3__step'>
              <h2 className='freshMint'>04</h2>
              <h3 className='exit-ipo-container__box3__title connectedCobalt'>Post Lock-up</h3>
              <div className='exit-ipo-container__box3__step'>After the lockup period, the following process will occur for Linqto members:
                <ul>
                  <li className='list-item'>Linqto receives communication from the company transfer agent that member shares are available and can be transferred to Linqto’s broker.</li>
                  <li className='list-item'>Once Linqto’s broker receives the shares, members will have made a choice before the lock-up period expired as to whether they wanted to receive cash proceeds or transfer their shares to an outside brokerage firm.</li>
                  <li className='list-item'>If you chose a brokerage firm, we will distribute your shares to the brokerage account that you have selected in the form above. You can also access your Brokerage details via your Investor Profile.</li>
                  <li className='list-item'>If you chose the Cash Account option, Linqto will place a market sell order of your shares and move the entirety of the proceeds in your Linqto Cash Account.</li>
                </ul>
                <img className='exit-ipo-container__box3__image' src={images['post-lock-up']} alt='Post Lock-up'/>
              </div>
            </div>
            <hr/>
          </div>
          <div className='page-container'>
            <div className='inner-container'>
              <div className='exit-ipo-container__box3'>
                <div>
                  <span className='heading_8_eyebrow gray3'>FROM THE COMMUNITY</span>
                  <h3 className='accessibleIndigo exit-ipo-container__box3__title'>Frequently <span className='giant_1 connectedCobalt'>Asked Questions</span></h3>
                </div>
              </div>
              {faqs && faqs.length && faqs.map((faq, i) => (
                <Button key={i} className='items-start w-full mt-6 mb-6' onClick={() => handleFAQ(i, faq)} ariaLabel={faq.question}>
                  <div className='flex flex-row justify-between'>
                    <p className='medium_4 text-start'>{faq.question}</p>
                    <img src={selected === i ? images.minus : images.plus} alt={`Expand ${faq.question}`} />
                  </div>
                  <p
                    className={`${selected === i ? '' : 'hidden'} flex text-start mt-2`}
                    dangerouslySetInnerHTML={{ __html: faq.answer }} />
                </Button>
              ))}
              <span className='medium_1'>Have more questions about the IPO process? <a href='/contact' className='underline medium_2 connectedCobalt'>Contact Us</a></span>
            </div>
          </div>
        </div>
      </div>
    </FormProvider>
  )
}

export default ExitIPO
