import { Button } from 'components'
import { Mixpanel } from 'utils'

const ProfileTabContainer = ({ onChange, tab }) => {
  const handleChange = (type) => {
    onChange(type)
    if (type === 'brokerage') {
      Mixpanel.track('View Brokerage Page')
    } else if (type === 'entity') {
      Mixpanel.track('View Entity Page')
    }
    window.history.replaceState(null, null, type === 'details' ? '/profile' : `?tab=${type}`)
  }

  return (
    <div className='page-tabs border-old'>
      <Button className={`tab bottom large_1 ${tab === 'details' && 'active connectedCobalt'}`} onClick={() => handleChange('details')}>Profile Details</Button>
      <Button className={`tab bottom large_1 ${tab === 'brokerage' && 'active connectedCobalt'}`} onClick={() => handleChange('brokerage')}>Brokerage</Button>
      <Button className={`tab bottom large_1 ${tab === 'entity' && 'active connectedCobalt'}`} onClick={() => handleChange('entity')}>Entity</Button>
    </div>
  )
}

export default ProfileTabContainer
