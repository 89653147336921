
/* Note
  Due to sometimes having few companies in this list (less than 7),
  the companies do not take up the full width of the parent container
  therefore, the companies are repeated to have the carousel effect
*/
const SlideCarousel = ({ companies }) => {
  return (
    <div className='flex w-full overflow-hidden whitespace-nowrap'>
      <div className='flex logos-slide'>
        {companies.map((company, index) => (
          <div
            className='bg-white rounded-2xl p-5 border border-gray6 flex flex-col items-center h-[84px] w-[120px] mx-5'
            data-testid='company'
            key={index}
          >
            <img
              src={company.iconUrl}
              className='w-8 h-8 rounded-full'
              alt='Company icon'
            />
            <p className='medium_2'> {company.name}</p>
          </div>
        ))}
      </div>
      <div className='flex logos-slide'>
        {companies.map((company, index) => (
          <div
            className='bg-white rounded-2xl p-5 border border-gray6 flex flex-col items-center h-[84px] w-[120px] mx-5'
            key={index}
          >
            <img
              src={company.iconUrl}
              className='w-8 h-8 rounded-full'
              alt='Company icon'
            />
            <p className='medium_2'> {company.name}</p>
          </div>
        ))}
      </div>
      <div className='flex logos-slide'>
        {companies.map((company, index) => (
          <div
            className='bg-white rounded-2xl p-5 border border-gray6 flex flex-col items-center h-[84px] w-[120px] mx-5'
            key={index}
          >
            <img
              src={company.iconUrl}
              className='w-8 h-8 rounded-full'
              alt='Company icon'
            />
            <p className='medium_2'> {company.name}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SlideCarousel
