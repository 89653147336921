import { useSelector } from 'react-redux'
import RewardHistoryItem from './RewardHistoryItem'

const RewardHistory = () => {
  const { transactions } = useSelector(state => state.rewards)
  if (transactions?.length === 0) return null
  return (
    <div className='nonclickable-grid-container'>
      <div className='giant_1 centered'>My Linqto Bucks History</div>
      <div className='mt-9'>
        <div className='nonclickable-grid-container__list'>
          <div className='nonclickable-grid-container__columns rewards_history'>
            <span className='medium_1'>DATE</span>
            <span className='medium_1'>EVENT</span>
            <span className='medium_1'>AMOUNT</span>
            <span className='medium_1'>EXPIRATION DATE</span>
            <span className='medium_1'>NOTE</span>
          </div>
          {transactions.map((t, i) => (
            <RewardHistoryItem key={i} transaction={t} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default RewardHistory
