import { useState } from 'react'
import { Button, TextInput } from 'components'
import { FormProvider, useForm } from 'react-hook-form'
import SubmitSuccessModal from './SubmitSuccessModal'
import { useDispatch, useSelector } from 'react-redux'
import {
  saveSecurityLicense,
  getInvestorStatus
} from 'slices/investorStatusSlice'
import { Mixpanel } from 'utils'
import { selfAccreditationOptions } from '../../data'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from 'schemas/crdNumberSchema'

const CRDRevamp = () => {
  const dispatch = useDispatch()
  const {
    accreditedStatus,
    accreditedCountry
  } = useSelector((state) => state.investorStatusSlice)
  const methods = useForm({ mode: 'onSubmit', resolver: yupResolver(schema) })
  const { handleSubmit } = methods
  const [submitLoading, setSubmitLoading] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)

  const onSubmit = async (formValues) => {
    setSubmitLoading(true)
    const { meta } = await dispatch(saveSecurityLicense(formValues))
    if (meta.requestStatus === 'fulfilled') {
      Mixpanel.track('Onboarding Click Submit Verification', {
        'Accreditation Method': 'Manual',
        'Accredited Country': accreditedCountry,
        'Accreditation Status': selfAccreditationOptions.SECURITIES_LICENSE
      })
      setSubmitLoading(false)
      setSubmitSuccess(true)
    }
  }

  return (
    <>
      <div className='large_1'>
        What is your CRD License number?
      </div>
      <FormProvider {...methods}>
        <form className='grid-old'>
          <div className='column six sixteen-mobile'>
            <TextInput
              name='crdNumber'
              label='CRD License Number'
              disabled={submitLoading || accreditedStatus === 'UNDER_REVIEW'}
              placeholder='CRD#'
              type='number'
            />
          </div>
          <div className='column sixteen'>
            <Button loading={submitLoading} disabled={submitLoading} onClick={handleSubmit(onSubmit)} >
          Submit
            </Button>
          </div>
        </form>
      </FormProvider>
      {submitSuccess && <SubmitSuccessModal hideModal={async () => { setSubmitSuccess(false); await dispatch(getInvestorStatus()) }} header='CRD # is Under Review by Our Team'>
        <div className='medium_1 content'>Your CRD# was successfully submitted.</div>
        <div className='medium_1 content'>Our team will review your submission right away, and
        confirm that your license is active. You’ll receive an email when you are verified.
        </div>
      </SubmitSuccessModal>}
    </>
  )
}

export default CRDRevamp
