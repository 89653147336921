import { useSelector } from 'react-redux'
import { Mixpanel } from 'utils'
import StartInvestingBanner from 'components/Global/StartInvestingBanner'
import AccountBanner from 'components/Global/AccountBanner'
import { useHistory } from 'react-router-dom'
import FormCRSBanner from 'components/Global/FormCRSBanner'

const InvestAccountBanner = ({ setShowBanner }) => {
  const {
    needToCompleteInvestorProfile,
    hasWalletDeposit,
    cashPaymentEnabled,
    wirePaymentEnabled,
    userProfileIsComplete,
    isRetailPurchaser,
    accreditedStatus,
    isCRSAcknowledged
  } = useSelector((state) => state.investSlice)
  const { featureFlags } = useSelector((state) => state.userSlice)
  const { CrsCheckEnabled } = featureFlags || {}

  const notRetailNeedToCompleteFundingStep =
    cashPaymentEnabled &&
    !wirePaymentEnabled &&
    !needToCompleteInvestorProfile &&
    !hasWalletDeposit && !isRetailPurchaser
  const retailNeedToCompleteFundingStep =
    cashPaymentEnabled &&
    !wirePaymentEnabled &&
    !hasWalletDeposit && isRetailPurchaser
  const notRetailNeedToCompleteProfile = !isRetailPurchaser && needToCompleteInvestorProfile
  const retailNeedToCompleteProfile = isRetailPurchaser && !userProfileIsComplete
  const history = useHistory()

  const hideBanner = (bannerType) => {
    if (bannerType === 'accountBanner') {
      Mixpanel.track('Dismiss New User Banner')
    } else {
      Mixpanel.track('Dismiss Onboarding Banner')
    }
    sessionStorage.setItem('dismissAccountBanner', true)
    setShowBanner(false)
  }

  const bannerAction = (bannerType) => {
    if (bannerType === 'accountBanner') {
      Mixpanel.track('Click Start Now on New User Banner')
    } else {
      Mixpanel.track('Click Onboarding Banner')
    }
    if (notRetailNeedToCompleteFundingStep || retailNeedToCompleteFundingStep) {
      history.push('/cash-account')
    } else {
      history.push('/profile')
    }
  }

  if (cashPaymentEnabled) {
    if (
      (notRetailNeedToCompleteProfile || retailNeedToCompleteProfile || notRetailNeedToCompleteFundingStep || retailNeedToCompleteFundingStep)
    ) {
      if ((notRetailNeedToCompleteProfile || retailNeedToCompleteProfile) && accreditedStatus === 'ACCREDITED' && !isCRSAcknowledged && CrsCheckEnabled) {
        return <FormCRSBanner />
      }
      return (
        <StartInvestingBanner
          hideBanner={hideBanner}
          bannerAction={bannerAction}
          needToCompleteInvestorProfile={notRetailNeedToCompleteProfile || retailNeedToCompleteProfile}
          needToCompleteFundingStep={notRetailNeedToCompleteFundingStep || retailNeedToCompleteFundingStep}
        />
      )
    } else {
      return <div />
    }
  } else {
    if (needToCompleteInvestorProfile) {
      return (
        <AccountBanner hideBanner={hideBanner} bannerAction={bannerAction} />
      )
    } else {
      return <div />
    }
  }
}

export default InvestAccountBanner
