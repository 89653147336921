import { useEffect, useState } from 'react'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { PhoneNoInput, SSNMaskedInput, Select, Button, Modal, PasswordInput, EmailInput, PageLoading, Checkbox } from 'components'
import { getDomain, Mixpanel } from 'utils'
import { month } from '../../data'
import { yupResolver } from '@hookform/resolvers/yup'
import { getFinraUserStatus, saveFinraUserStatus } from 'slices/investSlice'
import { schema } from 'schemas/finraProfileSchema'
const tooltip = {
  taxId:
    'Required for all US citizens and residents to meet regulatory and tax reporting obligations. Only leave this blank if you have neither an Social Security Number (SSN) nor an Individual Taxpayer Identification Number (ITIN).'
}

const FinraCompleteProfileModal = ({ hideModal, actionAfterSave, canBeClosed = true, showEmailAndPassword }) => {
  const dispatch = useDispatch()
  const { finraUserProfile } = useSelector(state => state.investSlice)
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: finraUserProfile,
    context: {
      addionalValidation: showEmailAndPassword
    }
  })
  const { handleSubmit, control, register, watch, reset } = methods

  const [submitLoading, setSubmitLoading] = useState(false)
  const [modalLoading, setModalLoading] = useState(true)
  const [errorCopy, setErrorCopy] = useState('')
  const selectedMonth = watch('birthMonth')
  const selectedYear = watch('birthYear')
  // call api to know the form filled
  useEffect(() => {
    const getStatus = async () => {
      const { payload } = await dispatch(getFinraUserStatus())
      if (payload) {
        reset(payload)
      }
      setModalLoading(false)
    }
    getStatus()
    Mixpanel.track('View Finra Complete Profile Modal')
  }, [])

  const onSubmit = async (data) => {
    setSubmitLoading(true)
    const formattedTaxId = data.taxId.replace(/[_ -]/g, '')
    const { meta } = await dispatch(saveFinraUserStatus({ ...data, taxId: formattedTaxId }))
    setSubmitLoading(false)
    if (meta.requestStatus === 'fulfilled') {
      Mixpanel.track('Complete Finra Profile Success')
      hideModal()
      actionAfterSave()
    }
    if (meta.requestStatus === 'rejected') {
      Mixpanel.track('Complete Finra Profile Error')
      setErrorCopy('Failed to save. Please try again later.')
    }
  }
  const yearArray = () => {
    const currentLegalYear = new Date().getFullYear() - 18
    const years = []
    for (let i = currentLegalYear; i >= currentLegalYear - 90; i--) {
      years.push(i)
    }
    return years
  }

  const monthArray = () => {
    const monthArray = []
    for (let index = 1; index < Object.keys(month).length + 1; index++) {
      const item = { label: month[index], value: index }
      monthArray.push(item)
    }
    return monthArray
  }

  const daysArray = () => {
    const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate()
    const daysArray = []
    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push(i)
    }
    return daysArray
  }

  const renderForm = () => {
    return (
      <FormProvider {...methods}>
        <form className='grid-old' onSubmit={handleSubmit(onSubmit)}>
          {showEmailAndPassword && <><div className='column eight sixteen-mobile'>
            <EmailInput
              name='email'
              label='Email'
              ariaLabel='email'
              disabled={submitLoading}
            />
          </div>
          <div className='column eight sixteen-mobile'>
            <PasswordInput
              name='password'
              label='Password'
              ariaLabel='password-input'
              disabled={submitLoading}
              autoComplete='new-password'
            />
          </div></>}
          <div className='column eight sixteen-mobile'>
            <PhoneNoInput
              label='Phone'
              name='phone'
              control={control}
              disabled={submitLoading}
              register={register}
              ariaLabel='phoneNo'
            />
          </div>
          <div className='column eight sixteen-mobile'>
            <SSNMaskedInput
              name='taxId'
              label='Social Security # or ITIN #'
              placeholder='000-00-0000'
              content={tooltip.taxId}
              ariaLabel='taxId'
              disabled={submitLoading}
            />
          </div>
          <div className='column eight sixteen-mobile'>
            <Controller
              control={control}
              name='birthYear'
              render={({ field }) => (
                <Select
                  field={field}
                  label='Birth Year'
                  name='birthYear'
                  options={yearArray()}
                  ref={null}
                  disabled={submitLoading}
                  defaultOption={{ value: 'NONE', label: 'Select a Year' }}
                  ariaLabel='birthYear'
                />
              )}
            />
          </div>
          <div className='column eight sixteen-mobile'>
            <Controller
              control={control}
              name='birthMonth'
              render={({ field }) => (
                <Select
                  field={field}
                  label='Birth Month'
                  name='birthMonth'
                  optionsWithLabel={monthArray()}
                  ref={null}
                  disabled={submitLoading}
                  defaultOption={{ value: 'NONE', label: 'Select a Month' }}
                  ariaLabel='birthMonth'
                />
              )}
            />
          </div>
          { (selectedMonth && selectedYear) && <div className='column eight sixteen-mobile'>
            <Controller
              control={control}
              name='birthDay'
              render={({ field }) => (
                <Select
                  field={field}
                  label='Birth Day'
                  name='birthDay'
                  options={daysArray()}
                  ref={null}
                  disabled={submitLoading}
                  defaultOption={{ value: 'NONE', label: 'Select a Day' }}
                  ariaLabel='birthDay'
                />
              )}
            />
          </div>}
          {showEmailAndPassword && <><div className='column sixteen'>
            <Checkbox
              label='I would like to receive updates on new investment opportunities, price reductions, and market insights'
              name='marketingOptIn'
              ariaLabel='marketingOptIn'
              checkboxClass='marketing-checkbox'
            />
          </div>
          <div className='column sixteen'>
            <div className='signup-terms'>
            By clicking &quot;Submit&quot; below, I agree to the{' '}
              <a href={getDomain('/terms-of-use', true)} rel='noopener noreferrer' className='inline-text-link' target='_blank'>Linqto Terms of Use</a>, <a className='inline-text-link' target='_blank' rel='noopener noreferrer' href={getDomain('/privacy', true)}>Privacy Policy</a> and <a className='inline-text-link' target='_blank' rel='noopener noreferrer' href={getDomain('/crs', true)}>Form CRS</a>.
            </div>
          </div></>}
          <div className='column sixteen sixteen-mobile'>
            <div className='medium_1 action-errorRed margined'>{errorCopy}</div>
            <div className='flex justify-center'>
              <div className='inline-flex justify-center'>
                <Button mode='primary' type='submit' >Submit</Button>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    )
  }

  return (
    <Modal
      modalHeader='Complete Your Profile'
      hideModal={hideModal}
      crossToClose={canBeClosed}
      clickToClose={canBeClosed}
      innerStyle='finra-modal text-left'
    >
      {modalLoading ? <PageLoading /> : renderForm()}
    </Modal>
  )
}

export default FinraCompleteProfileModal
