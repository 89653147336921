import { useState, useRef, useEffect } from 'react'
import { Modal, PageLoading } from 'components'
import Cropper from 'react-cropper'
import Button from '../../../Global/Button'
import { images } from 'assets'
import { useFormContext } from 'react-hook-form'
import Upload from 'components/Global/Upload/Upload'

function ManualUpload ({ documentName, disabled, input, name }) {
  const crop = useRef()
  const [image, setImage] = useState('')
  const [cropper, setCropper] = useState()
  const [showModal, setShowModal] = useState(false)
  const [modalLoading, setModalLoading] = useState(true)
  const [buttonLoading, setBttonLoading] = useState(false)
  const [fileUploaded, setFileUploaded] = useState('')

  const { register, setValue, clearErrors } = useFormContext()

  const dropComplete = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setCroppedData(acceptedFiles)
    }
  }

  const rotate = () => {
    const data = cropper.getCropBoxData()
    const contData = cropper.getContainerData()
    data.width = 2
    data.height = 2
    data.top = 0
    const leftNew = (contData.width / 2) - 1
    data.left = leftNew

    cropper.setCropBoxData(data)
    cropper.rotate(90)
    const canvData = cropper.getCanvasData()

    const heightOld = canvData.height
    const heightNew = contData.height
    const koef = heightNew / heightOld
    const widthNew = heightNew < heightOld ? canvData.width * koef : canvData.width
    canvData.height = heightNew
    canvData.width = widthNew
    canvData.top = 0
    if (canvData.width >= contData.width) {
      canvData.left = 0
    } else {
      canvData.left = (contData.width - canvData.width) / 2
    }
    cropper.setCanvasData(canvData)
    data.left = 0
    data.top = 0
    data.width = canvData.width
    data.height = canvData.height
    cropper.setCropBoxData(data)
  }

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      input.onChange({
        name: input.name,
        value: cropper.getCroppedCanvas().toDataURL('image/jpeg')
      })
      setFileUploaded(cropper.getCroppedCanvas().toDataURL('image/jpeg'))
    }
    setValue(input.name, cropper.getCroppedCanvas().toDataURL('image/jpeg', { shouldDirty: true }))
    clearErrors(input.name)
    hideModal()
  }
  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (showModal) {
        getCropData()
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [showModal, cropper])

  const hideModal = () => {
    setShowModal(false)
    setModalLoading(true)
    setImage('')
  }

  const setCroppedData = (acceptedFiles) => {
    setModalLoading(false)
    const reader = new FileReader()
    reader.onload = () => {
      setImage(reader.result)
    }
    reader.readAsDataURL(acceptedFiles[0])
    setShowModal(true)
    setModalLoading(true)
    setBttonLoading(true)
  }
  const remove = () => {
    setValue(input.name, '', { shouldDirty: true })
    input.onChange({
      name: input.name,
      value: ''
    })
  }

  return (
    <>
      <section {...register(name)}>
        <Upload
          type='OR'
          setFileUploaded={setFileUploaded}
          fileUploaded={fileUploaded}
          onDropComplete ={dropComplete}
          label={documentName}
          name={documentName}
          onDelete={remove}
          allowDelete={true}
          customClass='manual-upload'
          acceptedFileFormat = '.png, .jpg, .jpeg'
          disabled={disabled}
        />
      </section>
      {showModal &&
      <>
        <Modal innerStyle='upload-document-modal kyc-manual-modal' hideModal={hideModal} modalHeader={`Upload ${documentName}`} outerStyle='dimmer-no-padding' clickToClose pressToClose crossToClose>
          <div style={{ width: '100%', position: 'relative' }}>
            <Cropper
              style={{ height: 400, width: '100%' }}
              zoomTo={0.5}
              preview='.img-preview'
              src={image}
              viewMode={1}
              minCropBoxHeight={60}
              minCropBoxWidth={60}
              background={false}
              scalable= {false}
              responsive={true}
              autoCropArea={1}
              zoomOnTouch={true}
              checkOrientation={false}
              zoomOnWheel={true}
              onInitialized={(instance) => {
                setCropper(instance)
                setModalLoading(true)
                setTimeout(() => {
                  if (instance) {
                    if (instance.imageData && instance.containerData) {
                      const widthZoomRatio = instance.containerData.width / instance.imageData.naturalWidth
                      const heightZoomRatio = instance.containerData.height / instance.imageData.naturalHeight
                      const zoomRatio = widthZoomRatio < heightZoomRatio ? widthZoomRatio : heightZoomRatio
                      instance.zoomTo(zoomRatio)
                    }
                  }
                  setModalLoading(false)
                }, 750)
              }}
              ref={crop}
              ready = { () => {
                const cropper = crop.current.cropper
                if (cropper.containerData && cropper.imageData) {
                  const widthZoomRatio = cropper.containerData.width / cropper.imageData.naturalWidth
                  const heightZoomRatio = cropper.containerData.height / cropper.imageData.naturalHeight
                  const zoomRatio = widthZoomRatio < heightZoomRatio ? widthZoomRatio : heightZoomRatio
                  cropper.zoomTo(zoomRatio)
                  setModalLoading(false)
                  setBttonLoading(false)
                }
              } }
              guides={true}
            />
          </div>
          <div>
            <Button className='rotate-btn' onClick={rotate} ariaLabel='Rotate Document' >
              <img src={images.icon_rotate} alt='Rotate Document' />
            </Button>
            <Button onClick={getCropData} customClass='request-btn' loading={buttonLoading}>Save</Button>
          </div>
          {modalLoading && <PageLoading type='modal' /> }
        </Modal>
      </>
      }
    </>
  )
}

export default ManualUpload
