import React from 'react'
import { Button } from 'components'
import { images } from 'assets'

const LinkedAccount = ({ isConnected, connect, disconnect, copy, title, linkCopy, disconnectCopy, image, accountConntected = false, testID = '' }) => {
  if (!accountConntected) {
    return (
      <div className='mt-5'>
        <div className='medium_1 flex justify-between items-center'>
          <div className='flex justify-between items-center lg:w-auto w-[60%]'>
            <img className='mr-4 lg:w-10 lg:h-10 w-8' src={images[image]} alt='uphold' />
            <span className='medium_2 w-full text-left text-gray4'>{title}</span>
          </div>
          <div>
            {!isConnected && linkCopy === 'Under Review' && (
              <span className='no-wrap link gray'>
                {linkCopy}
              </span>
            )}
            {!isConnected && linkCopy !== 'Under Review' && (
              <Button className='no-wrap link primary' onClick={connect}>
                {linkCopy}
              </Button>
            )}
            {isConnected && (
              <Button className='no-wrap link primary' onClick={disconnect} data-testid={`disconnectBtn-${testID}`}>
                {disconnectCopy}
              </Button>
            )}
          </div>
        </div>
        <div className='medium_1 my-5 mx-0'>{copy}</div>
      </div>
    )
  } else {
    return (
      <div className='medium_1 text-center mt-6'>
        <Button className='link primary' onClick={connect}>Add an Account</Button>
      </div>)
  }
}

export default LinkedAccount
