import { useHistory } from 'react-router-dom'
import Button from 'components/Global/Button'

const FormCRSBanner = () => {
  const history = useHistory()
  return (
    <Button className='crs-banner-container' onClick={() => history.push('/profile')} ariaLabel='Please complete your Investor Profile.'>
      <p className='medium_1 accessibleIndigo'>We&apos;ve made updates to our Customer Relationship Summary (Form CRS). Please complete your Investor Profile.</p>
      <a className='medium_2 royal pointer'>Complete Profile</a>
    </Button>
  )
}

export default FormCRSBanner
