import PropTypes from 'prop-types'
import Select, { components as SelectComponents } from 'react-select'
import TooltipLabel from '../TooltipLabel'
import { images } from 'assets'
import { useFormContext } from 'react-hook-form'
import ErrorMessage from './ErrorMessage'

// Styles to modify react select style
const baseStyle = {
  control: {
    boxShadow: 0,
    borderRadius: 8,
    cursor: 'pointer'
  },
  height: 70,
  border: '1px solid #717171',
  borderActive: '1px solid #0039ec',
  borderError: '1px solid #ff0000',
  background: '#ffffff',
  backgroundDisabled: '#bfbfbf',
  placeholder: {
    color: '#050505',
    fontWeight: 600,
    top: '47%',
    margin: '0 !important',
    paddingLeft: 4,
    transform: 'translateY(0)',
    position: 'relative'
  }
}
const customStyles = {
  container: base => ({
    ...base
  }),
  placeholder: base => ({
    ...base,
    ...baseStyle.placeholder
  }),
  menu: base => ({
    ...base,
    marginTop: -6,
    boxShadow: 'none',
    border: '1px solid #717171',
    borderTop: '1px solid #ffffff',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  }),
  option: (style, state) => ({
    ...style,
    background: state.isSelected || state.isDisabled ? '#F2F2F2' : 'white',
    marginBottom: 1,
    borderRadius: 8,
    color: state.isDisabled ? '#717171' : '#000000',
    '&:active': {
      background: 'none'
    }
  })
}

const errorStyles = {
  placeholder: base => ({
    ...base,
    ...baseStyle.placeholder
  }),
  menu: base => ({
    ...base,
    marginTop: -6,
    boxShadow: 'none',
    border: '1px solid #ff0000',
    borderTop: '1px solid #ffffff',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  })
}

const iconStyle = {
  height: 34,
  width: 34,
  borderRadius: 19,
  marginRight: 15
}

// Custom components replacing existing react-select components
const customComponents = {
  DropdownIndicator: (props) => (
    <SelectComponents.DropdownIndicator {...props}>
      <img style={{ height: 25, width: 25 }} src={images['chevron-down']} alt='View Dropdown' />
    </SelectComponents.DropdownIndicator>
  ),
  ClearIndicator: () => null,
  IndicatorSeparator: () => null,
  Option: (props) => (
    <SelectComponents.Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }} data-testid={props.data.label}>
        <img src={props.data.icon} style={{ ...iconStyle }} alt='Dropdown Icon'/>
        {props.data.customLabel}
      </div>
    </SelectComponents.Option>
  ),
  SingleValue: (props) => (
    <SelectComponents.Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 2 }}>
        <img src={props.data.icon} style={{ ...iconStyle }} alt='Option Icon'/>
        {props.data.customLabel}
      </div>
    </SelectComponents.Option>
  ),
  MultiValueRemove: (props) => (
    <SelectComponents.MultiValueRemove {...props}>
      <img style={{ height: 12, width: 12 }} src={images['remove-X']} alt='Remove Option' />
    </SelectComponents.MultiValueRemove>
  )
}

// Note: options and selectedOptions is an array of objects with value and label keys
const CustomSelect = ({ name, placeholder = '', className = '', optionsList, selectedOptions, field, label, disabled, extraLabel, content = '', onChange, ariaLabel = '' }) => {
  const {
    formState: { errors },
    setValue,
    clearErrors,
    watch
  } = useFormContext()
  return (
    <div className={className}>
      <TooltipLabel content={content} label={label} extraLabel={extraLabel} />
      <Select
        {...field}
        aria-label={ariaLabel}
        styles={errors[name] ? {
          ...errorStyles,
          control: (style) => ({
            ...style,
            ...baseStyle.control,
            height: watch(name)?.length > 0 ? 'auto' : '56px',
            border: baseStyle.borderError,
            '&:hover': {
              border: baseStyle.borderError
            }
          })
        } : {
          ...customStyles,
          control: (style, state) => ({
            ...style,
            ...baseStyle.control,
            height: watch(name)?.length > 0 ? 'auto' : '56px',
            border: state.isSelected ? baseStyle.borderActive : baseStyle.border,
            '&:hover': {
              border: baseStyle.borderActive
            }
          }),
          valueContainer: base => ({
            ...base,
            padding: watch(name)?.length > 0 ? '0' : '0 16px'
          })
        }}
        components={customComponents}
        defaultValue={selectedOptions}
        placeholder={placeholder}
        onChange={(options) => {
          setValue(name, options, { shouldDirty: true })
          clearErrors(name)
          onChange(options)
        }}
        options={optionsList}
        isMulti= {false}
        isSearchable={false}
        isClearable
        value={selectedOptions}
        isDisabled={disabled}
      />
      {errors[name] && <ErrorMessage message={errors[name].message} />}
    </div>
  )
}

CustomSelect.propTypes = {
  optionsList: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array,
  setSelectedOptions: PropTypes.func,
  label: PropTypes.string,
  content: PropTypes.any
}

CustomSelect.defaultProps = {
  optionsList: [],
  selectedOptions: null
}

export default CustomSelect
