import { useEffect, useState, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { PageLoading, ErrorModal, ConditionalRender, Button, TopBanner, Tooltip, Select, Checkbox } from 'components'
import { Mixpanel, seoTitleTemplate, formatDecimal, formatWholeNumber, Fullstory, renderTotalsOrDash, MOBILE_WIDTH_SIZE, useLDFlags } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { FormProvider, useForm, Controller } from 'react-hook-form'
import { schema } from 'schemas/BundleOrderSchema'
import { images } from 'assets'
import { yupResolver } from '@hookform/resolvers/yup'
import { errorTypes } from 'types/errorTypes'
import { confirmBundleOrder, commitBundleOrder } from 'slices/bundleOrderSlice'
import ProductDocuments from 'components/OrderDetail/partials/ProductDocuments'
import Toggle from 'components/Global/Toggle'
import CustomSelect from 'components/Global/Inputs/CustomSelect'
import { availableSharesToSellTooltip, transactionFeeCopy, estimatedSharesCopy } from 'components/data'
import BundleCounter from './partials/BundleCounter'
import _ from 'lodash'
import useWindowSize from 'hooks/useWindowSize'
import { useQuery } from 'hooks/useQuery'
import FinraCompleteProfileModal from 'components/InvestDetail/partials/FinraCompleteProfileModal'
import LinqtoCapitalPlacementCopy from 'components/Global/LinqtoCapitalPlacementCopy'
import SuitabilityQuestionsModal from 'components/ConfrimOrder/SuitabilityQuestionsModal'

const BundleOrder = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const query = useQuery()
  const { bundleId } = useParams()
  const { width } = useWindowSize()

  const { totalAmount, documents, companies, accounts, sellSharesDetail, totalBundleQuantityAvailable, profileMissingFields, isAccredited } = useSelector(
    (state) => state.bundleOrder
  )

  const { featureFlags } = useSelector(state => state.userSlice)
  const { PurchaseWithSharesAllowed, RequireBrokerDealerSuitabilityQuestions } = featureFlags || {}
  const { suitabilityQuestions } = useLDFlags(['suitabilityQuestions'])
  const [selectedAccount, setSelectedAccount] = useState({})
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [confirmBundleOrderError, setConfirmBundleOrderError] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const [commitLoading, setCommitLoading] = useState(false)
  const [purchaseWithShares, setPurchaseWithShares] = useState(false)
  const [selectedSellCompaniesOptions, setSelectedOptions] = useState([])
  const [accountOptions, setAccountOptions] = useState([])
  const [purchaseWithCash, setPurchaseWithCash] = useState(true)
  const [bundleQty, setBundleQty] = useState(parseInt(query.get('qty')) || 1)
  const [quantityRequested, setQuantityRequested] = useState(parseInt(query.get('qty')) || 1)
  const [perBundleAmount, setPerBundleAmount] = useState(0)
  const [errorModalBtnCopy, setErrorModalBtnCopy] = useState('OK')
  const [showTopBanner, setShowTopBanner] = useState(null)
  const [showFinraCompleteProfileModal, setShowFinraCompleteProfileModal] = useState(false)
  const [isFinraUnconfirmedUser, setIsFinraUnconfirmedUser] = useState(false)
  const [showSuitabilityModal, setSuitabilityModal] = useState(false)
  const [showHover, setShowHover] = useState(false)
  const [showHoverFee, setShowHoverFee] = useState(false)
  const isMobile = width <= MOBILE_WIDTH_SIZE

  const missingFields = profileMissingFields || []
  const hasMissingFields = missingFields?.length === 3 && missingFields.includes('investmentHorizon') && missingFields.includes('riskTolerance') && missingFields.includes('investmentExperience')
  const showQuestionsModal = suitabilityQuestions && RequireBrokerDealerSuitabilityQuestions && hasMissingFields && isAccredited 

  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues: {
      entityId: query.get('entityId')
    }
  })

  const { handleSubmit, watch, formState: { errors }, setValue, clearErrors, control } = methods

  const accountLabel = watch('sellCompanyId') ? JSON.parse(watch('sellCompanyId'))?.label : null

  const sellCompanies = selectedAccount?.sellCompanies?.map(s => ({
    value: s.companyId,
    label: s.name,
    icon: s.iconUrl,
    numberOfShares: s.numberOfshares,
    pricePerShare: s.pricePerShare,
    customLabel: `${formatDecimal(s.totalSharePrice)} (${formatWholeNumber(s.numberOfshares)} Shares)`
  })) || []

  useEffect(() => {
    getConfirmBundleOrder(true)
  }, [])

  useEffect(() => {
    if (showQuestionsModal) {
      setSuitabilityModal(true)
    }
  }, [showQuestionsModal])

  useEffect(() => {
    if (totalAmount > 0) {
      getConfirmBundleOrder(false)
    }
  }, [quantityRequested])

  const handleQtyChange = useCallback(
    _.debounce((val) => {
      setQuantityRequested(() => val)
    }, 500),
    []
  )

  useEffect(() => {
    // If purchasing with shares and user disables purchaseWithCash then we show insufficent shares error
    if (purchaseWithShares && !purchaseWithCash && sellSharesDetail?.total > 0 && watch('sellCompanyId')) {
      methods?.setError('sharesInsufficent', {
        type: 'PLACE_ORDER_INSUFFICIENT_FUNDS_FOR_TRADE',
        message: 'Shares alone are insufficient to pay for this order. Please toggle on cash or select different shares.'
      })
    } else {
      clearErrors('sharesInsufficent')
    }
  }, [purchaseWithShares, purchaseWithCash, sellSharesDetail, watch('sellCompanyId')])

  const getConfirmBundleOrder = async (showPageLoader) => {
    if (!showPageLoader) {
      setCommitLoading(true)
    }
    const entityId = watch('entityId') !== 'individual' ? watch('entityId') : null
    const sellCompanyId = watch('sellCompanyId') ? JSON.parse(watch('sellCompanyId'))?.value : null

    const res = await dispatch(confirmBundleOrder({ bundleId, entityId, sellCompanyId, quantityRequested }))
    const { meta, payload } = res
    // update loader when api is done.
    if (showPageLoader) {
      setPageLoading(false)
    } else {
      setCommitLoading(false)
    }
    if (meta.requestStatus === 'fulfilled') {
      if (payload?.isAccredited) {
        if (selectedAccount && Object.keys(selectedAccount).length === 0) {
          if (query.get('entityId')) {
            // this to handle entity id if it set in the url
            setSelectedAccount(payload?.accounts.filter((account) => (account?.entityId?.toString() === query.get('entityId')))[0])
          } else {
            setSelectedAccount(payload?.accounts?.[0])
          }
        }
        const bundleCompanies = payload?.companies?.map(item => ({ Name: item.name, 'Amount selected': item.amount, 'Estimated shares': item.shares }))
        const accountsArray = payload?.accounts?.map(account => ({
          label: account.accountName,
          value: account?.entityId || 'individual',
          name: account.accountName
        })) || []

        setAccountOptions(accountsArray)
        setPerBundleAmount(payload?.totalAmount / quantityRequested)

        if (showPageLoader) {
          Mixpanel.track('View Bundle Order Review Confirmation', {
            Companies: bundleCompanies,
            'Bundle ID': bundleId
          })
          Fullstory.track('Page View', {
            page_name: 'Multi-Order Bundle Order Review Page',
            companies: bundleCompanies,
            bundle_id: bundleId
          })
        }
      } else {
        setConfirmBundleOrderError({ type: 'PLACE_ORDER_USER_PROFILE_NOT_COMPLETE', title: 'Incomplete Investor Profile', message: 'You must complete your investor profile to place your order.' })
        setShowErrorModal(true)
      }
      if (localStorage?.getItem('from-buy-order-page')) {
        setShowTopBanner({ title: 'Deposit Complete', body: 'Your funds are now available.' })
        localStorage?.removeItem('from-buy-order-page')
      }
    } else if (meta.requestStatus === 'rejected') {
      if (payload?.isFinraUser || payload?.isFinraNotConfirmedUser) {
        if (payload?.isFinraNotConfirmedUser) {
          setIsFinraUnconfirmedUser(true)
        }
        setShowFinraCompleteProfileModal(true)
        return
      }
      if (payload?.placeOrderError?.type === 'PLACE_ORDER_PRODUCT_NOT_ACTIVE' || payload?.placeOrderError?.type === 'PLACE_ORDER_COMPANY_NOT_AVAILABLE') {
        setErrorModalBtnCopy('View All Companies')
      }
      if (payload === 'PLACE_ORDER_USER_PROFILE_NOT_COMPLETE') {
        Mixpanel.track('View Incomplete Profile Purchase Modal')
      } else {
        Mixpanel.track('Purchase Error', {
          'Error Type': payload?.type
        })
      }
      setConfirmBundleOrderError(payload?.placeOrderError)
      setShowErrorModal(true)
    }
  }

  const handlePurchaseAsChange = (value) => {
    setSelectedAccount(value === 'individual' ? accounts[0] : accounts.filter((account) => (account?.entityId?.toString() === value.toString()))[0])
    setSelectedOptions([])
    setValue('sellCompanyId', null)
    clearErrors('sellCompanyId')
    getConfirmBundleOrder(false)
    Mixpanel.track('Change Purchase As on Bundle Review Page')
  }

  const handleCommitOrder = async () => {
    setCommitLoading(true)
    const entityId = watch('entityId') !== 'individual' ? watch('entityId') : null
    const sellCompanyId = watch('sellCompanyId') ? JSON.parse(watch('sellCompanyId'))?.value : null

    const res = await dispatch(commitBundleOrder({
      sellShares: sellSharesDetail?.sellShares,
      bundleCode: bundleId,
      entityId,
      sellCompanyId,
      bundleQuantity: quantityRequested,
      bundleAmount: totalAmount
    }))
    const { meta, payload } = res
    if (meta.requestStatus === 'fulfilled') {
      history.push(`/bundle-confirmed/${payload?.tradeId}?bundleId=${bundleId}`)
      setCommitLoading(false)
    } else if (meta.requestStatus === 'rejected') {
      if (payload?.placeOrderError?.type === 'PLACE_ORDER_PARAMETERS_CHANGED') {
        getConfirmBundleOrder(false)
        setConfirmBundleOrderError({ type: 'PLACE_ORDER_PARAMETERS_CHANGED', title: 'Please review order change', message: 'The company you are placing an order for is either fully subscribed or the share price has changed. Click “OK” to review the updates.' })
        setShowErrorModal(true)
      } else if (payload?.placeOrderError?.type === 'PLACE_ORDER_INSUFFICIENT_FUNDS_FOR_TRADE') {
        methods?.setError('sharesInsufficent', {
          type: payload?.placeOrderError?.type,
          message: payload?.placeOrderError?.message
        })
      } else if (payload?.placeOrderError?.type) {
        // need to make sure all exist so event can be tracked and modal can show info
        Mixpanel.track('Purchase Error', {
          'Error Type': payload?.placeOrderError?.type
        })
        setConfirmBundleOrderError(payload?.placeOrderError)
        setShowErrorModal(true)
      }
    }
  }

  const goToAddFunds = () => {
    localStorage?.setItem('from-buy-order-page', `${location.pathname}?qty=${quantityRequested}${selectedAccount?.entityId ? `&entityId=${selectedAccount?.entityId}` : ''}`)
    history.push(`/cash-account/add-funds?amountRequired=${totalAmount - selectedAccount?.amountAvailable}${selectedAccount?.entityId ? `&entityId=${selectedAccount?.entityId}` : ''}`)
    Mixpanel.track('Click Add Funds on Buy Order Page')
    Fullstory.track('Link Click', { link: 'Add Funds' })
  }

  const closeModal = () => {
    let path
    switch (confirmBundleOrderError?.type) {
    case errorTypes.PLACE_ORDER_PRODUCT_INSUFFICIENT_AVAILABLE_SHARES:
      history.goBack()
      break
    case errorTypes.NOT_LOGGED_IN_TOKEN_INVALID:
      path = '/signin'
      break
    case errorTypes.PLACE_ORDER_SANCTIONED_COUNTRY:
      path = '/contact'
      break
    case errorTypes.PLACE_ORDER_USER_PROFILE_NOT_COMPLETE:
      path = '/profile'
      break
    case 'PLACE_ORDER_PARAMETERS_CHANGED':
      path = null
      break
    case 'PLACE_ORDER_PURCHASE_NOT_ALLOWED':
      history.goBack()
      break
    case 'Internal Server Error':
    case 'PLACE_ORDER_COMPANY_NOT_AVAILABLE':
    case 'PLACE_ORDER_PRODUCT_NOT_ACTIVE':
      path = '/products/'
      break
    default:
      path = null
    }
    setShowErrorModal(false)
    if (path) {
      history.push(path)
    }
  }

  const toggleShares = () => {
    if (purchaseWithShares) {
      setValue('sellCompanyId', null)
      getConfirmBundleOrder(false)
      clearErrors('sellCompanyId')
      setSelectedOptions([])
    }
    setPurchaseWithShares(!purchaseWithShares)
  }

  const onSellCompanyChange = (option) => {
    setValue('sellCompanyId', JSON.stringify(option))
    setSelectedOptions([option])
    getConfirmBundleOrder(false)
  }

  const toggleCash = () => {
    const selection = !purchaseWithCash ? 'On' : 'Off'
    setPurchaseWithCash(!purchaseWithCash)
    Mixpanel.track('Cash Balance Toggle Click', { Status: selection })
    Fullstory.track('Toggle Click', { toggle: 'Cash', status: selection })
  }

  const trackPlaceOrder = () => {
    Mixpanel.track('Click Place Order Button', {
      Amount: totalAmount,
      'Cash Balance': selectedAccount?.amountAvailable
    })
  }

  const submitForm = (data) => {
    trackPlaceOrder()
    handleSubmit(handleCommitOrder)(data)
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Bundle Order')} />
        <PageLoading />
      </>
    )
  }
  const orderTotal = sellSharesDetail?.total || totalAmount
  const amountRequired = orderTotal - selectedAccount?.amountAvailable
  return (
    <>
      <SeoMeta title={seoTitleTemplate('Bundle Order ')} />
      <FormProvider {...methods}>
        <div className='page-container buy-order-container'>
          <div className='inner-container'>
            {showTopBanner && (
              <TopBanner
                title={showTopBanner.title}
                body={showTopBanner.body}
                hideBanner={() => setShowTopBanner(null)}
                type={showTopBanner.type}
              />
            )}
            <div className='buy-order-header'>
              <h1>Buy Order</h1>
            </div>
            <div className='buy-order-section wih-margin top-aligned'>
              <div className='large_1 buy-order-section-title'>
                <span>1</span> Investment Details
              </div>
              <div className='buy-order-section-detail'>
                <div className='box invest-order-box'>
                  {companies?.length > 0 && companies?.map((c) => (
                    <div className='buy-order-bundle-row' key={c.companyId}>
                      <div className='buy-order-row'>
                        <div className='buy-order-bundle-name-row medium_4'><img className='buy-order-bundle-company-icon' src={c.iconUrl} alt={c.name}/> {c.name}</div>
                      </div>
                      <div className='buy-order-row'>
                        <span>Price Per Share</span>
                        <span>
                          {formatDecimal(c.sharePrice)}
                        </span>
                      </div>
                      <div className='buy-order-row'>
                        <span>Estimated Shares*</span>
                        <span>{formatWholeNumber(c.shares)}</span>
                      </div>
                      <div className='buy-order-row'>
                        <span>Amount</span>
                        <span className='medium_2'>{formatDecimal(c.amount)}</span>
                      </div>
                    </div>
                  )
                  )}
                  <div className='buy-order-row buy-order-bundle-total-row'>
                    <span className='medium_2'>Per Bundle Amount</span>
                    <span className='medium_2'>{formatDecimal(perBundleAmount)}</span>
                  </div>
                  <div className='buy-order-row'>
                    <span className='medium_1'>Bundle Quantity </span>
                    <div className='bundle-qty'>
                      <div className='bundle-qty-count'>
                        <BundleCounter
                          type='minus'
                          disabled={bundleQty === 1}
                          onClick={() => {
                            setBundleQty(bundleQty - 1)
                            handleQtyChange(bundleQty - 1)
                          }}
                        />
                        <div className='medium_2 bundle-qty-copy' data-testid='bundle-qty'>{bundleQty}</div>
                        <BundleCounter
                          type='plus'
                          disabled={bundleQty === totalBundleQuantityAvailable}
                          onClick={() => {
                            setBundleQty(bundleQty + 1)
                            handleQtyChange(bundleQty + 1)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {(bundleQty === totalBundleQuantityAvailable) && <div className='buy-order-row buy-order-bundle-total-row'>
                    <span className='medium_1'></span>
                    <div className='medium_1 gray4'>Bundle Limit Reached</div>
                  </div>}
                  <div className='buy-order-row'>
                    <span className='medium_4'>Total Amount</span>
                    <span className='medium_4'>{formatDecimal(totalAmount)}</span>
                  </div>
                </div>
                <div className='buy-order-copy'>*{estimatedSharesCopy}</div>
              </div>
            </div>
            <div className='buy-order-section wih-margin top-aligned'>
              <div className='large_1 buy-order-section-title'>
                <span>2</span> Payment
              </div>
              <div className='buy-order-section-detail'>
                <span className='medium_2'>Pay With</span>
                <div className='box invest-order-box payment-invest-order-box__with-share-dropdown'>
                  <ConditionalRender isVisible={accounts?.length > 1}>
                    <div className='buy-order-row'>
                      <Controller
                        control={control}
                        name='entityId'
                        setValue={setValue}
                        render={({ field }) => (
                          <Select
                            field={field}
                            disabled={commitLoading}
                            label='Transact as'
                            name='entityId'
                            ariaLabel='purchase-as'
                            optionsWithLabel={accountOptions}
                            setValue={setValue}
                            handleChange={handlePurchaseAsChange}
                            className='full-width'
                          />
                        )}
                      />
                    </div>
                  </ConditionalRender>
                  <ConditionalRender isVisible={PurchaseWithSharesAllowed && selectedAccount?.sellCompanies?.length > 0}>
                    <div className='buy-order-row'>
                      <div className='linqto-bucks-wrapper'>
                        <div className='linqto-img'>
                          <img src={images['shares-icon']} alt='Pay with Shares' />
                        </div>
                        <span className='medium_2'>Shares</span>
                      </div>
                      <Toggle handleChange={toggleShares} checked={purchaseWithShares} ariaLabel='payWithSharesToggleSwitch' />
                    </div>
                    {purchaseWithShares &&
                    <Controller
                      control={control}
                      name='sellCompanyIdControl'
                      render={({ field }) => (
                        <CustomSelect
                          name='sellCompanyId'
                          ariaLabel='sellCompanyId-input'
                          field={field}
                          label='Shares available to sell'
                          placeholder='No Company Holdings Selected'
                          selectedOptions={selectedSellCompaniesOptions}
                          optionsList={sellCompanies}
                          onChange={onSellCompanyChange}
                          className='shares-drop-down'
                          content={availableSharesToSellTooltip}
                          disabled={commitLoading}
                        />
                      )}
                    />}
                    <ConditionalRender isVisible={selectedSellCompaniesOptions?.length === 1} className='shares-sell-data'>
                      <div className='buy-order-row'>
                        <div className='medium_1 gray4'>Estimated Shares To Be Sold:</div>
                        <div className='medium_1 gray4'>{renderTotalsOrDash(sellSharesDetail?.sellShares, formatWholeNumber(sellSharesDetail?.sellShares))}</div>
                      </div>
                      <div className='buy-order-row'>
                        <div className='medium_1 gray4'>Remaining Shares:</div>
                        <div className='medium_1 gray4'>{renderTotalsOrDash(sellSharesDetail?.remainingUnsoldShares, formatWholeNumber(sellSharesDetail?.remainingUnsoldShares))}</div>
                      </div>
                      <div className='buy-order-row'>
                        <div className='medium_1 gray4'>Estimated Share Price:</div>
                        <div className='medium_1 gray4'>{renderTotalsOrDash(sellSharesDetail?.sellSharePrice, formatDecimal(sellSharesDetail?.sellSharePrice))}</div>
                      </div>
                      <div className='buy-order-row'>
                        <div className='medium_1 gray4'>Cost Basis of Shares:</div>
                        <div className='medium_1 gray4'>{renderTotalsOrDash(sellSharesDetail?.totalOriginalHeldSharePrice, formatDecimal(sellSharesDetail?.totalOriginalHeldSharePrice))}</div>
                      </div>
                      {sellSharesDetail?.sellTransactionFee > 0 && <div className='buy-order-row'>
                        <div className='medium_1 gray4'>Transaction Fee:<Tooltip content={transactionFeeCopy(sellSharesDetail?.sellTransactionFeeRate)} showHover={showHoverFee} setShowHover={setShowHoverFee} /></div>
                        <div className='medium_1 gray4'>{renderTotalsOrDash(sellSharesDetail?.sellTransactionFee, formatDecimal(sellSharesDetail?.sellTransactionFee * -1))}</div>
                      </div>}
                      <div className='buy-order-row'>
                        <div className='medium_1 gray4'>Your Projected Gain:<Tooltip content='These gains/losses are estimated. Consult a tax advisor for potential tax implications.' showHover={showHover} setShowHover={setShowHover} /></div>
                        <div className='medium_1 gray4'>{renderTotalsOrDash(sellSharesDetail?.projectedGain, formatDecimal(sellSharesDetail?.projectedGain) || '-')}</div>
                      </div>
                    </ConditionalRender>
                    <div className='line-separator space-above-sm' />
                  </ConditionalRender>
                  <div className='buy-order-row flex-start linqto-bucks-row'>
                    <div className='linqto-img'>
                      <img src={images['cash-icon-dollar']} alt='Cash Balance' />
                    </div>
                    <div className='linqto-copy'>
                      <span className='medium_2 no-padding'>Cash Balance</span>
                      <span className='no-padding'>{formatDecimal(selectedAccount?.amountAvailable)}</span>
                      <span className={`${isMobile ? 'body_1' : 'medium_1'} no-padding gray4a`}>Covers balance after selling shares and any discounts applied.</span>
                    </div>
                    {PurchaseWithSharesAllowed && <Toggle handleChange={toggleCash} checked={purchaseWithCash} ariaLabel='cashBalanceToggleSwitch' />}
                  </div>
                </div>
                <div className='buy-order-row'>
                  <span>Investment Amount</span>
                  <span>{formatDecimal(totalAmount)}</span>
                </div>
                {sellSharesDetail?.sellAmount > 0 && accountLabel && <div className='buy-order-row'>
                  <span>Shares to Sell: {accountLabel}</span>
                  <span>-{formatDecimal(sellSharesDetail?.sellAmount)}</span>
                </div>
                }
                <div className='buy-order-row medium_2'>
                  <span>Total Amount Due</span>
                  <span>{formatDecimal(Object.keys(sellSharesDetail).length === 0 ? totalAmount : sellSharesDetail?.total < 0 ? 0 : sellSharesDetail?.total)}</span>
                </div>
                {sellSharesDetail?.total < 0 &&
                  <div className='buy-order-row'>
                    <span>Credit to Cash Account</span>
                    <span>{formatDecimal(sellSharesDetail?.total * -1)}</span>
                  </div>
                }
              </div>
            </div>
            <ConditionalRender isVisible={amountRequired > 0}>
              <div className='buy-order-row add-funds-row'>
                <span className='red width-70'>{formatDecimal(amountRequired)} more required for purchase</span>
                <Button ariaLabel='Add funds to your account' className='link primary' onClick={goToAddFunds}>Add Funds</Button>
              </div>
            </ConditionalRender>
            <div className='buy-order-section top-aligned'>
              <div className='large_1 buy-order-section-title'>
                <span>3</span> Agreements
              </div>
              <div className='buy-order-section-detail'>
                <ProductDocuments documents={documents} type='bundle-order' selectedAccount={selectedAccount} />
              </div>
            </div>
            <form onSubmit={submitForm}>
              <div className='buy-order-section-detail buy-order-end'>
                <div className='buy-order-row add-funds-row'>
                  <Checkbox
                    label='I confirm I have read these documents and agree to be bound by their provisions.'
                    name='confirmOrderCheckbox'
                    ariaLabel='confirmOrderCheckbox'
                    disabled={commitLoading}
                    checkboxClass='confirm-checkbox'
                  />
                </div>
                <ConditionalRender isVisible={amountRequired > 0}>
                  <div className='buy-order-row add-funds-row'>
                    <span className='red'>Please <Button ariaLabel='Add funds to your account' className='inline-text-link' data-testid='add-funds-link' onClick={goToAddFunds}>add funds</Button> to complete this order</span>
                  </div>
                </ConditionalRender>
                <ConditionalRender isVisible={!!errors?.sharesInsufficent}>
                  <div className='buy-order-row total-spaced'>
                    <span className='red'>{errors?.sharesInsufficent?.message}</span>
                  </div>
                </ConditionalRender>
                <div className='text-center w-full md:w-[60%] md:ml-auto flex flex-col items-center'>
                  <Button
                    type='submit'
                    loading={commitLoading}
                    disabled={commitLoading || Object.keys(errors).length > 0 || amountRequired > 0}
                    customClass='place-order-button'
                  >
                      Place Order
                  </Button>
                  <div className='max-w-sm'>
                    <LinqtoCapitalPlacementCopy />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </FormProvider>
      {showErrorModal && (
        <ErrorModal
          error={confirmBundleOrderError}
          hideModal={closeModal}
          buttonTitle={errorModalBtnCopy}
        />
      )}
      {showFinraCompleteProfileModal && <FinraCompleteProfileModal hideModal={() => setShowFinraCompleteProfileModal(false)} actionAfterSave={() => getConfirmBundleOrder(true)} canBeClosed={false} showEmailAndPassword={isFinraUnconfirmedUser}/>}
      {showSuitabilityModal && 
        <SuitabilityQuestionsModal 
          showCloseX={false}
          hideModal={(close) => {
            setSuitabilityModal(false)
            // If questions complete, refresh the details page
            if (close === 'complete') {
              getConfirmBundleOrder(true)
            }
          }}
        />}
    </>
  )
}

export default BundleOrder
