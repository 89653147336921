import { images } from 'assets'

/**
 * US Regulations Container Copy
 * Used in multiple components to display a message about US regulations
 * @param {Boolean} multilineCopy - If true, the copy will be displayed in multiple lines, 
 * otherwise it will be displayed in a single line 
 */
const USRegulationsContainerCopy = ({ multilineCopy }) => {
  return (
    <div className={`flex-col items-start justify-between p-4 mb-10 border  border-gray6 lg:mb-20 rounded ${multilineCopy ? 'max-w-[540px] lg:flex-col lg:items-start' : 'lg:flex-row lg:items-center'}`}>
      <div className='flex items-start'>
        <img className='mt-1 mr-4 lg:mt-0' src={images.info} alt='img_info' />
        <span className='body_1'>We are required by US regulations to collect this information. We keep all information private & secure.</span>
      </div>
      <a className={`mt-2 ml-8 text-connectedCobalt body_2  ${multilineCopy ? 'lg:ml-8' : 'lg:ml-0 lg:mt-0'}`} href='https://help.linqto.com/account-security' rel='noreferrer'>Learn more</a>
    </div>

  )
}

export default USRegulationsContainerCopy