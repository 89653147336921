import { useEffect, useState } from 'react'
import { PageLoading, RewardShare, RewardHistory, Tooltip } from 'components'
import { Mixpanel, seoTitleTemplate, formatDecimal, getDomain, Fullstory } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { getRewards } from 'slices/rewardsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { images } from 'assets'
import moment from 'moment'

const Rewards = () => {
  const dispatch = useDispatch()
  const { linqtoBucks, signUpBonus, firstOrderBonus, transactions } = useSelector(state => state.rewards)
  const [pageLoading, setPageLoading] = useState(true)
  const [showExipringTooltip, setShowExpiringTooltip] = useState(false)

  // Total linqto bucks expiring in 30 days
  const totalExpiringAmount = transactions?.reduce((total, curr) => {
    const is30DaysFromExpiring = curr?.expiresAt ? moment(curr?.expiresAt).diff(moment(), 'days') <= 30 : false
    return is30DaysFromExpiring ? total + curr?.amountRemaining : total
  }, 0)
  
  useEffect(() => {
    (async () => {
      const res = await dispatch(getRewards())
      const { meta } = res
      if (meta.requestStatus === 'fulfilled') {
        setPageLoading(false)
        Mixpanel.track('View Linqto Bucks Page')
        Fullstory.track('Page View', { page_name: 'Linqto Bucks' })
      }
    })()
  }, [])

  if (pageLoading) {
    return <>
      <SeoMeta title={seoTitleTemplate('Linqto Bucks Referrral Program')} />
      <PageLoading />
    </>
  }

  return (
    <div>
      <div className='page-container'>
        <img
          src={images.image_RAF_landing_desktop}
          alt=''
          className='absolute h-[574px] -right-[180px] top-[60px] z-[-1] hidden sm:block'
        />
        <div className='inner-container'>
          <div className='flex flex-col items-center text-center lg:flex lg:items-baseline'>
            <div className='px-5 py-1.5 rounded-full inline-block lg:mt-[70px] mb-2 lg:mb-4 my-0 mx-0 bg-[#f9f9f9] medium_2 connectedCobalt'>Reward Balance: {formatDecimal(linqtoBucks)}</div>
            <div className='mb-8'>
              <span className='md:pl-5 medium_1'>Expiring Soon:</span>
              <Tooltip
                header='Expiring Soon'
                content='Linqto Bucks are valid for 90 days from the date of issuance. Any amounts listed under "Expiring Soon" are valid for 30 days or less.'
                showHover={showExipringTooltip}
                setShowHover={setShowExpiringTooltip}
              />
              <span className='medium_1'>&nbsp;{formatDecimal(totalExpiringAmount)}</span>
            </div>
            <img src={images.image_RAF_friends_mobile} alt='' className='block w-3/5 my-5 md:hidden' />
            <div className='giant_2'>Refer a friend,</div>
            <div className='giant_2 connectedCobalt'>get {formatDecimal(firstOrderBonus, true, 0)} Linqto Bucks!</div>
            <div className='mt-4 medium_1 accessibleIndigo'>Your friend gets {formatDecimal(signUpBonus, true, 0)} towards their first investment.</div>
          </div>
          <RewardShare positionIndex={1}/>
          <div className='text-center lg:text-left body_1 accessibleIndigo'>By participating in the Linqto referral program, I have read and agree with the <a className='body_1 connectedCobalt' href={getDomain('/reward-terms-of-use', true)} rel='noopener noreferrer' target='_blank'>Terms and Conditions</a>.</div>
          <img src={images.image_RAF_landing_mobile} alt='' className='relative block w-full lg:hidden -right-6' />
          <div className='mt-[32px] lg:mt-[160px] text-center'>
            <div className='heading_8_eyebrow gray3'>how it works</div>
            <div className='giant_2'>Share private investing and</div>
            <div className='giant_2 connectedCobalt'>earn rewards!</div>
          </div>
          <div className='flex lg:flex-row lg:justify-between lg:gap-[40px] my-8 lg:mb-[100px] flex-col mb-8'>
            <div className='lg:max-w-[30%] mb-8 max-w-full'>
              <img src={images.rewards_share} alt='Share Your Link' />
              <div className='large_1 connectedCobalt'>
                Share Your Link
              </div>
              <div className='mt-4 medium_1'>
                When the friends you refer sign up, they get {formatDecimal(signUpBonus, true, 0)} Linqto Bucks, and you earn {formatDecimal(firstOrderBonus, true, 0)} Linqto Bucks when they make their first purchase!
              </div>
            </div>
            <div className='lg:max-w-[30%] mb-8 max-w-full'>
              <img src={images.rewards_toggle} alt='Share link by email' />
              <div className='large_1 connectedCobalt'>
              Apply Linqto Bucks
              </div>
              <div className='mt-4 medium_1'>
              The referral rewards will show up in your wallet as Linqto Bucks. Simply toggle on as part of your payment to apply on your next purchase.
              </div>
            </div>
            <div className='lg:max-w-[30%] mb-8 max-w-full'>
              <img src={images.rewards_dollar} alt='Unlimited Referrals' />
              <div className='large_1 connectedCobalt'>
              Unlimited Referrals
              </div>
              <div className='mt-4 medium_1'>
              Invite as many friends as you want. Refer five friends, get {formatDecimal(firstOrderBonus * 5, true, 0)} Linqto Bucks!
              </div>
            </div>
          </div>
          <RewardHistory />
        </div>
      </div>
      <div className='flex flex-col lg:items-center rewards-footer py-[86px]'>
        <div className='giant_1 centered'>Get {formatDecimal(firstOrderBonus, true, 0)} Linqto Bucks!</div>
        <div className='mx-4'>
          <RewardShare positionIndex={2}/>
        </div>
      </div>
    </div>
  )
}

export default Rewards
