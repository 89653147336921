import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'slices/commonSlice'
import api from '../apis/private.js'
import parseProfile from '../actions/parsing/parsingProfile'
import _ from 'lodash'

const initialState = {
  userProfile: {},
  trustedContact: {},
  entity: {},
  entities: [],
  brokerInfos: [],
  hasCompletedOrder: false,
  hasKyc: true,
  isAccredited: false,
  isUserProfileComplete: false,
  submitEntityLoading: 'init',
  profileLoading: false,
  initialValues: {},
  deleteLoading: false
}

export const getUserProfile = createAsyncThunk(
  'getUserProfile',
  (_, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .get('/page/userProfile')
      .then((res) => {
        return fulfillWithValue({
          ...res.data,
          allSectors: res.data?.allSectors,
          trustedContact: res.data?.trustedContact || {},
          initialValues: { ...parseProfile(res.data) }
        })
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const editProfile = createAsyncThunk(
  'editProfile',
  (formValues, { dispatch, rejectWithValue, fulfillWithValue }) => {
    if (_.isEmpty(formValues?.trustedContact)) { delete formValues.trustedContact }
    return api
      .post('/page/userProfile', { ...formValues })
      .then((res) => {
        return fulfillWithValue({
          ...res.data,
          initialValues: { ...parseProfile(res.data) }
        })
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)
export const deleteTrustedContact = createAsyncThunk(
  'deleteTrustedContact',
  (_, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return api
      .post('/page/userProfile/deleteTrustedContact')
      .then(() => {
        return fulfillWithValue('success')
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const createEntity = createAsyncThunk(
  'createEntity',
  async (obj, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await api.post('/page/entity', obj)
      return fulfillWithValue(res)
    } catch (err) {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return rejectWithValue(err.response)
    }
  }
)

export const getEntity = createAsyncThunk(
  'getEntity',
  async (entityId, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await api.get(`/page/entity/${entityId}`)
      return fulfillWithValue(res?.data)
    } catch (err) {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return rejectWithValue(err.response)
    }
  }
)
/**
 * Update user profile with new data
 * currently used for updating suitability questions
 * @function updateUserProfile
 * @param {object} payload
 * returns - response object
 */
export const updateUserProfile = createAsyncThunk('updateUserProfile', 
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await api.post('/page/userProfile/update', {
        userProfile: payload
      })
      return fulfillWithValue(res)
    } catch (err) {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return rejectWithValue(err.response)
    }
  })

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    resetProfile: (state) => {
      state.isUserProfileComplete = false
      state.isAccredited = false
      state.hasCompletedOrder = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.fulfilled, (state, { payload }) => {
        return {
          ...state,
          ...payload
        }
      })
      .addCase(editProfile.pending, (state) => {
        state.profileLoading = true
      })
      .addCase(editProfile.fulfilled, (state, { payload }) => {
        return {
          ...state,
          profileLoading: false,
          ...payload
        }
      })
      .addCase(editProfile.rejected, (state) => {
        state.profileLoading = false
      })
      .addCase(deleteTrustedContact.pending, (state) => {
        state.deleteLoading = true
      })
      .addCase(deleteTrustedContact.fulfilled, (state, { payload }) => {
        return {
          ...state,
          deleteLoading: false,
          ...payload
        }
      })
      .addCase(deleteTrustedContact.rejected, (state) => {
        state.deleteLoading = false
      })
      .addCase(getEntity.fulfilled, (state, { payload }) => {
        state.entity = payload
      })
  }
})

export const { resetProfile } = profileSlice.actions

export default profileSlice.reducer
