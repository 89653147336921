import { Modal } from 'components'
import { USDoceumentTypesExplanation } from '../../data'

const USDocumentTypesModal = ({ hideModal }) => {
  return (
    <Modal
      innerStyle='document-types-modal text-left'
      modalHeader='Accepted Document Types'
      clickToClose
      pressToClose
      crossToClose
      hideModal={hideModal}
    >
      <>
        <div className='medium_1 doc-title'>
          Verifies Income:<sup>*</sup>
        </div>
        <ul>
          {USDoceumentTypesExplanation.VERIFIES_INCOME.map((el, id) => (
            <li className='medium_1 doc-list' key={`vi_${id}`}>
              {el}
            </li>
          ))}
        </ul>
        <div className='medium_1 doc-desc'><sup>*</sup>Document types can be combined, but you must prove income for the <strong>two most recent completed tax years.</strong></div>
        <div className='medium_1 doc-title'>Verifies Net Worth:
          <ul>
            {USDoceumentTypesExplanation.VERIFIES_NET_WORTH.map((el, id) => (
              <li className='medium_1 doc-list' key={`vi_${id}`}>
                {el}
              </li>
            ))}
          </ul>
          <div className='doc-type medium_1'>
            {USDoceumentTypesExplanation.ACCEPTED_TYPES}
          </div>
        </div>
      </>
    </Modal>
  )
}

export default USDocumentTypesModal
