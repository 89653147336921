import Modal from 'components/Global/Modal'
import Button from 'components/Global/Button'
import { useHistory } from 'react-router-dom'
import { Mixpanel } from 'utils'

const WithdrawModal = ({ hideModal, ownerName }) => {
  const history = useHistory()

  const contactUs = () => {
    history.push(`/contact?topic=Cash Account Support&message=Re: Withdraw funds from ${ownerName}  cash account. I'd like to withdraw $`)
    Mixpanel.track('Click Contact Us on Withdrawal modal')
  }

  return (
    <Modal
      modalHeader='Contact Us to Withdraw Funds'
      hideModal={hideModal}
      crossToClose
      clickToClose
    >
      <div className='medium_1 content'>
        Please reach out to our team to withdraw your funds.
      </div>
      <div className='inline-flex justify-center'>
        <Button onClick={contactUs}>
          Contact Us
        </Button>
      </div>
    </Modal>
  )
}

export default WithdrawModal
