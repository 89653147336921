import Button from 'components/Global/Button'
import FormError from 'components/Global/FormError'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import ManualVerificationAddressSection from './ManualVerificationAddressSection'
import ManualVerificationBirthInputsContainer from './ManualVerificationBirthInputsContainer'

const UserIdentityAndAddress = () => {
  const { formState: { errors, isSubmitting } } = useFormContext()

  return (
    <div className='verfication-fields'>
      <div className='grid-old'>
        <ManualVerificationBirthInputsContainer />
        <ManualVerificationAddressSection />
      </div>
      <div className='grid-old'>
        <div className='error-container' style={{ paddingLeft: '1rem' }}>
          {Object.keys(errors).length !== 0 && <FormError errorMessageHeader='Required Fields Missing' errorMessageCopy='Please make sure to fill in all required fields' /> }
        </div>
      </div>
      <div>
        <Button type='submit' loading={isSubmitting} disabled={isSubmitting}>Submit</Button>
      </div>
    </div>
  )
}

export default UserIdentityAndAddress
