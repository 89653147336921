
import { useSelector } from 'react-redux'
import { Button } from 'components'
import { useHistory } from 'react-router-dom'
import { Mixpanel, Fullstory } from 'utils'

const RecurringInvestmentCTA = ({ company = null }) => {
  const history = useHistory()
  const { featureFlags } = useSelector(state => state.userSlice)
  const { RecurringInvestmentEnabled } = featureFlags || {}

  const scheduleRecurringInvestment = () => {
    if (company) {
      Mixpanel.track('Portfolio Detail Schedule Recurring Investment Clicked', { 'Company Name': company.name })
      Fullstory.track('Page View', {
        page_name: 'Portfolio Detail Schedule Recurring Investment Clicked',
        company_name: company.name
      })
    } else {
      Mixpanel.track('Portfolio Schedule Recurring Investment Clicked')
      Fullstory.track('Page View', { page_name: 'Portfolio Schedule Recurring Investment Clicked' })
    }
    history.push(`/recurring-investment${company ? `/?companyId=${company?.companyId}` : ''}`)
  }
  if (!RecurringInvestmentEnabled) return null
  return (
    <div className='recurring-investment-banner'>
      <div className='recurring-investment-banner-copy'>
        <div className='gray5 medium_4'>New Feature!</div>
        <div className='gray5'>Schedule a recurring investment to take advantage of low minimums and predictable payments.</div>
      </div>
      <Button
        onClick={scheduleRecurringInvestment}
        mode='secondary'>
        Schedule Recurring Investment
      </Button>
    </div>
  )
}

export default RecurringInvestmentCTA
