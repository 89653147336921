import { useSelector } from 'react-redux'
import Button from 'components/Global/Button'
import { useHistory } from 'react-router-dom'
import { Mixpanel } from 'utils'

const TotalInvestment = () => {
  const { totalInvestmentValue, entities, cashPaymentEnabled, needToCompleteInvestorProfile, total, totalCashAvailable } = useSelector(state => state.portfolioSlice)
  const history = useHistory()

  const totalValue = cashPaymentEnabled ? total : totalInvestmentValue

  let ctaText = 'Start Investing'
  let ctaURL = '/products'

  if (cashPaymentEnabled) {
    if (needToCompleteInvestorProfile) {
      ctaText = 'Complete Profile'
      ctaURL = '/profile'
    }
    if (!needToCompleteInvestorProfile && totalCashAvailable === 0) {
      ctaText = 'Add Funds'
      if (entities.length > 0) {
        ctaURL = '/linqto-wallet'
      } else {
        ctaURL = '/cash-account'
      }
    }
  }

  const handlePush = () => {
    history.push('/products')
    Mixpanel.track('Click Invest More on Portfolio Page')
  }

  if (totalValue === 0) {
    return (
      <div className='total-investment-container'>
        <div className='investment-total-container'>
          <p className='giant_3 investment-total'>$0<sup className='medium_1 super-script'>00</sup></p>
          <span className='medium_1 investment-total_copy'>{cashPaymentEnabled ? 'Total Balance' : 'Total Investment'}</span>
        </div>
        <Button onClick={() => history.push(ctaURL)}>{ctaText}</Button>
      </div>
    )
  }

  const totalInvestmentValueArr = totalValue.toString().split('.')
  let decimal = totalInvestmentValueArr[1]
  if (decimal && decimal.length === 1) {
    decimal += '0'
  }

  return (
    <div className='total-investment-container'>
      <div className='investment-total-container'>
        <p className='giant_3 investment-total'>${(+totalInvestmentValueArr[0]).toLocaleString('en-US')}<sup className='medium_1 super-script'>{decimal || '00'}</sup></p>
        <span className='medium_1 investment-total_copy'>{cashPaymentEnabled ? 'Total Balance' : 'Total Investment'}</span>
      </div>
      <div className='investment-cta-container'>
        <Button onClick={handlePush}>{totalInvestmentValue > 0 ? 'Invest More' : 'Start Investing'}</Button>
      </div>
    </div>
  )
}

export default TotalInvestment
