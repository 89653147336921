import FooterLinks from './partials/FooterLinks'
import { images } from 'assets'
import { getDomain } from 'utils'

const SocialLinks = () => {
  return (
    <div className='flex justify-center w-full mt-10 mb-10 md:justify-start'>
      <a
        href='https://www.instagram.com/linqtoplatform/'
        target='_blank'
        rel='noreferrer'
        className='m-2 md:mr-3'
      >
        <img width={32} height={32} alt='Linqto Instagram' src={images.footer_instagram}></img>
      </a>
      <a
        href='https://www.facebook.com/Linqto'
        target='_blank'
        rel='noreferrer'
        className='m-2 md:mr-3'
      >
        <img width={32} height={32} alt='Linqto Facebook' src={images.footer_facebook}></img>
      </a>
      <a
        href='https://www.linkedin.com/company/linqto-inc./'
        target='_blank'
        rel='noreferrer'
        className='m-2 md:mr-3'
      >
        <img width={32} height={32} alt='Linqto Linkedin' src={images.footer_linkedin}></img>
      </a>
      <a
        href='https://www.youtube.com/channel/UCS8uFXbni3A-4FhwqNqpFBQ'
        target='_blank'
        rel='noreferrer'
        className='m-2 md:mr-3'
      >
        <img width={32} height={32} alt='Linqto Youtube' src={images.footer_youtube}></img>
      </a>
      <a
        href='https://twitter.com/linqtoinc?lang=en'
        target='_blank'
        rel='noreferrer'
        className='m-2 md:mr-3'
      >
        <img width={32} height={32} alt='Linqto X' src={images.footer_X}></img>
      </a>
    </div>
  )
}

const Footer = () => {
  return (
    <div id='footer-div' className='bg-#0f1736 text-white p-10 md:p-20 lg:p-32 rounded-tl-[120px]'>
      <div className='flex flex-col w-full md:justify-between md:flex-row'>
        <div className='w-full md:w-2/3 md:pr-24'>
          <div className='flex-col text-gray4'>
            <a
              href={getDomain('/', true)}
              className='flex justify-center md:justify-start'
            >
              <img className='h-11' alt='Your Access to Private Investment' src={images['footer-logo']} />
            </a>
            <p className='text-white w-9/10 md:w-full mt-10 giant_1 text-center md:text-left lg:text-[64px] lg:leading-none'>Private Investing Made Simple.</p>
            <SocialLinks />
            <div className='flex flex-row justify-center w-full md:w-5/12 md:justify-start'>
              <a aria-label='Download Our App on Google Playstore' role='button' href='https://play.google.com/store/apps/details?id=com.linqto.investor.id' target='_blank' rel='noopener noreferrer'><img className='mr-2 h-11' alt='Download Linqto app on Google Play' src={images.google_play} /></a>
              <a aria-label='Download Our App on App Store' role='button' href='https://apps.apple.com/us/app/linqto/id1464707632' target='_blank' rel='noopener noreferrer'><img className='ml-2 h-11' alt='Download Linqto app on App Store ' src={images.app_store} /></a>
            </div>
          </div>
        </div>
        <div className='w-full md:w-1/2'>
          <FooterLinks />
        </div>
      </div>
      <div>
        <hr className='h-px my-16 bg-gray3 border-0' />
        <div data-testid='copyrightCopy' className='body_1 text-gray2'>&#169; {new Date().getFullYear()} LINQTO Inc.</div>
        <div className='mt-5 body_1 text-gray2'>
          IMPORTANT LEGAL NOTICE AND DISCLOSURES:
          <br/>
          Informational use only. Not intended for reproduction, copying or
          distribution without expressed written consent of Linqto, Inc. et al.
          Not intended to provide investment advice nor does it constitute a
          solicitation or an offer to buy or sell any security or other
          financial instrument. Nothing contained in this website constitutes
          tax, legal, insurance or investment advice. Any use, interference
          with, disclosure or copying of this material is unauthorized and
          strictly prohibited. Investing in securities in private companies is
          speculative and involves a high degree of risk. The recipient must be
          prepared to withstand a total loss of your investment. We strongly
          encourage the recipient to complete their own independent due
          diligence before investing in securities or financial instruments
          including obtaining additional information, opinions, financial
          projections and legal or other investment advice. Linqto Inc., along with the products, services, and securities of its affiliated entities, including Linqto Liquidshares LLC, are not available to Maryland residents.
          <br /><br />
          Linqto is not affiliated or associated with, or endorsed by, any of the companies mentioned herein and the information included has not been checked or confirmed in any way by the same companies. All service marks or trademarks are the property of their respective owners.
          <div className='mt-5 body_1'>
            This site is protected by reCAPTCHA and the Google{' '}
            <a
              rel='noopener noreferrer'
              target='_blank'
              href='https://policies.google.com/privacy'
              className='text-white underline text-decoration-line body_1'
            >
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              href='https://policies.google.com/terms'
              rel='noopener noreferrer'
              target='_blank'
              className='text-white underline text-decoration-line body_1'
            >
              Terms of Service
            </a>{' '}
            apply.
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
