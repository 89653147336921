import { getWebsiteURL } from 'utils'

const OriginationDetailCompanyCopy = ({ company, page, checkIfOption }) => {
  if (page === 'detail') {
    return (
      <div className={`section-wide origination-detail-copy ${company.isLoggedIn ? checkIfOption() ? 'copy-extra-margin' : 'copy-signed-in ' : ''}`}>
        <div className='medium_1'>{company.description} {company.websiteUrl && <a href={getWebsiteURL(company.websiteUrl)} target='_blank' rel='noopener noreferrer'>{company.websiteUrl}</a>}</div>
      </div>
    )
  }
  if (page === 'confirmed') {
    return (
      <div className='section-wide origination-detail-copy copy-confirmed'>
        <h1 className='content-section-title'>Confirmed!</h1>
        <div className='medium_1 content-section'>Congratulations on taking the first step to more liquidity.</div>
        <div className='medium_1'>A member of our sales team will be in touch to get you to the next step.</div>
      </div>
    )
  }
}

export default OriginationDetailCompanyCopy
