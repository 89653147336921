import AccreditationTypesModal from './AccreditationTypesModal'
import { ConditionalRender } from 'components'

const NotAccreditedUserCopy = ({
  setShowAccreditationTypesModal,
  showAccreditationTypesModal,
  status,
  accreditationRegion
}) => {
  return (
    <>
      <ConditionalRender isVisible={accreditationRegion === 'USA'}>
        <div className='medium_1 header-content'>
        Verifying your <a className='link primary' alt='accredited investor' onClick={() => setShowAccreditationTypesModal(true)}>accreditation</a>{' '}
          will give you access to invest in every company available on our platform. U.S. laws and regulations require us to verify if you are an accredited investor.
        </div>
      </ConditionalRender>
      <ConditionalRender isVisible={accreditationRegion !== 'USA'}>
        <div className='medium_1 header-content'>
          You indicated that you are an{' '}
          <a
            data-testid='accreditation-modal'
            className='link primary'
            alt='accredited investor'
            onClick={() => setShowAccreditationTypesModal(true)}
          >
            accredited investor
          </a>{' '}
          at sign up. In order to get you investing, we&apos;ll need to confirm your
          status.
        </div>
      </ConditionalRender>
      {showAccreditationTypesModal && (
        <AccreditationTypesModal
          setShowAccreditationTypesModal={setShowAccreditationTypesModal}
          status={status}
        />
      )}
    </>
  )
}

export default NotAccreditedUserCopy
