import { useSelector } from 'react-redux'
import moment from 'moment'
import { formatDecimal } from 'utils'

const PurchaseCreditsCurrent = () => {
  let { currentCreditEntries } = useSelector((state) => state.purchaseCredits)
  currentCreditEntries = currentCreditEntries.slice().sort((a, b) => b.createdAt - a.createdAt)

  return (
    <>
      <div className='grid-old transactions-table desktop-content'>
        <div className='row transactions-table-header'>
          <div className='column five medium_1 gray5'>ISSUED</div>
          <div className='column six medium_1 gray5'>EXPIRING</div>
          <div className='column five medium_1 gray5 right'>ISSUED AMT.</div>
          <div className='column five medium_1 gray5 right'>REMAINING</div>
        </div>
        {currentCreditEntries?.map((f, i) =>
          <div key={`currentPurchaseCredits_${i}`} className='row transactions-table-body'>
            <div className='column five medium_1 gray5'>{moment(f?.createdAt).format('MM/DD/YYYY')}</div>
            <div className='column six medium_2 gray5'>{moment(f?.expiringAt).format('MM/DD/YYYY')}</div>
            <div className='column five medium_1 gray5 right'>{formatDecimal(f?.amount)}</div>
            <div className='column five medium_2 gray5 right'>{formatDecimal(f?.balance)}</div>
          </div>
        )}
      </div>
      <div className='grid-old transactions-table mobile-content'>
        {currentCreditEntries && currentCreditEntries.map((f, i) =>
          <div key={`currentPurchaseCredits_${i}`} className='row transactions-table-body'>
            <div className='column eight-mobile medium_1 gray5'>ISSUED {moment(f.createdAt).format('MM/DD/YYYY')}<br/>EXPIRING <span className='medium_2'>{moment(f.expiringAt).format('MM/DD/YYYY')}</span></div>
            <div className='column eight-mobile medium_1 gray5 right'>ISSUED AMT. {formatDecimal(f.amount)}<br/>REMAINING <span className='medium_2'>{formatDecimal(f?.balance)}</span></div>
          </div>
        )}
      </div>
    </>
  )
}

export default PurchaseCreditsCurrent
