import React from 'react'

const HistoryColumns = ({ showCompany }) => {
  return (
    <div className={`history-grid-container${showCompany ? '__columns' : '__columns no_company'}`}>
      <span className='medium_1 align-left'>DATE</span>
      {showCompany && <span className='medium_1 align-left'>PRODUCT</span>}
      <span className='medium_1 align-center'>TYPE</span>
      <span className='medium_1 align-right'>SHARES</span>
      <span className='medium_1 align-right'>SHARE PRICE</span>
      <span className='medium_1 align-right'>TOTAL</span>
    </div>
  )
}

export default HistoryColumns
