import { formatDecimal } from 'utils'
import { useSelector } from 'react-redux'

const InsufficientFundsErrors = () => {
  const { commission, conversionRate, fee, total } = useSelector(
    (state) => state.walletSlice
  )
  return (
    <div className='add-funds-summary'>
      <div className='add-funds-summary-row'>
        <div>Currency Conversion Rate</div>
        <div>{formatDecimal(conversionRate, false)}</div>
      </div>
      <div className='add-funds-summary-row'>
        <div>Uphold Fee</div>
        <div>{fee > 0 && '-'}{formatDecimal(fee)}</div>
      </div>
      <div className='add-funds-summary-row'>
        <div>Uphold Commission</div>
        <div>{commission > 0 && '-'}{formatDecimal(commission)}</div>
      </div>
      <div className='medium_2 add-funds-summary-row error'>
        <div>Total</div>
        <div>{formatDecimal(total)}</div>
      </div>
    </div>
  )
}

export default InsufficientFundsErrors
