import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'slices/commonSlice'
import api from '../apis/private.js'
import { Mixpanel, Fullstory } from 'utils'
import parseInvestDetails from 'actions/parsing/parseInvestDetails.js'
import parseInvest from 'actions/parsing/parseInvest.js'

const initialState = {
  companies: [],
  retailCompanies: [],
  nonRetailCompanies: [],
  isLoggedIn: false,
  needToCompleteInvestorProfile: false,
  verticals: [],
  details: {},
  loading: false,
  pageLoading: false,
  cashPaymentEnabled: false,
  wirePaymentEnabled: false,
  hasWalletDeposit: false,
  canSellShares: false,
  ownedShares: 0,
  announcements: [],
  hasSellableShares: false,
  isRetailPurchaser: false,
  userProfileIsComplete: false,
  accreditedStatus: 'NOT_VERIFIED',
  linqtoImpliedValuation: '',
  bundle: [],
  missingFields: [],
  isCRSAcknowledged: false,
  finraUserProfile: {},
  topSellers: [],
  featuredCompanies: [],
  searchedCompanies: []
}

export const getInvestPage = createAsyncThunk('getInvestPage', ({ vertical = '', query = '', sort = '', firstRender }, { dispatch, getState }) => {
  const url = `/page/invest?filterVertical=${encodeURIComponent(vertical)}&searchTerm=${query}&orderBy=${sort}`
  return api
    .get(url)
    .then((res) => {
      // Check initial state and current invest state, if they are equal, fire Mixpanel event (event should be fired only once)
      if (firstRender) {
        Mixpanel.registerUTM()
        Mixpanel.register({ Platform: 'Web', 'Signed In': res.data.isLoggedIn })
        Mixpanel.track('View Linqto Product Invest Page', {
          'Onboarding Complete': !!res?.data?.needToCompleteInvestorProfile,
          'Has Made Wallet Deposit': !!res?.data?.hasWalletDeposit,
          'Cash Account Enabled': !!res?.data?.cashPaymentEnabled,
          'Wire Payments Enabled': !!res?.data?.wirePaymentEnabled
        })
        Fullstory.track('Page View', {
          page_name: 'Products',
          onboarding_complete: !!res?.data?.needToCompleteInvestorProfile,
          has_made_wallet_deposit: !!res?.data?.hasWalletDeposit,
          cash_account_enabled: !!res?.data?.cashPaymentEnabled,
          wire_payments_enabled: !!res?.data?.wirePaymentEnabled
        })
      }

      const parsedData = parseInvest(res.data)
      const isCRSAcknowledged = res?.data?.acknowledgedCrsAt
      const verticals = parsedData.verticals ? parsedData.verticals : getState().invest.verticals
      return { ...parsedData, verticals, isCRSAcknowledged }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
})

export const searchCompaniesbyValue = createAsyncThunk('searchCompanies', async ({ query = '' }, { dispatch, fulfillWithValue, rejectWithValue }) => {
  const url = `/page/invest?searchTerm=${query}`
  try {
    const response = await api.get(url)
    return fulfillWithValue({ searchedCompanies : response.data.companies })
  } catch (error) {
    dispatch(handleServerError(error?.response?.data?.error))
    return rejectWithValue(error.response)
  }
})


/**
 * Fetch bundles
 * @function getBundles
 * @returns {array} bundles - Array of bundles
 * @returns {boolean} isAccredited - Accredited status
 */
export const getBundles = createAsyncThunk('getBundles', async (_, { dispatch }) => {
  try {
    const res = await api.get('/page/bundles')
    return res.data
  } catch (err) {
    if (err.response) {
      dispatch(handleServerError(err.response.data.error))
    }
  }
})

const getCompanyStatus = ({ isSoldOut, promoFlag }) => {
  if (promoFlag === 'COMING_SOON') return 'Coming Soon'
  if (isSoldOut || promoFlag === 'FULLY_SUBSCRIBED') return 'Sold Out'
  return 'Available'
}

export const getInvestDetail = createAsyncThunk('getInvestDetail', ({ companyId }, { dispatch }) => {
  return api
    .get(`/page/invest/${companyId}`)
    .then((res) => {
      const company = res?.data?.company || {}
      const linqtoBucks = res?.data?.linqtoBucks
      Mixpanel.registerUTM()
      Mixpanel.register({
        'Available to Invest': !company?.isSoldOut,
        'Is Linqto Bucks available': !!linqtoBucks,
        'Linqto Bucks amount': linqtoBucks,
        Platform: 'Web',
        'Signed In': res?.data?.isLoggedIn,
        'Has Entity': res?.data?.entities?.length > 0
      })
      Mixpanel.track('View Product Page', {
        'Share value': company.products && company.products.length ? company.products[0]?.sharePrice : 'N/A',
        'Company Status': getCompanyStatus(company),
        Vertical: company?.vertical,
        'Minimum investment amount': company?.sliderMinimum,
        'Company Name': company?.name,
        'Promotion Discount amount': `${res?.data?.discountPercent * 100}%`,
        'Promotion ID': res?.data?.discountNote
      })
      Fullstory.track('Page View', {
        page_name: 'Company',
        share_value: company.products && company.products.length ? company.products[0]?.sharePrice : 'N/A',
        company_status: getCompanyStatus(company),
        vertical: company?.vertical,
        minimum_investment_amount: company?.sliderMinimum,
        company_name: company?.name,
        promotion_discount_amount: `${res?.data?.discountPercent * 100}%`,
        promotion_id: res?.data?.discountNote
      })
      const missingFields = res?.data?.user?.missingProfileFields
      const isFinraNotConfirmedUser = missingFields?.includes('confirmedStatus')
      // fields string are case sensitive
      const isFinraUser = missingFields?.includes('Phone', 'BirthDay', 'BirthMonth', 'BirthYear', 'TaxId') && missingFields.length === 5
      return { details: parseInvestDetails(res?.data), canSellShares: res?.data?.canSellShares, canPayWithShares: res?.data?.canPayWithShares, ownedShares: res?.data?.ownedShares, isLoggedIn: res?.data?.isLoggedIn, missingFields, isFinraUser, isFinraNotConfirmedUser }
    })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
})

export const toggleInterest = createAsyncThunk('toggleInterest', ({ companyUrlName, isInterested }, { dispatch }) => {
  return api.post(`/page/invest/${companyUrlName}/setUserInterest`, { interested: isInterested })
    .then(res => res.data)
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
    })
})

/**
 * Get finra user profile Status
 * @function getFinraUserStatus
 * @param {object} data - User profile data
 */
export const getFinraUserStatus = createAsyncThunk('getFinraUserStatus', async (_, { dispatch, fulfillWithValue }) => {
  try {
    const res = await api.get('/page/confirmUser')
    return fulfillWithValue(res.data)
  } catch (err) {
    if (err.response) {
      dispatch(handleServerError(err.response.data.error))
    }
  }
})

/**
 * Get finra user profile Status
 * @function saveFinraUserStatus
 * @param {object} data - User profile data
 */
export const saveFinraUserStatus = createAsyncThunk('saveFinraUserStatus', async (data, { dispatch, rejectWithValue }) => {
  try {
    await api.post('/page/confirmUser', data)
  } catch (err) {
    if (err.response) {
      dispatch(handleServerError(err.response.data.error))
      return rejectWithValue(err.response?.data?.error)
    }
  }
})

export const investSlice = createSlice({
  name: 'invest',
  initialState,
  reducers: {
    setLinqtoImpliedValuation: (state, { payload }) => {
      state.linqtoImpliedValuation = payload
    },
    resetProductDetail: (state) => {
      state.details = initialState.details
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInvestPage.pending, (state) => {
        state.loading = true
      })
      .addCase(getInvestPage.fulfilled, (state, { payload }) => {
        return { ...state, loading: false, ...payload }
      })
      .addCase(getInvestPage.rejected, (state) => {
        state.loading = false
      })
      .addCase(searchCompaniesbyValue.fulfilled, (state, { payload }) => {
        return { ...state, ...payload }
      })
      .addCase(getInvestDetail.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getInvestDetail.fulfilled, (state, { payload }) => {
        return { ...state, pageLoading: false, ...payload }
      })
      .addCase(getInvestDetail.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(toggleInterest.fulfilled, (state) => {
        return { ...state, details: { ...state.details, isInterested: !state.details.isInterested } }
      })
      .addCase(getBundles.fulfilled, (state, { payload }) => {
        return { ...state, bundle: payload }
      })
      .addCase(getFinraUserStatus.fulfilled, (state, { payload }) => {
        return { ...state, finraUserProfile: payload }
      })
  }
})

export const { setLinqtoImpliedValuation, resetProductDetail } = investSlice.actions

export default investSlice.reducer
