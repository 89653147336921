import { useState } from 'react'
import { images } from 'assets'
import useWindowSize from 'hooks/useWindowSize'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { TABLET_WIDTH_SIZE, Mixpanel, formatDecimal, truncate } from 'utils'
import { useLDFlags } from 'utils/LaunchDarkly'

const BundleCard = ({ bundle }) => {
  const history = useHistory()
  const { width } = useWindowSize()
  const [buttonHovered, setButtonHovered] = useState(false)
  const { bundleInvestViewDetails: showViewDetailLink } = useLDFlags(['bundleInvestViewDetails'])
  const { bundleId, companies, title, bundleName, price, desc } = bundle
  // returns array of company names as 1 string with | as a separator
  const showCompanyName = companies => companies?.map(({ name }) => `${name} `)?.join('| ')

  /**
   * call Mixpanel with bundle id and companies
   * @param {string} bundleId
   * @param {Array} companies
   */
  const handleClick = (bundleId, companies) => {
    // save the scroll position before navigating to the bundle details page
    localStorage?.setItem('investFeedScroll', window.scrollY)
    Mixpanel.track('Click Bundle on Product Invest Page', { Companies: companies, 'Bundle ID': bundleId })
    history.push(`/bundle/${bundleId}`)
  }

  const experimentSection = () => {
    if (showViewDetailLink) {
      return (
        <div className={`btn secondary-underlined ${buttonHovered && 'hovered'}`} >View Detail</div>
      )
    } else {
      return (
        <>
          <span className='medium_4 connectedCobalt bundle-price'>{formatDecimal(price, true, false, false)}</span>
          <span className='body_1 gray3 total-value-text'>Total Value</span>
        </>
      )
    }
  }
  if (width <= TABLET_WIDTH_SIZE) {
    return (
      <div key={bundleId} className='bundle box' onClick={() => handleClick(bundleId, companies)} data-testid={`bundle-${bundleId}`} role='button' tabIndex={0} aria-label={title || bundleName}>
        <div className='bundle__left'>
          <span className='heading_8_eyebrow gray3'>{title || bundleName}</span>
        </div>
        <div className='bundle__middle'>
          <div className='flex bundle__left__img-container'>
            {companies && companies.length ? companies?.map(({ iconUrl }, index) => (
              <img key={index} className='bundle__company_icon' height={40} width={40} src={iconUrl} alt='Bundle Company' />
            )) : null}
          </div>
          <div className='flex-col centered'>
            {experimentSection()}
          </div>
        </div>
        <span className='medium_2'>{truncate(showCompanyName(companies), 90)}</span>
      </div>
    )
  } else {
    return (
      <div key={bundleId} className='bundle box' onClick={() => handleClick(bundleId, companies)} onMouseOver={() => setButtonHovered(true)} onMouseOut={() => setButtonHovered(false)} data-testid={`bundle-${bundleId}`} role='button' tabIndex={0} aria-label={title || bundleName}>
        <div className='bundle__left'>
          <span className='heading_8_eyebrow gray3 bundle__left__eyebrow'>{title || bundleName}</span>
          <div className='flex bundle__left__img-container'>
            {companies && companies.length ? companies?.map(({ iconUrl }, index) => (
              <img key={index} className='bundle__company_icon' src={iconUrl} alt='Bundle Icon' />
            )) : null}
          </div>
        </div>
        <div className='bundle__middle'>
          <span className='medium_2 gray5'>{showCompanyName(companies)}</span>
          <span className='medium_1'>{truncate(desc, 180)}</span>
        </div>
        <div className='bundle__right'>
          {experimentSection()}
        </div>
      </div>
    )
  }
}

const BundleList = () => {
  const { bundle } = useSelector(state => state.investSlice)
  const { bundles } = bundle || {}

  if (!bundles?.length) {
    return null
  }

  return (
    <>
      <div className='bundle-container'>
        <h3 className='bundle-container__header'>Bundles
          <div className='bundle-container__header__bundle-header'>
            <img src={images['preferred-pricing']} alt='Preferred Pricing' />
            <span className='medium_2'>Preferred Pricing</span>
          </div>
        </h3>
        {bundles.map((b) => (
          <BundleCard key={b.bundleId} bundle={b} />
        ))}
      </div>
      <h3 className='mb-3'>Companies</h3>
    </>
  )
}

export default BundleList
