import Button from 'components/Global/Button'
import { useHistory } from 'react-router-dom'

const ResetModalConfirmation = ({ hideModal }) => {
  const history = useHistory()
  return (
    <div className='mt-0 md:mt-4'>
      <div className='mb-4 medium_1'>
        We&apos;ve sent you a link to reset your password.
      </div>
      <div className='mb-4 medium_1'>
       Didn&apos;t get the email? Make sure that you&apos;ve entered the email address associated with
        your Linqto Account. Allow up to five minutes, then check your spam or junk folder.
      </div>
      <div className='flex justify-center'>
        <Button onClick={hideModal}>
          OK
        </Button>
      </div>
      <div className='pt-4 medium_1'>
        Need help?{' '}
        <Button
          onClick={() => history.push('/contact')}
          className='text-connectedCobalt medium_2'
        >
          Contact Support
        </Button>
      </div>
    </div>
  )
}

export default ResetModalConfirmation
