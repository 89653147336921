import { useEffect } from 'react'
import {
  Nav,
  Footer,
  NotFound,
  Contact,
  SignUp,
  SignIn,
  ActivateEmail,
  Verify,
  ConfirmResetPassword,
  OrderDetail,
  OrderConfirmation,
  OrderConfirmed,
  Portfolio,
  Invest,
  InvestRevamp,
  InvestDetail,
  PortfolioDetail,
  Profile,
  BrokerInfo,
  InvestorStatus,
  FinancialAdvisor,
  Identity,
  Documents,
  Security,
  AutoVerification,
  ManualVerification,
  AutoSignIn,
  Rewards,
  UnicornDetails,
  Origination,
  OriginationDetail,
  OriginationOffer,
  OriginationConfirmed,
  DeleteAccount,
  DeleteAccountSuccess,
  RIADashboard,
  SellOrder,
  SellOrderSummary,
  SellOrderDetail,
  RIATc,
  RIAUserAccreditation,
  LinqtoWallet,
  FBOAccount,
  FBOAddFunds,
  FBOAddFundsSummary,
  FBOWithdraw,
  FBOAddBank,
  SuccessSubmission,
  Home,
  Unsubscribe,
  PurchaseCredits,
  FinanciallySophisticatedTest,
  BundleOrder,
  BundleOrderConfirmed,
  RecurringInvestment,
  RecurringInvestmentConfirmed,
  SignUpFINRA,
  Redirect,
  InvestorStatusRevamp,
  InvestList,
  PageLoading,
  AiAuth
} from 'components'
import { Switch, Route, useHistory, useLocation } from 'react-router-dom'
import Modal from './Global/Modal'
import ReactGA from 'react-ga4'
import { SEOvalues } from './data'
import { Helmet } from 'react-helmet'
import FormSuccess from './Global/FormSuccess'
import { useSelector, useDispatch } from 'react-redux'
import { getCoreUser, getFeatureFlags } from 'slices/userSlice'
import MicroBlinkError from './Kyc/AutoVerification/partials/MicroBlinkError'
import NotificationBanner from './Global/NotificationBanner'
import SellOfferDetail from './SellOffer/SellOfferDetail'
import { Mixpanel, Braze, Fullstory, getUserCountry, useLDFlags } from 'utils'
import { setContentCards, setMixpanelExist } from 'slices/commonSlice'
import { useQuery } from 'hooks/useQuery'
import FinanciallySophisticatedResult from './InvestorStatus/partials/FinanciallySophisticatedResult'
import ExitIPO from './ExitIPO/ExitIPO'
import AddEntity from './AddEntity/AddEntity'
import { withLDProvider, useLDClient } from 'launchdarkly-react-client-sdk'
import ReferSplash from './ReferSplash/ReferSplash'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import InvestDetailRedesign from './InvestDetail/InvestDetailRedesign'

const isProd = window.location.host === 'www.linqto.com' || window.location.host === 'app.linqto.com'
const clientSideID = isProd ? '6605a0e7f71c6a0f824acad0' : '6605a0e7f71c6a0f824acacf'

const queryClient = new QueryClient()

const App = (props) => {
  const {
    isImpersonating,
    firstName,
    lastName,
    canImpersonate,
    isSignedIn,
    isUserReturned,
    userId
  } = useSelector((state) => state.userSlice)
  const { isMixpanelExist, systemErr } = useSelector((state) => state.commonSlice)
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const query = useQuery()
  const ldClient = useLDClient()
  const utmSource = query.get('utm_source')
  const { accreditationRevamp, investPageRedesign, investDetailPageRedesign } = useLDFlags(['accreditationRevamp', 'investPageRedesign', 'investDetailPageRedesign'])
  
  useEffect(() => {
    Fullstory.initialize()
    // Disable the auto scroll for browser & setting intial scroll to 0
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'
    }
    window.scrollTo(0, 0)

    dispatch(getCoreUser())
    // init Google Analytics GA4
    ReactGA.initialize([
      { trackingId: isProd ? 'G-3RQ2QB03RK' : 'G-JN2XXRMZXV', testMode: true }
    ])
    ReactGA.event('page_view', {
      page_location: window.location.pathname + window.location.search,
      page_title: document.title
    })

    history.listen(() => {
      ReactGA.event('page_view', {
        page_location: window.location.pathname + window.location.search,
        page_title: document.title
      })

      // setting scroll to the top when moving from page to page
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    })
  }, [])

  // Invest Feed Scroll - If not on products page, remove scroll from local storage (to reset scroll position)
  useEffect(() => {
    if (!location?.pathname?.includes('products')) {
      localStorage?.removeItem('investFeedScroll')
    }
  }, [location.pathname])

  useEffect(() => {
    let utmForZP
    if (utmSource === 'ZeroPark') {
      Fullstory.stopTracking()
      utmForZP = window?.location?.search
    }
    const notSignUpPage = location.pathname !== '/signup' && !isMixpanelExist
    const signUpPageNotZP = location.pathname === '/signup' && utmSource !== 'ZeroPark'
    // NOTE: this is to make sure ZP still being tracked on page other than sign up
    if (notSignUpPage || signUpPageNotZP) {
      Mixpanel.initialize()
      dispatch(setMixpanelExist())
      Fullstory.resumeTracking()
      if (utmForZP) {
        Mixpanel.registerUTM(utmForZP)
      }
    }
  }, [location.pathname, isMixpanelExist])

  useEffect(() => {
    if (systemErr) {
      Mixpanel.track('General Error', { 'Error Type': 'Internal Server Error' })
      Fullstory.track('General Error', { error_type: 'Internal Server Error' })
    }
  }, [systemErr])

  useEffect(() => {
    if (userId > 0) {
      Fullstory.identify(userId.toString())
      ldClient?.identify({
        kind: 'user',
        key: userId,
        hasReferrer: window.location.search.includes('?r=') || window.location.search.includes('?ria='),
        country: getUserCountry(),
        screenSize: window.innerWidth
      })
    }
  }, [userId])
  
  // NOTE: UTM Content Launch Darkly tracking
  useEffect(() => {
    if (window.location.search.includes('utm_content=')) {
      const content = window.location.search?.split('utm_content=')?.[1]?.split('&')?.[0]
      ldClient?.identify({
        kind: 'utm_content',
        utmContent: content
      })
    }
  }, [window.location.search])

  useEffect(() => {
    const getFlags = async () => {
      if (isSignedIn) {
        await dispatch(getFeatureFlags())
      }
    }
    getFlags()
    if (Braze.isInitialized) {
      const brazeCards = Braze.retrieveContentCards()
      if (brazeCards && brazeCards.cards.length) {
        dispatch(setContentCards(brazeCards.cards))
      }
    }
  }, [isSignedIn])

  const showNav = location.pathname !== '/refer-friend'

  return (
    /** Note: Overflow hidden fixes width of this div on mobile devices  */
    <QueryClientProvider client={queryClient}>
      <Helmet>
        ‍<title>{SEOvalues.PAGE_TITLE}</title>
        <meta name='description' content={SEOvalues.PAGE_META_DESCRIPTION} />
        <meta name='keywords' content={SEOvalues.PAGE_KEYWORDS} />
      </Helmet>
      <div style={{ overflow: 'hidden' }}>
        {showNav && <Nav isUserReturned={isUserReturned}/>}
        {canImpersonate && isSignedIn && <NotificationBanner
          text={`Viewing As: ${isImpersonating ? `${firstName} ${lastName}` : 'Myself'}`}
          delay={0}
          fixed
          onClick = {() => { history.push('/ria-dashboard') }}
          actionLabel = {'See All Clients'}
          className='ria-banner'
        />
        }
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/:name/success' component={FormSuccess} />
          <Route exact path='/products' component={investPageRedesign === true ? InvestRevamp : investPageRedesign === false ? Invest : PageLoading} />
          <Route exact path='/products/:id' component={investDetailPageRedesign ? InvestDetailRedesign : InvestDetail} />
          <Route exact path='/products-list' component={InvestList} />
          <Route
            exact
            path='/orderconfirmation/:id'
            component={OrderConfirmation}
          />
          <Route exact path='/orderconfirmed/:id' component={OrderConfirmed} />
          <Route
            exact
            path='/bundle/:bundleId'
            component={BundleOrder}
          />
          <Route
            exact
            path='/bundle-confirmed/:tradeId'
            component={BundleOrderConfirmed}
          />
          <Route exact path='/signup/create-account' component={SignUp} />
          <Route exact path='/signupfin' component={SignUpFINRA} />
          <Route exact path='/signup' component={SignUp} />
          <Route exact path='/signin' component={SignIn} />
          <Route exact path='/auto-sign-in' component={AutoSignIn} />
          <Route exact path='/signin/mfas' component={SignIn} />
          <Route
            exact
            path='/signin/mfas/:mfaType/:userMfaId'
            component={SignIn}
          />
          <Route exact path='/activate' component={ActivateEmail} />
          <Route
            exact
            path='/verify'
            component={Verify}
          />
          <Route
            exact
            path='/confirmResetPassword'
            render={() => <ConfirmResetPassword {...props} />}
          />
          <Route exact path='/profile' component={Profile} />
          <Route exact path='/order/:id' component={OrderDetail} />
          <Route exact path='/portfolio' component={Portfolio} />
          <Route exact path='/rewards' component={Rewards} />
          <Route exact path='/market-insights/:companyName' component={UnicornDetails} />
          <Route
            exact
            path='/portfolio/:companyId'
            component={PortfolioDetail}
          />
          <Route exact path='/linqto-wallet' component={LinqtoWallet} />
          <Route exact path='/cash-account' component={FBOAccount} />
          <Route exact path='/cash-account/add-funds' component={FBOAddFunds} />
          <Route path='/cash-account/add-funds-summary' component={FBOAddFundsSummary} />
          <Route path='/cash-account/withdraw' component={FBOWithdraw} />
          <Route exact path='/cash-account/add-bank' component={FBOAddBank} />
          <Route exact path='/registered-investment-advisor' component={RIATc} />
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/investor-status' component={accreditationRevamp ? InvestorStatusRevamp : accreditationRevamp === false ? InvestorStatus : PageLoading} />
          <Route exact path='/financial-advisor' component={FinancialAdvisor} />
          <Route exact path='/exit-ipo/:companyId' component={ExitIPO} />
          <Route
            exact
            path='/identity/auto-verification/:steps'
            component={AutoVerification}
          />
          <Route
            exact
            path='/identity/fail-submission'
            component={MicroBlinkError}
          />
          <Route
            exact
            path='/identity/success-submission'
            component={SuccessSubmission}
          />
          <Route exact path='/identity' component={Identity} />
          <Route
            exact
            path='/identity/manual-verification'
            component={ManualVerification}
          />
          <Route exact path='/documents' component={Documents} />
          <Route exact path='/settings' component={Security} />
          <Route
            exact
            path='/settings/:mfaType/:userMfaId/:steps'
            component={Security}
          />
          <Route
            exact
            path='/settings/delete-account'
            component={DeleteAccount}
          />
          <Route
            exact
            path='/settings/delete-account-success'
            component={DeleteAccountSuccess}
          />
          <Route path='/sell/offerconfirmation/' component={OriginationOffer} />
          <Route
            path='/sell/offerconfirmed/:originationId'
            component={OriginationConfirmed}
          />
          <Route path='/sell/:companyName' component={OriginationDetail} />
          <Route path='/sell' component={Origination} />
          <Route path='/broker-information/:entityId' component={BrokerInfo} />
          <Route path='/broker-information/' component={BrokerInfo} />
          <Route path='/ria-dashboard/' component={RIADashboard} />
          <Route path='/offer/:id' component={SellOfferDetail} />
          <Route exact path='/sell-order/create/:id' component={SellOrder} />
          <Route exact path='/sell-order/review' component={SellOrderSummary} />
          <Route exact path='/sell-order/:id' component={SellOrderDetail} />
          <Route exact path='/ria/verify-client-accreditation/:userId' component={RIAUserAccreditation} />
          <Route exact path='/unsubscribe' component={Unsubscribe} />
          <Route exact path='/purchase-credits' component={PurchaseCredits} />
          <Route exact path='/investor-status/financial-sophisticated-quiz/result' component={FinanciallySophisticatedResult} />
          <Route exact path='/investor-status/financial-sophisticated-quiz' component={FinanciallySophisticatedTest} />
          <Route path='/entity/:entityId' component={AddEntity} />
          <Route path='/entity' component={AddEntity} />
          <Route path='/recurring-investment/:recurringEventId' component={RecurringInvestment} />
          <Route path='/recurring-investment/' component={RecurringInvestment} />
          <Route exact path='/recurring-investment-confirmed/:recurringEventId' component={RecurringInvestmentConfirmed} />
          <Route path='/refer-friend' component={ReferSplash} />
          <Route path='/redirect' component={Redirect} />
          <Route path='/ai-auth' component={AiAuth} />
          <Route path='*' component={NotFound} />
        </Switch>
        <Footer
        />
        {systemErr && (
          <Modal
            clickToClose={false}
            pressToClose={false}
            crossToClose={false}
            hideModal={() => console.log('err modal')}
            modalHeader='An Unexpected Error Occurred'
          >
            <div className='medium_1'>
              Please try refreshing the page or go back to the previous page.{' '}
              <br />
              You can also contact us at{' '}
              <a className='link primary' href='mailto:support@linqto.com'>
              support@linqto.com
              </a>
              .
            </div>
          </Modal>
        )}
        <div id='chat-bot'></div>
      </div>
    </QueryClientProvider>
  )
}

export default withLDProvider({
  clientSideID
})(App)
