import '@linqto-team/react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from '@linqto-team/react-responsive-carousel'
import { Braze, Mixpanel, MOBILE_WIDTH_SIZE, TABLET_WIDTH_SIZE } from 'utils'
import useWindowSize from 'hooks/useWindowSize'
import { images } from 'assets'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Button from 'components/Global/Button'

const MessagingCarousel = ({ data, page }) => {
  const history = useHistory()
  const { width } = useWindowSize()
  const [, setCurrentPromotion] = useState(null)

  useEffect(() => {
    const item = data?.[0] || null
    if (item) {
      setCurrentPromotion(item)
      Mixpanel.track('View Messaging Tile', { Page: page, Message: item?.message, CTA: item?.callToActionLabel })
    }
  }, [data])

  const renderArrow = (type, handleClick, remaining) => {
    return (
      width >= TABLET_WIDTH_SIZE ? <div onClick={handleClick} className={`pointer ${!remaining && 'disabled'}`} role='button'>
        <img src={images[`carousel-${type}`]} height={26} width={26} alt='View More' />
      </div> : null
    )
  }

  const handleCallToAction = (link, id) => {
    Braze.track('Click Messaging Tile Button', { id })
    if (link?.includes('http')) {
      window.location.href = link
    } else {
      history.push(link)
    }
  }

  const handleFn = (index) => {
    setCurrentPromotion((prevState) => {
      Mixpanel.track('Change Messaging Tile', { Page: page, Message: prevState?.message, CTA: prevState?.callToActionLabel })
      Mixpanel.track('View Messaging Tile', { Page: page, Message: data[index]?.message, CTA: data[index]?.callToActionLabel })
      return data[index]
    })
  }

  const showArrowsAndIndicator = !!(data && data.length > 1)

  return (
    <Carousel
      emulateTouch
      showStatus={false}
      showThumbs={false}
      centerMode={width <= MOBILE_WIDTH_SIZE}
      onChange={handleFn}
      centerSlidePercentage={100}
      showIndicators={showArrowsAndIndicator}
      renderArrowPrev={(handleClick, remaining) => showArrowsAndIndicator ? renderArrow('prev', handleClick, remaining) : null}
      renderArrowNext={(handleClick, remaining) => showArrowsAndIndicator ? renderArrow('next', handleClick, remaining) : null}>
      {data.map((item, i) => (
        <Button key={i} className='carousel-item' onClick={() => handleCallToAction(item?.url, item?.id)} ariaLabel='Carousel item'>
          <img src={item.imageUrl} alt='Carousel item' className='carousel-img'/>
        </Button>
      ))}
    </Carousel>
  )
}

export default MessagingCarousel
