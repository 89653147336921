import { Modal } from 'components'
import Button from './Button'
import ReactHtmlParser from 'react-html-parser'

const ErrorModal = ({ error, buttonTitle = null, hideModal, crossToClose = false, centered = false }) => {
  if (!error.type) return null
  return (
    <Modal hideModal={hideModal} modalHeader={error.title} crossToClose={crossToClose}>
      <div className={`medium_1 content ${centered ? 'text-center' : ''}`}>{ReactHtmlParser(error.message)}</div>
      <div className='inline-flex justify-center'><Button onClick={hideModal}>{buttonTitle ? buttonTitle : 'OK'}</Button></div>
    </Modal>
  )
}

export default ErrorModal
