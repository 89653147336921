import { images } from 'assets'

const OriginationDetailHeader = () => {
  return (
    <header className='page-header'>
      <img src={images['radial-lines-gray']} className='unicorn-backgeound' alt='unciron background'/>
    </header>
  )
}

export default OriginationDetailHeader
