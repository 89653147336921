import { images } from 'assets'
import { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Mixpanel, getDomain, Fullstory } from 'utils'

const MobileNav = ({ items, filterNavByStatus, setToggleStatus, customNavItem }) => {
  const { pathname } = useLocation()
  const [toggleDropdown, setToggleDropdown] = useState('')
  const [signOut, setSignOut] = useState(null)
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = ''
    }
  }, [])

  // On mount, we search through the nav items to set the current dropdown based on the url pathname (if found)
  useEffect(() => {
    items.map(item => {
      if (item && item.submenu) {
        item.submenu.forEach(subItem => {
          if (subItem.route === pathname) {
            setToggleDropdown(item.name)
          }
          if (subItem.tag === 'signout') {
            setSignOut(subItem)
          }
        })
      }
    })
  }, [])

  useEffect(() => {
    const appHeight = () => {
      document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    appHeight()
    window.addEventListener('resize', appHeight)
  }, [])

  const handleClick = (item) => {
    if (item.submenu && item.submenu.length) {
      setToggleDropdown(item.name === toggleDropdown ? '' : item.name)
    } else {
      Mixpanel.track('Click Main Nav', { 'Main Nav Item': item?.name })
      Fullstory.track('Click Main Nav', {
        main_nav_item: item?.name,
        current_url: item?.route
      })
      setToggleStatus(false)
    }
  }
  
  return (
    <div className='main-nav mobile-active' role='navigation' aria-label='Mobile Navigation'>
      <div className='mobile-container'>
        {filterNavByStatus(items).map((item) => (
          <span key={item.tag} className={`animate-up ${item.name === toggleDropdown ? 'down' : 'up'}`}>
            <NavLink 
              to={item.route !== '/' && item.route} 
              onClick={() => handleClick(item)}
              aria-label={`Open ${item.tag} menu`}
              aria-expanded={item.name === toggleDropdown}
            >
              <div className={`flex items-center justify-center mobile-nav-items ${toggleDropdown === item.name ? 'cobalt' : ''}`}>
                {item.name}
                {item.submenu && item.submenu.length && <img src={images['chevron-down']} height={20} width={20} alt=''/>}
              </div>
            </NavLink>
            {item.submenu && item.submenu.length && item.submenu.map((subItem) => (
              ((toggleDropdown === item.name) && subItem.showOnMobile) ? (
                subItem.cms && window.location.hostname.includes('app') ? <a
                  key={`nav-${subItem.tag}`}
                  href={getDomain(subItem.route, subItem?.cms)}
                  onClick={() => handleClick(subItem)}
                  aria-label={`Navigate to ${subItem.name}`}
                ><p>{subItem.name}</p>
                </a>
                  : subItem?.routeType === 'external' ? <a
                    key={`nav-${subItem.tag}`}
                    onClick={subItem.action}
                    aria-label={`Navigate to ${subItem.name}`}
                  ><p>{subItem.name}</p></a> :
                    <NavLink key={subItem.tag} to={subItem.route} onClick={() => handleClick(subItem)} aria-label={`Navigate to ${subItem.name}`}><p>{subItem.name}{subItem?.secondaryItem && <div className='nav-secondary-item'>{subItem?.secondaryItem}</div>}</p></NavLink>
              ) : null
            ))}
          </span>
        ))}
      </div>
      {customNavItem()}
      {signOut && <div className='mobile-nav-items sign-out-btn' onClick={signOut?.action} aria-label='Sign Out'>Sign Out</div>}
    </div>
  )
}

export default MobileNav
