import { images } from 'assets'
import AsyncSelect from 'react-select/async'

const OriginationSearch = ({ loadSuggestedOptions, onSelectionChange, onInputChange, openMenu }) => {
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%'
    }),
    control: (provided) => ({
      ...provided,
      border: '1px solid #0039EC',
      height: '48px',
      borderRadius: '8px',
      paddingLeft: '8px',
      fontWeight: 700,
      zIndex: '99'
    }),
    input: (provided) => ({
      ...provided,
      color: '#050505'
    }),
    loadingIndicator: (provided) => ({
      ...provided,
      display: 'none'
    }),
    placeholder: (provided) => ({
      ...provided,
      fontWeight: 600,
      color: '#333333'
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '0px 0px 16px 16px',
      marginTop: '-2px',
      borderTop: '0px',
      zIndex: '89'
    }),
    option: (provided) => ({
      ...provided,
      width: '100%',
      color: '#050505',
      padding: '16px 24px'
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: '#333333',
      textAlign: 'left',
      paddingLeft: '20px',
      paddingBottom: '16px',
      lineHeight: '20px'
    })
  }
  return (
    <div className='origination-body-section box search-box'>
      <div className='origination-body-title large_1'>Find Your Company</div>
      <div className='origination-search'>
        <div className='origination-search-input'>
          <AsyncSelect
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null
            }}
            cacheOptions
            styles={customStyles}
            loadOptions={loadSuggestedOptions}
            onChange={onSelectionChange}
            onInputChange={onInputChange}
            noOptionsMessage={() => openMenu ? 'No results found. We may not be purchasing  this. Please check your spelling.' : null}
            LoadingMessage={() => 'Searching'}
            isClearable={true}
            placeholder='Search All Unicorns'
            aria-label='origination-search'
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#f4f5f4',
                primary: '#0039EC'
              }
            })}
          />
          <img alt='Search Companies' src={images['icon-search']} className='search-icon'/>
        </div>
        <img src={images['origination-unicorn']} className='origination-search-img' alt='Origination Search'/>
      </div>
    </div>
  )
}

export default OriginationSearch
