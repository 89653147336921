import ProductDocuments from 'components/OrderDetail/partials/ProductDocuments'
import { useSelector } from 'react-redux'

const Agreements = ({ selectedAccount }) => {
  const { documents } = useSelector(
    (state) => state.placeOrderSlice
  )
  return (
    <div className='buy-order-section top-aligned'>
      <div className='large_1 buy-order-section-title'>
        <span>3</span> Agreements
      </div>
      <div className='buy-order-section-detail'>
        <ProductDocuments documents={documents} type='order-confirmation' selectedAccount={selectedAccount} />
      </div>
    </div>
  )
}

export default Agreements
