import { Button } from 'components'
import RadioButton from 'components/Global/Inputs/RadioButton'
import React from 'react'
import { useFormContext } from 'react-hook-form'

const DeleteAccountReasons = ({ reasons, url }) => {
  const { watch } = useFormContext()
  const reason = watch('reason')

  return (
    <div className='radio-group'>
      {Object.values(reasons).map((ele, id) => (
        <React.Fragment key={id}>
          <RadioButton className='-my-2' label={ele} name='reason' value={ele} />
          {reason === ele && reason === reasons.TOO_MANY_EMAIL && (
            <Button mode='secondary' onClick={() => window.open(url, '_blank')} ariaLabel='Update Email Preferences'>
              Update Email Preferences
            </Button>)}
        </React.Fragment>
      ))}
    </div>
  )
}

export default DeleteAccountReasons
