import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Mixpanel, useLDFlags } from 'utils'
import Button from 'components/Global/Button'

const CompleteProfileBanner = ({ setShowQuestionsModal }) => {
  const history = useHistory()
  const { details: { name, user } } = useSelector((state) => state.investSlice)
  const { missingProfileFields, isAccredited } = user || {}
  const { featureFlags } = useSelector((state) => state.userSlice)
  const { RequireBrokerDealerSuitabilityQuestions } = featureFlags || {}
  const { suitabilityQuestions } = useLDFlags(['suitabilityQuestions'])

  // If user has feature flags enabled, has missing suitability profile fields and is already accredited, show the questions modal
  // otherwise, redirect to profile page to complete the profile
  const hasMissingFields = missingProfileFields?.length === 3 && missingProfileFields.includes('investmentHorizon') && missingProfileFields.includes('riskTolerance') && missingProfileFields.includes('investmentExperience')
  const showQuestionsModal = suitabilityQuestions && RequireBrokerDealerSuitabilityQuestions && hasMissingFields && isAccredited
  const bannerText = showQuestionsModal ? 'A few items in your Investor Profile need to be completed before placing an order.' 
    : 'Please complete your investor profile to place an order.'
  const goToProfile = () => {
    Mixpanel.track('Click Complete Profile Banner on Product Invest Page', { 'Company Name': name })
    if (showQuestionsModal) {
      setShowQuestionsModal(true)
    } else {
      history.push('/profile')
    }
  }

  return (
    <Button className='complete-profile-banner-container' onClick={goToProfile} data-testid='complete-profile-banner' ariaLabel='Please complete your investor profile to place an order'>
      <p className='text-left medium_1 accessibleIndigo md:w-[85%]'><span className='medium_2'>Welcome to Linqto!</span> {bannerText}</p>
      <span className='inline-text-link click-complete-profile'>Complete Profile</span>
    </Button>
  )
}

export default CompleteProfileBanner
