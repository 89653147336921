import { Modal } from 'components'

const UnicornMosaicModal = ({ setShowModal }) => {
  const hideModal = () => {
    setShowModal(false)
  }
  return (
    <Modal
      innerStyle='mosaic-modal text-left'
      hideModal={hideModal}
      modalHeader='What is a Mosaic Score?'
      clickToClose
      pressToClose
      crossToClose>
      <div className='mosaic-modal-web content'>
        <div className='mosaic-modal-section medium_1'>
          Mosaic score predicts the health and future success potential of private tech companies using 4 models:
          <span className='medium_2'>{' '}Momentum,</span>
          <span className='medium_2'>{' '}Market,</span>
          <span className='medium_2'>{' '}Money,</span> and
          <span className='medium_2'>{' '}Management.</span>
        </div>
        <span className='medium_2'>{' '}Momentum</span> is informed by news/media, sentiment, partnership & customer momentum, and social media.
        <span className='medium_2'>{' '}Market</span> refers to the quality of the market - is it a hot industry? The
        <span className='medium_2'>{' '}Money</span> model assesses the financial health of a company.  And the
        <span className='medium_2'>{' '}Management</span> model centers around the founding and management team and their work
        accomplishments, like previous exits, funding rounds, industry experience, as well as their educational background and network.
      </div>
    </Modal>
  )
}

export default UnicornMosaicModal
