import { Modal, Button } from 'components'
import ReferFriendCopy from './ReferFriendCopy'

const ReferralHowItWorksModal = ({ closeModal }) => {
  return (
    <Modal innerStyle='signup-error' modalHeader='How it works' crossToClose hideModal={closeModal}>
      <div className='items-center-justify-center'>
        <ReferFriendCopy />
      </div>
      <div className='inline-flex justify-center mt-6 mb-6'>
        <Button onClick={closeModal}>
          OK
        </Button>
      </div>
    </Modal>
  )
}

export default ReferralHowItWorksModal
