import { useFormContext } from 'react-hook-form'
import TextInput from 'components/Global/Inputs/TextInput'
import FBOAddFundsCTA from './FBOAddFundsCTA'
import FBOAddFundsFundingSource from './FBOAddFundsFundingSource'
import InsufficientFundsErrors from './InsufficientFundsErrors'
import { useEffect } from 'react'
import { useQuery } from 'hooks/useQuery'

const FBOAddFundsForm = ({
  onSubmit,
  cancelAddFunds,
  selectedAccount,
  setSelectedAccount,
  insufficientError,
  setInsufficientError,
  setShowWireInstruction,
  connectPlaidAccount,
  entityId
}) => {
  const query = useQuery()
  const amount = query.get('amountRequired')

  const { handleSubmit, setValue, clearErrors } = useFormContext()

  // NOTE: Amount Required is pre-populated from the buy order page as a query param
  useEffect(() => {
    if (amount) {
      validatePriceValues(amount)
    }
  }, [])

  const validatePriceValues = (inputVal) => {
    const updatedValue = inputVal.charAt(0) === '$' ? inputVal.substring(1) : inputVal
    setValue('amount', `$${Number(updatedValue) ? Number(updatedValue).toFixed(2) : '0.00'}`)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <FBOAddFundsFundingSource
        selectedAccount={selectedAccount}
        setSelectedAccount={setSelectedAccount}
        insufficientError={insufficientError}
        setInsufficientError={setInsufficientError}
        setShowWireInstruction={setShowWireInstruction}
        connectPlaidAccount={connectPlaidAccount}
        entityId={entityId}
        label='Funding Source'
        testId='addFundsSourceSelect'
      />
      {selectedAccount?.label !== 'Wire Transfer' && (
        <>
          <TextInput
            name='amount'
            ariaLabel='Amount'
            label='Amount'
            placeholder='$0.00'
            type='tel'
            className='number-format'
            onChange={() => {
              if (insufficientError) {
                setInsufficientError(null)
                clearErrors('amount')
              }
            }}
            onBlur={(e) => {
              validatePriceValues(e.target.value)
            }}
            handleKeyPress={(e) => {
              if ((e.key !== '.' && isNaN((Number(e.key)))) || e.key === null || e.key === ' ' || e.key === '-') {
                e.preventDefault()
              }
            }}
          />
          {insufficientError && <InsufficientFundsErrors />}
          <FBOAddFundsCTA
            cancelAddFunds={cancelAddFunds}
            onSubmit={onSubmit}
            selectedAccount={selectedAccount}
          />
        </>
      )}
    </form>
  )
}

export default FBOAddFundsForm
