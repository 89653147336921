import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'slices/commonSlice'
import api from '../apis/private.js'
import parseContactUs from 'actions/parsing/parseContactUs'

const initialState = {
  pageLoading: false,
  submitLoading: false,
  firstName: '',
  lastName: '',
  email: '',
  topics: [],
  phone: '',
  initialValues: {}
}

export const getContactUs = createAsyncThunk('getContactUs', (_, { dispatch, fulfillWithValue, rejectWithValue }) => {
  return api
    .get('/page/contactUs')
    .then(res => {
      const { addresses, topics, firstName, lastName, email, phone } = parseContactUs(res.data)
      if (res && res.data) {
        return fulfillWithValue({ addresses, topics, initialValues: { firstName, lastName, email, phone } })
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return rejectWithValue(err.response)
    })
})

export const submitContactUs = createAsyncThunk('submitContactUs', async (formValue, { dispatch, rejectWithValue }) => {
  try {
    await api.post('/page/contactUs', formValue)
  } catch (err) {
    if (err.response) {
      dispatch(handleServerError(err.response.data.error))
    }
    return rejectWithValue(err.response)
  }
})

export const contactUsSlice = createSlice({
  name: 'contactUsSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getContactUs.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getContactUs.fulfilled, (state, { payload }) => {
        return payload
      })
      .addCase(getContactUs.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(submitContactUs.pending, (state) => {
        state.submitLoading = true
      })
      .addCase(submitContactUs.fulfilled, (state) => {
        state.submitLoading = false
      })
      .addCase(submitContactUs.rejected, (state) => {
        state.submitLoading = false
      })
  }
})

export default contactUsSlice.reducer
