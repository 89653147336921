import { Button } from 'components'
import { useHistory, useLocation } from 'react-router-dom'
import { getDomain } from 'utils'

const FormSuccess = () => {
  const location = useLocation()
  const history = useHistory()
  const type = location.pathname?.split('/')[1] || ''

  const renderSuccessPage = () => {
    switch (type) {
    case 'contact': {
      return (<div className='inner-container contact-success-container'>
        <div>
          <h1>Your message was sent successfully!</h1>
          <span className='medium_1'>We&apos;ll be in touch soon, but while you&apos;re waiting, you may want to browse our FAQs.</span>
        </div>
        <div className='contact-success-content'>
          <a href={getDomain('/faq', true)}>Frequently Asked Questions</a>
          <Button onClick={() => history.push('/products')}>Browse Investment Opportunities</Button>
        </div>
      </div>)
    }
    case 'contact-trader': {
      return (<div className='inner-container contact-success-container'>
        <div className='contact-success-header'>
          <h1>Your message was sent successfully!</h1>
          <span>We&apos;ll be in touch soon.</span>
        </div>
        <div className='contact-success-content'>
          <span onClick={() => history.goBack()} role='button' tabIndex={0}>Return to Order Details</span>
          <Button onClick={() => history.push('/')}>Return to Home Page</Button>
        </div>
      </div>)
    }
    default:
      return null
    }
  }

  return (
    <div className='page-container shrink-container'>
      {renderSuccessPage()}
    </div>
  )
}

export default FormSuccess
