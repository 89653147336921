import Button from 'components/Global/Button'
import SeoMeta from 'components/Global/SeoMeta'
import { PageLoading } from 'components'
import { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { getSellOrder } from 'slices/sellOrderSlice'
import { seoTitleTemplate, Mixpanel, formatDecimal, renderTotalsOrDash } from 'utils'
import { schema } from 'schemas/sellOrderSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { sellOrderErrors } from 'components/data'
import SellOrderHeader from './partials/SellOrderHeader'
import SellOrderContainer from './partials/SellOrderContainer'
import InCompleteProfileModal from 'components/FBOAccount/partials/InCompleteProfileModal'
import ErrorModal from 'components/Global/ErrorModal'

const SellOrder = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams()
  const { shareOwningAccounts, loading, subTotal, minimumTotalSellPrice, projectedGain, avgOriginalHeldPricePerShare, totalOriginalHeldSharePrice } = useSelector(state => state.sellOrder)
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [selectedAvailableShares, setSelectedAvailableShares] = useState(0)
  const [showIncompleteProfileModal, setShowIncompleteProfileModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [summaryError, setSummaryError] = useState({})
  const [pageLoading, setPageLoading] = useState(true)

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    context: { hasEntities: shareOwningAccounts.length > 1, selectedAccount, minSell: { subTotal, minimumTotalSellPrice, selectedAvailableShares } }
  })
  const { handleSubmit, setError } = methods

  useEffect(() => {
    (async () => {
      const res = await dispatch(getSellOrder(id))
      setPageLoading(false)
      const { meta, payload } = res
      if (meta.requestStatus === 'fulfilled') {
        Mixpanel.track('View Create Sell Order Page', { 'Company Name': payload?.companyName })
      } else if (meta.requestStatus === 'rejected') {
        if (payload?.error === 'PROFILE_INCOMPLETE') {
          Mixpanel.track('View Incomplete Profile Modal')
          setShowIncompleteProfileModal(true)
        }
      }
      methods.reset({ sharesForAccount: 0 })
    })()
  }, [])

  const onSubmit = async (formValues) => {
    if (!formValues.sharesForAccount) {
      setError('sharesForAccount', {
        type: 'required',
        message:
            'Enter valid amount'
      })
    } else {
      const entityId = selectedAccount.entityId || null
      history.push(`/sell-order/review/?companyUrlName=${id}&shareCount=${formValues.sharesForAccount}${entityId ? `&entityId=${entityId}` : ''}`)
    }
  }

  const handleBack = event => {
    event.preventDefault()
    history.goBack()
  }

  const closeIncompleteModal = () => {
    setShowIncompleteProfileModal(false)
    Mixpanel.track('Click Go to Investor Profile')
    history.push('/profile')
  }

  const renderErrorModal = () => {
    Mixpanel.track('Sell Order Error', { 'Error Type': sellOrderErrors.SHARE_PRICE_NOT_AVAILABLE.mixPanel })
    setSummaryError(sellOrderErrors.SHARE_PRICE_NOT_AVAILABLE)
    setShowErrorModal(true)
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Create Sell Order')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Create Sell Order')} />
      <FormProvider {...methods}>
        <div className='page-container sell-order-container'>
          <form className='inner-container' onSubmit={handleSubmit(onSubmit)}>
            <SellOrderHeader title='Create Sell Order' showHelp={true} />
            <div className='sell-order-page-container'>
              <SellOrderContainer
                setSelectedAccount={setSelectedAccount}
                selectedAccount={selectedAccount}
                selectedAvailableShares={selectedAvailableShares}
                setSelectedAvailableShares={setSelectedAvailableShares}
                renderErrorModal={renderErrorModal}
              />
              <div className='sell-order-totals sell-offer-order '>
                <div className='sell-order-row total-spaced'>
                  <span>Cost Basis of Shares</span>
                  <span>{renderTotalsOrDash(avgOriginalHeldPricePerShare, formatDecimal(totalOriginalHeldSharePrice))}</span>
                </div>
                <div className='sell-order-row'>
                  <span>Average Cost / Share</span>
                  <span>{renderTotalsOrDash(totalOriginalHeldSharePrice, formatDecimal(avgOriginalHeldPricePerShare))}</span>
                </div>
                <div className='sell-order-row'>
                  <span>Your Projected Gain</span>
                  <span>{renderTotalsOrDash(projectedGain, formatDecimal(projectedGain))}</span>
                </div>
              </div>
              <div className='sell-order-totals'>
                <div className='sell-order-row spaced'>
                  <span className='customer-support-copy'>
                  Shares are sold in the order they were purchased.
                  </span>
                </div>
              </div>
              <div className='gap-4 mt-8 ml-0 md:ml-auto md:inline-flex'>
                <Button disabled={loading} mode='tertiary' onClick={handleBack}>Cancel</Button>
                <Button disabled={loading || !selectedAccount || selectedAccount?.sellableShareCount === 0} type='submit'>Sell</Button>
              </div>
            </div>
          </form>
        </div>
      </FormProvider>
      {showIncompleteProfileModal && <InCompleteProfileModal hideModal={closeIncompleteModal} type='SELL_ORDER' crossToClose={false} />}
      {showErrorModal && <ErrorModal error={summaryError} crossToClose={true} hideModal={() => setSummaryError(false)}/>}
    </>
  )
}

export default SellOrder
