import mixpanel from 'mixpanel-browser'

const parsedKeyWords = {
  cid: 'Campaign ID',
  agid: 'Ad Group ID',
  placement: 'Placement'
}

const getQueryParam = (url, param) => {
  // Expects a raw URL
  // eslint-disable-next-line no-empty-character-class
  param = param.replace(/[[]/, '[').replace(/[]]/, ']')
  const regexS = '[?&]' + param + '=([^&#]*)'
  const regex = new RegExp(regexS)
  const results = regex.exec(url)
  if (
    results === null ||
    (results && typeof results[1] !== 'string' && results[1].length)
  ) {
    return ''
  } else {
    return decodeURIComponent(results[1]).replace(/\W/gi, ' ')
  }
}

const parseKeywords = (key) => {
  if (key.includes('_')) {
    const utm = key.split('_')[0].toUpperCase()
    const newKey = key.split('_')[1]
    const CapNewKey = newKey?.[0]?.toUpperCase() + newKey?.slice(1)
    return utm + ` ${CapNewKey}`
  } else {
    return parsedKeyWords[key]
  }
}
const UTMTracking = (utm = '') => {
  const campaignKeywords =
      'utm_source utm_medium utm_campaign utm_content utm_term cid agid placement'.split(' ')
  let kw = ''
  const params = {}
  const firstParams = {}
  let index
  for (index = 0; index < campaignKeywords.length; ++index) {
    kw = getQueryParam(utm ? window.location.href + utm : document.URL, campaignKeywords[index])
    if (kw.length) {
      // if the kw value for utm_campaign has a space, replace it with a dash. If there is no space, keep the same format
      if (campaignKeywords[index] === 'utm_campaign' && kw.includes(' ')) {
        const regex = /\s/gm
        kw = kw.replace(regex, '-')
      }
      params[parseKeywords(campaignKeywords[index]) + ' (last touch)'] = kw
    }
  }
  for (index = 0; index < campaignKeywords.length; ++index) {
    kw = getQueryParam(utm ? window.location.href + utm : document.URL, campaignKeywords[index])
    if (kw.length) {
      firstParams[parseKeywords(campaignKeywords[index])] = kw
    }
  }
  if (mixpanel && mixpanel.people) {
    mixpanel.people.set(params)
    mixpanel.people.set_once(firstParams)
    const googleAdParams = { 'Ad Group ID': firstParams['Ad Group ID'], 'Campaign ID': firstParams['Campaign ID'], Placement: firstParams.Placement }
    mixpanel.register({ ...params, ...googleAdParams })
  }
}

export { UTMTracking }
