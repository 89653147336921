import { images } from 'assets'

const SignUpOAuthInfo = ({ err, oAuthType }) => {
  return (
    <div className='column sixteen'>
      <div className='flex-col mt-0 mb-5 lg:mt-12'>
        <div className='flex-col items-center text-center large_1'>
          <img className='!mb-10' alt='capture button' src={images[err === 500 ? 'something-went-wrong' : 'checkmark']} />
          {err === 500 ? 'Something Went Wrong' : `Thanks, your ${oAuthType} Login is linked`}
        </div>
      </div>
    </div>
  )
}

export default SignUpOAuthInfo
