import {  useLDFlags } from 'utils'

const LinqtoCapitalPlacementCopy = ({marginTop = 10, textSize= 'sm'}) => {
  const { suitabilityQuestions } = useLDFlags(['suitabilityQuestions'])

  if (!suitabilityQuestions) return null
  return (
    <p className={`min-w-full mt-${marginTop} mb-10 text-${textSize} text-center font-CodecColdRegular text-[#757575]`} role='contentinfo'>
      Linqto Capital acts as placement agent offering these securities. See <a className='underline body_2' href='https://files.brokercheck.finra.org/crs_314557.pdf' target='_blank' rel='noopener noreferrer'>Form CRS</a> for important disclosures.
    </p>
  )
}

export default LinqtoCapitalPlacementCopy
