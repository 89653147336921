import { images } from 'assets'
import { Button } from 'components'
import { Mixpanel } from 'utils'

const EntityDropDown = ({ groupBy, setGroupBy }) => {
  const handleChange = (type) => {
    setGroupBy(type)
    Mixpanel.track('Click Change Portfolio View', { Type: type === 'account' ? 'Show by account' : 'Show by investment' })
  }

  return (
    <div className='dropdown-container'>
      <div className='dropdown-wapper item dropdown dropdown-6'>
        <Button className='dropdown-icon' ariaLabel='Open Show By dropdown'>
          <img alt='Show By Dropdown' src={images['show-by-account']} />
        </Button>
        <div className='dropdown_menu dropdown_menu--animated dropdown_menu-6' aria-haspopup='listbox'>
          <a className='item' onClick={() => handleChange('account')} role='button' tabIndex={0} aria-label='Show by account' aria-selected={groupBy === 'account'}>
            {groupBy === 'account' && (
              <img alt='Show by account' src={images['show-by_selected']} />
            )}
            Show by account
          </a>
          <a className='item' onClick={() => handleChange('investment')} role='button' tabIndex={1} aria-label='Show by investment' aria-selected={groupBy === 'investment'}>
            {groupBy === 'investment' && (
              <img alt='Show by investment' src={images['show-by_selected']} />
            )}
            Show by investment
          </a>
        </div>
      </div>
    </div>
  )
}

export default EntityDropDown
