import { useEffect } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { useSelector, useDispatch } from 'react-redux'
import {
  getPlaidDocumentIncomeToken,
  getDocumentIncomeLinkingComplete,
  getInvestorStatus
} from 'slices/investorStatusSlice'
import { images } from 'assets'
import { Mixpanel } from 'utils'
import { useLDFlags } from 'utils/LaunchDarkly'

const PlaidDocumentIncomeLink = ({title, copy, icon, tags, testId, setShowPlaidDocumentSuccessModal}) => {
  const dispatch = useDispatch()
  const { plaidDocumentIncomeToken } = useSelector((state) => state.investorStatusSlice)
  const { accreditationIncomeFlow } = useLDFlags(['accreditationIncomeFlow'])
  
  useEffect(() => {
    if (accreditationIncomeFlow && !window.ReactNativeWebView) {
      dispatch(getPlaidDocumentIncomeToken())
    }
  }, [])

  const onLinkSuccess = async () => {
    Mixpanel.track('Onboarding Click Submit Verification', {
      'Accreditation Method': 'Auto/Manual',
      'Accredited Country': 'United States',
      'Accreditation Status': '$200k Individual Income for 2 Years'
    })
    setShowPlaidDocumentSuccessModal(true)
    if (!window.ReactNativeWebView) {
      await dispatch(getDocumentIncomeLinkingComplete())
    }
    dispatch(getInvestorStatus('noRefresh'))
  }

  const onExit = async (error) => {
    console.log('Plaid error', error)
  }
  const onEvent = async (eventName, metadata) => {
    if (eventName === 'ERROR') {
      Mixpanel.track('Accreditation Plaid Document Income Event Error', { Error: metadata.error_code })
    } else {
      Mixpanel.track(`Accreditation Plaid Document Income Event: ${eventName}`, { 'Link Step': metadata.view_name })
    }
  }
  const config = {
    onSuccess: onLinkSuccess,
    onExit,
    onEvent,
    token: plaidDocumentIncomeToken
  }

  const { open } = usePlaidLink(config)

  const connectPlaid = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('triggerPlaidIncomeDocument')
    } else {
      open()
    }
  }

  return (
    <div className='acc-nav-item' onClick={connectPlaid} data-testid={testId} role='button' aria-label={title} tabIndex={0}>
      <img src={images[icon]} alt={title} />
      <div className='acc-nav-copy'>
        <div className='medium_2 gray5'>{title}</div>
        <div className='medium_1 gray3'>{copy}</div>
        {tags?.length > 0 && <div className='acc-nav-tags'>
          {tags?.map((tag, i) => {
            return (
              <div key={i} className={`acc-nav-tag ${tag?.type}`}>
                {tag?.copy}
              </div>
            )
          })}
        </div>}
      </div>
      <img src={images.arrow_forward_ios} alt={`Go to ${title}`} className='acc-nav-arrow' />
    </div>
  )
}

export default PlaidDocumentIncomeLink
