import { Mixpanel, getDomain, Fullstory } from 'utils'
import { NavLink } from 'react-router-dom'
import { images } from 'assets'
import { Button } from 'components'

// TODO: maybe can consolidate with NavItem component
const DropDownItem = ({ setShowDropdown, showDropdown, item, filterNavByStatus, isActive }) => {
  const clickDropdownItem = (i) => {
    if (i?.secondaryItem === 'Get $1,000') {
      Mixpanel.track('Click Get $1,000 in Account')
    }
    Mixpanel.track('Click Main Nav', { 'Main Nav Item': item?.name })
    Fullstory.track('Click Main Nav', {
      main_nav_item: item?.name,
      current_url: item?.route
    })
    setShowDropdown(false)
  }
  return (
    <Button
      key={item.tag}
      className={`item dropdown dropdown-6 animate-up ${isActive ? 'active' : ''}`}
      onMouseOver={() => setShowDropdown(true)}
      onMouseLeave={() => setShowDropdown(false)}
      aria-label={`Open ${item.tag} menu`}
      aria-controls={`${item.tag}-menu`}
      aria-expanded={showDropdown}
    >
      <span className='dropdown-title'>{item.name}</span>
      <img src={images['chevron-down']} height={20} width={20} alt=''/>
      {showDropdown && (
        <div className='dropdown_menu dropdown_menu--animated dropdown_menu-6' id={`${item.tag}-menu`}>
          {filterNavByStatus(item.submenu).map((i) => {
            if (i.action) {
              return (
                <a
                  key={`nav-${i.tag}`}
                  className='item dropdown'
                  onClick={i.action}
                  role='button'
                  aria-label={`Navigate to ${i.name}`}
                >
                  <span className={`${i.tag === 'signout' ? 'text-connectedCobalt medium_2' : 'medium_1'}`}>{i.name}</span>
                </a>
              )
            }
            // only refresh the whole page is domain is on app and navigate to wp
            if (i.cms && window?.location?.hostname?.includes('app')) {
              return (<a
                key={`nav-${i.tag}`}
                href={getDomain(i.route, i?.cms)}
                onClick={() => clickDropdownItem(i)}
                className='item dropdown'
              >
                <span className='medium_1'>{i.name}</span>
              </a>
              )
            }
            return (
              <NavLink
                key={`nav-${i.tag}`}
                to={i.route}
                onClick={() => clickDropdownItem(i)}
                className='item dropdown'
              >
                <span className='medium_1'>{i.name}</span>
                {i?.secondaryItem && <div className='nav-secondary-item'>{i?.secondaryItem}</div>}
              </NavLink>
            )
          })}
        </div>
      )}
    </Button>
  )
}

export default DropDownItem
