import React from 'react'
import { useFormContext } from 'react-hook-form'
import TooltipLabel from '../TooltipLabel'
import ErrorMessage from './ErrorMessage'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const DatePickerInput = ({
  name,
  label,
  placeholder,
  content = '',
  disabled = false,
  ariaLabel = '',
  onChange = () => {},
  className = '',
  value,
  dateFormat,
  minDate = null
}) => {
  const {
    formState: { errors }
  } = useFormContext()
  return (
    <div className={`input-group ${className} ${errors[name] ? 'error' : ''}`}>
      <TooltipLabel content={content} label={label} htmlFor={name} />
      <DatePicker
        selected={value}
        className='date-picker'
        onChange={onChange}
        disabled={disabled}
        ariaLabelledBy={ariaLabel}
        placeholderText={placeholder}
        dateFormat={dateFormat}
        name={name}
        minDate={minDate}
        id={name}
      />
      {errors[name] && <ErrorMessage message={errors[name].message} />}
    </div>
  )
}

export default DatePickerInput
