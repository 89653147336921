import { ButtonSelect, Checkbox, Tooltip } from 'components'
import TextInput from 'components/Global/Inputs/TextInput'
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { annualIncomeOptions, InvestmentExperienceOptions, InvestmentHorizonOptions, netWorthOptions, RiskToleranceOptions } from 'components/data'
import USRegulationsContainerCopy from './USRegulationsContainerCopy'

const SuitabilityQuestions = ({ activeStep, setActiveStep }) => {
  const { watch, setValue, reset } = useFormContext()
  const { featureFlags: { CrsCheckEnabled } } = useSelector(state => state.userSlice)
  const { userProfile, initialValues } = useSelector(state => state.profile)
  
  const annualIncome = watch('annualIncome')
  const networthExcludingResidence = watch('networthExcludingResidence')
  const investmentHorizon = watch('investmentHorizon')
  const riskTolerance = watch('riskTolerance')
  const investmentExperience = watch('investmentExperience')

  // Set inital step and question values from backend if they exist
  useEffect(() => {
    setActiveStep(1)
    reset(initialValues)
  }, [setActiveStep, setValue, initialValues])

  const renderEmploymentFields = () => {
    return (
      <div className='flex flex-col'>
        <span className='mt-4 md:mt-0 medium_4'>Employment Information</span>
        <div className='grid-old'>
          <div className='column eight sixteen-mobile'>
            <TextInput
              name='occupation'
              label='Occupation'
              ariaLabel='Occupation'
              disabled={false}
            />
          </div>
          <div className='column eight sixteen-mobile'>
            <TextInput
              name='employerName'
              label='Employer Name'
              ariaLabel='Employer Name'
              disabled={false}
            />
          </div>
        </div>
        <USRegulationsContainerCopy />
      </div>
    )
  }

  const AnnualIncome = () => {
    const [selected, setSelected] = useState(-1)
    const options = Object.keys(annualIncomeOptions).filter(o => o !== 'UNKNOWN')
    const [showHover, setShowHover] = useState(false)

    useEffect(() => {
      setSelected(options.indexOf(annualIncome))
    }, [annualIncome])

    const selectIncome = (index) => setValue('annualIncome', options[index])
    return (
      <div className='flex flex-col'>
        <span className='medium_1 gray4'>
          <span className='mt-4 md:mt-0 medium_4 text-gray5'>What is your gross annual income?</span>
          <Tooltip
            header='Gross Annual Income'
            content='Gross annual income is the total amount of money an individual earns in a year before taxes and other deductions. It includes all sources of income, such as: salary, bonuses, and overtime pay. Joint income can also be included.'
            showHover={showHover}
            setShowHover={setShowHover}
          />
        </span>
        <div className='grid-old'>
          {options.map((o, i) => (
            <div key={annualIncomeOptions[o]} className='mb-4 column three sixteen-mobile'>
              <ButtonSelect selected={selected === i} selectItem={() => selectIncome(i)}>
                <span className='body_1 text-gray5'>{annualIncomeOptions[o]}</span>
              </ButtonSelect>
            </div>
          ))}
        </div>
      </div>
    )
  }

  const NetWorth = () => {
    const [selected, setSelected] = useState(-1)
    const options = Object.keys(netWorthOptions).filter(o => o !== 'UNKNOWN')
    const [showHover, setShowHover] = useState(false)

    useEffect(() => {
      setSelected(options.indexOf(networthExcludingResidence))
    }, [networthExcludingResidence])

    const selectNetworth = (index) => setValue('networthExcludingResidence', options[index])
    return (
      <div className='flex flex-col'>
        <span className='medium_1 gray4'>
          <span className='mt-4 md:mt-0 medium_4 text-gray5'>What is your net worth?{' '}
            <span className='mt-4 md:mt-0 medium_4 text-gray3'>(Excluding primary residence)</span>
          </span>
          <Tooltip
            header='Net Worth'
            content='Net worth is the total value of your assets minus the total value of your liabilities. An asset could be real estate or retirement accounts. A liability could be a car or home loan.'
            showHover={showHover}
            setShowHover={setShowHover}
          />
        </span>
        <div className='grid-old'>
          {options.map((o, i) => (
            <div key={netWorthOptions[o]} className='mb-4 column three sixteen-mobile'>
              <ButtonSelect selected={selected === i} selectItem={() => selectNetworth(i)}>
                <span className='medium_1 text-gray5'>{netWorthOptions[o]}</span>
              </ButtonSelect>
            </div>
          ))}
        </div>
      </div>
    )
  }

  const InvestmentHorizon = () => {
    const [selected, setSelected] = useState(-1)
    const options = Object.keys(InvestmentHorizonOptions).filter(o => o !== 'UNKNOWN')
    const [showHover, setShowHover] = useState(false)

    useEffect(() => {
      setSelected(options.indexOf(investmentHorizon))
    }, [investmentHorizon])

    const selectHorizon = (index) => setValue('investmentHorizon', options[index])
    return (
      <div className='flex flex-col'>
        <span className='medium_1 gray4'>
          <span className='mt-4 md:mt-0 medium_4 text-gray5'>What is your investment horizon for your investing activities through Linqto?</span>
          <Tooltip
            header='Investment Horizon'
            content='An investment horizon is the length of time an investor plans to hold an investment or achieve their financial goals.'
            showHover={showHover}
            setShowHover={setShowHover}
          />
        </span>
        <div className='grid-old'>
          {options.map((o, i) => (
            <div key={InvestmentHorizonOptions[o].type} className='mb-4 column four sixteen-mobile'>
              <ButtonSelect selected={selected === i} selectItem={() => selectHorizon(i)}>
                <span className='medium_1 text-gray5'>{InvestmentHorizonOptions[o].type}</span>
              </ButtonSelect>
            </div>
          ))}
        </div>
      </div>
    )
  }

  const DescriptionBox = ({ children }) => (
    <div className='flex mx-[-5px] p-4 mb-4 border rounded-lg min-h-10 bg-gray1 border-gray2'>
      <span className='body_1 text-gray5 text-start'>
        {children}
      </span>
    </div>
  )

  const RiskTolerance = () => {
    const [selected, setSelected] = useState(-1)
    const [showTooltip0, setShowTooltip0] = useState(false)
    const [showTooltip1, setShowTooltip1] = useState(false)
    const [showTooltip2, setShowTooltip2] = useState(false)

    const options = Object.keys(RiskToleranceOptions).filter(o => o !== 'UNKNOWN')
    useEffect(() => {
      setSelected(options.indexOf(riskTolerance))
    }, [riskTolerance])

    const selectRiskTolerance = (index) => setValue('riskTolerance', options[index])
    return (
      <div className='flex flex-col'>
        <span className='mt-4 md:mt-0 medium_4 text-gray5'>How would you best describe your risk tolerance for your investing activities through Linqto?</span>
        <div className='grid-old'>
          {options.map((o, i) => (
            <div key={RiskToleranceOptions[o].type} className='mb-4 column three sixteen-mobile'>
              <ButtonSelect selected={selected === i} selectItem={() => selectRiskTolerance(i)}>
                <>
                  <span className='medium_1 text-gray5'>{RiskToleranceOptions[o].type}</span>
                  <Tooltip
                    content={RiskToleranceOptions[o].tooltip}
                    showHover={i === 0 ? showTooltip0 : i === 1 ? showTooltip1 : showTooltip2}
                    setShowHover={() => {
                      if (i === 0) setShowTooltip0(!showTooltip0)
                      if (i === 1) setShowTooltip1(!showTooltip1)
                      if (i === 2) setShowTooltip2(!showTooltip2)
                    }}
                  />
                </>
              </ButtonSelect>
            </div>
          ))}
        </div>
        {riskTolerance !== 'UNKNOWN' && <DescriptionBox>
          <span className='body_2 text-gray4'>{RiskToleranceOptions[riskTolerance].type}</span>: {RiskToleranceOptions[riskTolerance].tooltip}
        </DescriptionBox>}
      </div>
    )
  }

  const InvestmentExperience = () => {
    const [selected, setSelected] = useState(-1)
    const [showTooltip0, setShowTooltip0] = useState(false)
    const [showTooltip1, setShowTooltip1] = useState(false)
    const [showTooltip2, setShowTooltip2] = useState(false)
    const options = Object.keys(InvestmentExperienceOptions).filter(o => o !== 'UNKNOWN')

    useEffect(() => {
      setSelected(options.indexOf(investmentExperience))
    }, [investmentExperience])

    const selectInvestmentExperience = (index) => setValue('investmentExperience', options[index])
    return (
      <div className='flex flex-col'>
        <span className='mt-4 md:mt-0 medium_4 text-gray5'>How much experience do you have with private securities or other alternative investments?</span>
        <div className='grid-old'>
          {options.map((o, i) => (
            <div key={InvestmentExperienceOptions[o].type} className='mb-4 column three sixteen-mobile'>
              <ButtonSelect selected={selected === i} selectItem={() => selectInvestmentExperience(i)}>
                <>
                  <span className='medium_1 text-gray5'>{InvestmentExperienceOptions[o].type}</span>
                  <Tooltip
                    content={InvestmentExperienceOptions[o].tooltip}
                    showHover={i === 0 ? showTooltip0 : i === 1 ? showTooltip1 : showTooltip2}
                    setShowHover={() => {
                      if (i === 0) setShowTooltip0(!showTooltip0)
                      if (i === 1) setShowTooltip1(!showTooltip1)
                      if (i === 2) setShowTooltip2(!showTooltip2)
                    }}
                  />
                </>
              </ButtonSelect>
            </div>
          ))}
        </div>
        {investmentExperience !== 'UNKNOWN' && <DescriptionBox>
          <span className='body_2 text-gray4'>{InvestmentExperienceOptions[investmentExperience].type}</span>: {InvestmentExperienceOptions[investmentExperience].tooltip}
        </DescriptionBox>}
      </div>
    )
  }

  const Acknowledgement = () => {
    return (
      <div className='flex flex-col'>
        <div className='mt-4 md:mt-0 medium_4'>Acknowledgment</div>
        <Checkbox name='acknowlegeCheckbox' ariaLabel='acknowlegeCheckbox'>
          By checking this box, I hereby certify that I understand that investing in securities in private companies is speculative and involves a high degree of risk. I am prepared to withstand a total loss of my investment. I will complete my own independent due diligence before investing in securities or financial instruments, including obtaining additional information, opinions, financial projections, and legal or other investment advice. I have sufficient knowledge and experience to be investing in private equities, and I'm not subject to backup tax withholding. Furthermore, I certify that the information I provided is true and accurate to the best of my knowledge and belief.
        </Checkbox>
      </div>
    )
  }

  return (
    <>
      {activeStep === 1 && renderEmploymentFields()}
      {activeStep === 2 && <AnnualIncome />}
      {activeStep === 3 && <NetWorth />}
      {activeStep === 4 && <InvestmentHorizon />}
      {activeStep === 5 && <RiskTolerance />}
      {activeStep === 6 && <InvestmentExperience />}
      {activeStep === 7 && (!userProfile?.acknowledgedRiskAt || (!userProfile?.acknowledgedCrsAt && CrsCheckEnabled)) && <Acknowledgement />}
    </>
  )
}
export default SuitabilityQuestions