import { images } from 'assets'
import { seoTitleTemplate, getDomain } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { Button } from 'components'

const NotFound = () => {
  return (
    <>
      <SeoMeta title={seoTitleTemplate('404 Not Found')} />
      <div className='page-container'>
        <div className='inner-container'>
          <div className='flex-col items-center'>
            <h1>We&apos;re sorry, this page does not exist.</h1>
            <div className='flex items-center w-[100vw]'>
              <div className='w-full border-b-2'></div>
              <img className='mx-2 my-3' alt='404 page not found' src={images['404']} height={45} width={40} />
              <div className='w-full border-b-2'></div>
            </div>
            <div className='w-full max-w-[900px] text-center items-center'>
              <p className='block my-2 text-gray-400'>Let&apos;s get you back to investing.</p>
              <div className='flex justify-center ml-auto mr-auto max-w-60'>
                <Button onClick={() => window.open(getDomain('/', true))} >Home</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFound
