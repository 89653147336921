import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'slices/commonSlice'
import api from '../apis/private.js'
import parseUpholdAccounts from 'actions/parsing/parseUpholdAccounts'
import parseCashAccount from 'actions/parsing/parseCashAccount'
import parseWithdraw from 'actions/parsing/parseWithdraw'
import { Mixpanel, Braze, Fullstory } from 'utils'
import axios from 'axios'

const initialState = {
  hasUpholdWallet: false,
  isLoggedIn: false,
  cards: [],
  isUpholdOtpRequired: false,
  pageLoading: false,
  loading: false,
  uphold: null,
  bank: {},
  isAccredited: false,
  isProfileComplete: false,
  linqtoBucks: 0,
  purchaseCreditsEarned: 0,
  accounts: [],
  upholdError: null,
  ownerName: '',
  availableBalance: 0,
  availableWithdrawBalance: 0,
  entryHistory: [],
  plaidLinkToken: '',
  hasUphold: false,
  canAddFunds: false,
  isRetirementAccount: false,
  fundingFromUpholdAllowed: false,
  plaidLinkingAllowed: false,
  upholdCards: [],
  amount: 0,
  cardCurrency: '',
  cardId: '',
  cardLabel: '',
  commission: 0,
  conversionRate: 0,
  fee: 0,
  fromBankName: '',
  fromAccountNumberSafe: '',
  msToLive: 0,
  otpRequired: false,
  total: 0,
  transactionId: 0,
  cashExternalAccounts: [],
  pendingCashExternalAccounts: [],
  approvedCashExternalAccounts: [],
  cashWithdrawalEnabled: false,
  upholdAmlStatus: '',
  upholdUnderReview: false,
  upholdIsApproved: false,
  upholdAccessId: 0,
  fundingError: '',
  achLimit: 0
}

export const UPHOLD_API_ERRORS = {
  UPHOLD_MUST_PERFORM_LIVENESS_CHECK: 'user-must-perform-liveness-check',
  UPHOLD_MUST_SUBMIT_IDENTITY: 'user-must-submit-identity',
  UPHOLD_RESTRICTED_BY_AUTHENTICATION_RESET: 'uphold-restricted-by-authentication-reset',
  UPHOLD_USER_STATUS_NOT_VALID: 'uphold-user-status-not-valid',
  UPHOLD_UNKNOWN_ERROR: 'uphold-unknown-error',
  UPHOLD_INSUFFICIENT_UNLOCKED_FUNDS: 'UPHOLD_INSUFFICIENT_UNLOCKED_FUNDS',
  UPHOLD_NOT_FOUND: 'uphold-not-found'
}

/**
 * Call Plaid endpoint to get token to use for plaid integration
 */
export const getPlaidLinkToken = createAsyncThunk(
  'getPlaidLinkToken',
  (entityId, { dispatch }) => {
    return api
      .get(`/page/linqtoWallet/cashAccount/plaidLinkToken${entityId ? `?entityId=${entityId}` : ''}`)
      .then(res => {
        return res.data.linkToken
      })
      .catch(err => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
      })
  }
)

/**
 * Get list of plaid accounts AFTER user connects to bank account & retrieves public token
 * @param {string} token
 */
export const linkPlaidAccounts = createAsyncThunk(
  'linkPlaidAccounts',
  (_, { dispatch }) => {
    return api
      .get('/page/wallet/ach/getPlaidLinkToken')
      .then(res => {
        return res.data.linkToken
      })
      .catch(err => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
      })
  }
)

/**
 * Get list of plaid accounts AFTER user connects to bank account & retrieves public token
 * @param {string} token
 */
export const getPlaidAccounts = createAsyncThunk(
  'getPlaidAccounts',
  ({ publicToken, plaidAccountId, entityId }) => {
    return api
      .post('/page/linqtoWallet/cashAccount/plaidExchangeToken', { publicToken, plaidAccountId, entityId })
      .then(res => {
        return res.data
      })
      .catch(err => {
        Mixpanel.track('Funding Error', { 'Error Type': err?.response?.data?.error })
        Braze.track('Funding Error', { 'Error Type': err?.response?.data?.error })
        return 500
      })
  }
)

export const disconnectExternalAccount = createAsyncThunk(
  'disconnectExternalAccount',
  (body, { rejectWithValue }) => {
    return api.post('/page/linqtoWallet/cashAccount/disconnectExternalAccount', body).catch((err) => {
      if (err.response) {
        return rejectWithValue(err.response)
      }
    })
  }
)

export const connectUphold = createAsyncThunk(
  'connectUphold',
  (_, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .get('/getUpholdConnectUrl')
      .then((res) => {
        return fulfillWithValue(res.data.url)
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const getUpholdAccounts = createAsyncThunk('getUpholdAccounts', (entityId, { dispatch, rejectWithValue }) => {
  return api
    .get(`/page/paymentMethod${entityId !== 'individual' ? `?entityId=${entityId}` : ''}`)
    .then(res => parseUpholdAccounts(res.data))
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return rejectWithValue(err.response.data.error)
    })
})

export const disconnectUphold = createAsyncThunk(
  'disconnectUphold',
  (_, { dispatch, rejectWithValue }) => {
    return api.post('/disconnectUphold').catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return rejectWithValue(err.response)
    })
  }
)

export const addFundsToLinqtoWallet = createAsyncThunk(
  'addFundsToLinqtoWallet',
  (entityId, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api.get(`/page/linqtoWallet/wireInstructions/${entityId ? `?entityId=${entityId}` : ''}`).then((res) => {
      return fulfillWithValue(res.data)
    }).catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return rejectWithValue(err.response)
    })
  }
)

export const getLinqtoWallet = createAsyncThunk(
  'getLinqtoWallet',
  (_, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .get('/page/linqtoWallet')
      .then((res) => {
        return fulfillWithValue(res.data)
      })
      .catch((err) => {
        if (err.response) {
          if (
            err.response.data?.error === 'UPHOLD_EMAIL_VERIFICATION_REQUIRED'
          ) {
            return rejectWithValue(err.response.data.error)
          }
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const getAddFundsPage = createAsyncThunk(
  'getAddFundsPage',
  (entityId, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .get(`/page/linqtoWallet/addFunds${entityId ? `?entityId=${entityId}` : ''}`)
      .then((res) => {
        return fulfillWithValue(parseCashAccount(res?.data))
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data?.error === 'UPHOLD_EMAIL_VERIFICATION_REQUIRED') {
            return rejectWithValue(err.response.data.error)
          } else if (err?.response?.data?.error && err?.response?.data?.error in UPHOLD_API_ERRORS) {
            Mixpanel.track('Funding Error', { 'Error Type': UPHOLD_API_ERRORS[err.response.data.error] })
            return rejectWithValue(err.response.data.error)
          } else {
            dispatch(handleServerError(err.response.data.error))
            return { upholdAPIError: 'UPHOLD_NOT_FOUND' }
          }
        }
        dispatch(handleServerError(err.response.data.error))
        return rejectWithValue(err.response)
      })
  }
)

export const getAddFundsSummaryPage = createAsyncThunk(
  'getAddFundsSummaryPage',
  ({ amount, cardCurrency, cardId, cardLabel }, { fulfillWithValue, rejectWithValue }) => {
    return api
      .get(`/page/linqtoWallet/addFunds/summary?amount=${amount}&cardCurrency=${cardCurrency}&cardId=${cardId}&cardLabel=${cardLabel}`)
      .then((res) => {
        return fulfillWithValue(res.data)
      })
      .catch((err) => {
        return rejectWithValue(err?.response?.data?.error)
      })
  }
)

export const getAddFundsACHSummaryPage = createAsyncThunk(
  'getAddFundsACHSummaryPage',
  ({ amount, cashExternalAccountId }, { fulfillWithValue, rejectWithValue }) => {
    return api
      .get(`/page/linqtoWallet/addFunds/ach?amount=${amount}&cashExternalAccountId=${cashExternalAccountId}`)
      .then((res) => {
        return fulfillWithValue(res.data)
      })
      .catch((err) => {
        return rejectWithValue(err?.response?.data?.error)
      })
  }
)

export const commitAddFunds = createAsyncThunk(
  'commitAddFunds',
  ({ commitRequest, type }, { fulfillWithValue, rejectWithValue }) => {
    const url = type === 'Uphold' ? '/page/linqtoWallet/addFunds/summary' : type === 'Bank Account' ? '/page/linqtoWallet/addFunds/ach' : ''
    return api
      .post(url, { ...commitRequest })
      .then(res => {
        return fulfillWithValue(res.data)
      })
      .catch(err => {
        return rejectWithValue(err?.response?.data?.error)
      })
  }
)

export const getCashAccount = createAsyncThunk(
  'getCashAccount',
  ({ entityId = '', refreshStatus = true }, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .get(`/page/linqtoWallet/cashAccount/${entityId ? `?entityId=${entityId}` : ''}`)
      .then((res) => {
        const missingFields = res?.data?.missingProfileFields
        const isFinraNotConfirmedUser = missingFields?.includes('confirmedStatus')
        // fields string are case sensitive
        const isFinraUser = missingFields?.includes('Phone', 'BirthDay', 'BirthMonth', 'BirthYear', 'TaxId') && missingFields.length === 5
        Fullstory.setUserProperty({ cash_balance: res?.data?.availableBalance })
        return fulfillWithValue({ ...parseCashAccount(res.data), isFinraNotConfirmedUser, isFinraUser }, { refreshStatus })
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
        }
        return rejectWithValue(err.response)
      })
  }
)

export const getWithdrawPage = createAsyncThunk(
  'getWithdrawPage',
  (entityId, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .get(`/page/linqtoWallet/withdrawal${entityId ? `?entityId=${entityId}` : ''}`)
      .then((res) => {
        return fulfillWithValue(parseWithdraw(res.data))
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err.response.data.error))
          return rejectWithValue(err.response)
        }
      })
  }
)

/**
 * Accepts an object { transferAmount, cardId, upholdAccessId }
 * and calls /upholdWithdrawRequest API to initiate uphold withdraw
 * @function commitUpholdWithdrawRequest
 * @param {Object} obj
 * @returns {Object} - no need to worry about response as after successful
 * API call, we push user back to cash account page
 */
export const commitUpholdWithdrawRequest = createAsyncThunk(
  'commitUpholdWithdrawRequest',
  async (formValues, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await api.post('/page/linqtoWallet/upholdWithdrawRequest', formValues)
      return fulfillWithValue(response.data)
    } catch (error) {
      dispatch(handleServerError(error?.response?.data?.error))
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const commitWithdrawRequest = createAsyncThunk(
  'commitWithdrawRequest',
  async (formValues, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await api.post('/page/linqtoWallet/sendWithDrawRequest', formValues)
      return fulfillWithValue(response?.data)
    } catch (error) {
      dispatch(handleServerError(error?.response?.data?.error))
      return rejectWithValue(error?.response?.data)
    }
  }
)

export const createManualCashExternalAccount = createAsyncThunk(
  'createManualCashExternalAccount',
  (formValues, { rejectWithValue }) => {
    const file = {
      uri: formValues.document[0]?.path,
      name: formValues.document[0]?.name,
      type: formValues.document[0]?.type
    }
    const form = new FormData()
    for (const item in formValues) {
      if (item === 'document' && file) {
        form.append('document', formValues.document[0])
      } else {
        form.append(item, formValues[item])
      }
    }

    const config = {
      method: 'post',
      url: `${import.meta.env.VITE_APP_API_URL}/page/cashExternalAccount/wire`,
      data: form,
      headers: { 'content-type': 'multipart/form-data', hostname: window.location.hostname }
    }
    if (window.location.hostname === 'localhost') {
      config.headers = { access_token: localStorage?.getItem('linqto_token') }
    }
    return axios(config)
      .then((res) => res)
      .catch((err) => {
        return rejectWithValue(err.response)
      })
  }
)

export const walletSlice = createSlice({
  name: 'walletSlice',
  initialState,
  reducers: {
    resetFundingError: (state) => {
      state.fundingError = initialState.fundingError
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(connectUphold.pending, (state) => {
        state.loading = true
      })
      .addCase(connectUphold.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          ...payload
        }
      })
      .addCase(connectUphold.rejected, (state) => {
        state.loading = false
      })
      .addCase(disconnectUphold.pending, (state) => {
        state.loading = true
      })
      .addCase(disconnectUphold.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(disconnectUphold.rejected, (state) => {
        state.loading = false
      })
      .addCase(getUpholdAccounts.pending, (state) => {
        state.loading = true
      })
      .addCase(getUpholdAccounts.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          uphold: payload
        }
      })
      .addCase(getUpholdAccounts.rejected, (state) => {
        state.loading = false
      })
      .addCase(addFundsToLinqtoWallet.pending, (state) => {
        state.loading = true
      })
      .addCase(addFundsToLinqtoWallet.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          ...payload
        }
      })
      .addCase(addFundsToLinqtoWallet.rejected, (state) => {
        state.loading = false
      })
      .addCase(getLinqtoWallet.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getLinqtoWallet.fulfilled, (state, { payload }) => {
        const { accounts } = payload || {}
        let hasUphold = false
        if (accounts && accounts.length) {
          accounts.forEach((account) => {
            if (account?.hasUphold) {
              hasUphold = true
            }
          })
        }
        return {
          ...state,
          pageLoading: false,
          hasUphold,
          ...payload
        }
      })
      .addCase(getLinqtoWallet.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(getAddFundsPage.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getAddFundsPage.fulfilled, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getAddFundsPage.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(getAddFundsSummaryPage.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getAddFundsSummaryPage.fulfilled, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getAddFundsSummaryPage.rejected, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getAddFundsACHSummaryPage.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getAddFundsACHSummaryPage.fulfilled, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getAddFundsACHSummaryPage.rejected, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getCashAccount.pending, (state, { meta }) => {
        if (meta.arg === 'noRefresh') {
          state.pageLoading = false
        } else {
          state.pageLoading = true
        }
      })
      .addCase(getCashAccount.fulfilled, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getCashAccount.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(getPlaidLinkToken.fulfilled, (state, { payload }) => {
        state.plaidLinkToken = payload
      })
      .addCase(commitAddFunds.pending, (state) => {
        state.loading = true
      })
      .addCase(commitAddFunds.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          ...payload
        }
      })
      .addCase(commitAddFunds.rejected, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          fundingError: payload
        }
      })
      .addCase(getPlaidAccounts.pending, (state) => {
        state.loading = true
      })
      .addCase(getPlaidAccounts.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          ...payload
        }
      })
      .addCase(getPlaidAccounts.rejected, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          ...payload
        }
      })
      .addCase(getWithdrawPage.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getWithdrawPage.fulfilled, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getWithdrawPage.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(commitWithdrawRequest.pending, (state) => {
        state.loading = true
      })
      .addCase(commitWithdrawRequest.fulfilled, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          ...payload
        }
      })
      .addCase(commitWithdrawRequest.rejected, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          ...payload
        }
      })
      .addCase(createManualCashExternalAccount.pending, (state) => {
        state.loading = true
      })
      .addCase(createManualCashExternalAccount.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(createManualCashExternalAccount.rejected, (state) => {
        state.loading = false
      })
      .addCase(commitUpholdWithdrawRequest.pending, (state) => {
        state.loading = true
      })
      .addCase(commitUpholdWithdrawRequest.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(commitUpholdWithdrawRequest.rejected, (state) => {
        state.loading = false
      })
  }
})
export const { resetFundingError } = walletSlice.actions
export default walletSlice.reducer
