import Breadcrumb from 'components/Global/Breadcrumb'
import Button from 'components/Global/Button'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Mixpanel } from 'utils'
import { ConditionalRender } from 'components'

const PortfolioDetailHeader = ({ company }) => {
  const history = useHistory()
  const { company: { canSellShares, atsPoolEnabled, sharesAvailableToSell } } = useSelector(state => state.portfolioSlice)

  const items = [{ name: 'My Portfolio', route: '/portfolio' }]
  const canSell = company.status === 'OPEN' && canSellShares && atsPoolEnabled && sharesAvailableToSell > 0

  const handleBuyMore = () => {
    history.push(`/products/${company.urlName}`)
    Mixpanel.track('Click Buy More on Portfolio/Company Page')
  }

  const handelSell = () => {
    Mixpanel.track('Click Sell Button on Portfolio Company Page', { 'Company Name': company.name })
    if (atsPoolEnabled) {
      history.push(`/sell-order/create/${company.urlName}`)
    }
  }

  return (
    <>
      <Breadcrumb items={items} />
      <div className='order-list-container'>
        <h1 className='order-list-title'>
          <img
            className='company-image'
            alt={`Linqto ${company.name} series`}
            src={company.iconUrl}
          ></img>
          {company.name}
        </h1>
        <div className='order-list-cta'>
          <div className='flex gap-4 '>
            <Button
              mode='secondary'
              onClick={handleBuyMore}
            >
              Buy More
            </Button>
            <Button
              onClick={handelSell}
              disabled={!canSell}
            >
              Sell
            </Button>
          </div>
          <ConditionalRender isVisible={sharesAvailableToSell === 0}>
            <div className='gray4 medium_1'>Shares must be held 90 days before selling.</div>
          </ConditionalRender>
        </div>
      </div>
    </>
  )
}

export default PortfolioDetailHeader
