import { Mixpanel } from 'utils'

const OriginationOfferDocuments = ({ documents }) => {
  const handleViewDocument = (name) => Mixpanel.track('View Originations Offer Agreement Document', { 'Document Type': name })
  return (
    <div className='section-wide origination-detail-documents'>
      <div className='agreements'>
        {documents && documents.map((d) => {
          return (
            <a
              onClick={() => handleViewDocument(d.name)}
              className='agreement'
              key={d.name}
              href={d.url}
              target='_blank'
              rel='noopener noreferrer'
              role='button'
              tabIndex={0}
              aria-label={`View ${d.name}`}
            >
              <span className='medium_2 name'>{d.name}</span>
            </a>
          )
        })}
      </div>
    </div>
  )
}

export default OriginationOfferDocuments
