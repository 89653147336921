const label = {
  PENDING: {
    color: 'pending',
    value: 'Pending'
  },
  COMPLETED: {
    color: 'completed',
    value: 'Completed'
  },
  CANCELED: {
    color: 'cancelled',
    value: 'Cancelled'
  },
  CLOSED: {
    color: 'closed',
    value: 'Closed'
  }
}
const StatusTag = ({ status }) => {
  return (
    <div className={`medium_2 label ${label[status]?.color}`}>
      {label[status].value}
    </div>
  )
}

export default StatusTag
