import TextInput from 'components/Global/Inputs/TextInput'
import { formatDecimal } from 'utils'
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import TooltipLabel from 'components/Global/TooltipLabel'

const FBOWithdrawAmount = () => {
  const { loading, availableBalance, availableWithdrawBalance } = useSelector((state) => state.walletSlice)
  const { setValue } = useFormContext()
  const validatePriceValues = (inputVal) => {
    const updatedValue = inputVal.charAt(0) === '$' ? inputVal.substring(1) : inputVal
    setValue('amount', `$${Number(updatedValue) ? Number(updatedValue).toFixed(2) : '0.00'}`)
  }
  const tooltip = 'Funds from pending deposits are not available to withdraw. Funds are available to withdraw 5 business days after settlement. This helps us prevent fraud.'

  return (
    <>
      <div className='available-funds'>
        <div>Total Funds</div>
        <div>{formatDecimal(availableBalance)}</div>
      </div>
      <div className='available-funds'>
        <div> <TooltipLabel content={tooltip} label={'Available For Withdrawal'} /></div>
        <div className='medium_2'>{formatDecimal(availableWithdrawBalance)}</div>
      </div>
      <TextInput
        isDecimal
        name='amount'
        ariaLabel='Amount'
        label='Amount'
        disabled={loading}
        className='number-format'
        placeholder='$0.00'
        onBlur={(e) => {
          validatePriceValues(e.target.value)
        }}
        handleKeyPress={(e) => {
          if ((e.key !== '.' && isNaN((Number(e.key)))) || e.key === null || e.key === ' ' || e.key === '-') {
            e.preventDefault()
          }
        }}
      />
    </>
  )
}

export default FBOWithdrawAmount
