// import { Mixpanel } from 'utils'
import { Button } from "components"

const PurchaseCreditsTabs = ({ activeIndex, setActiveIndex }) => {
  const updateActiveIndexAndTrack = (num) => {
    setActiveIndex(num)
    // Mixpanel.track('Click Product Tab', { 'Tab Name': tab, 'Company Name': name })
  }

  return (
    <div className='invest-tabs-container'>
      <div className={'invest-tabs-container__page-tabs invest-tabs-container__spaced'}>
        <Button data-testid='currentPurchaseCredits' className={`tab ${activeIndex === 0 ? 'active' : ''}`} onClick={() => updateActiveIndexAndTrack(0)}>
          <span className='large_1'>Current</span>
        </Button>
        <div data-testid='historyPurchaseCredits' className={`tab ${activeIndex === 1 ? 'active' : ''}`} onClick={() => updateActiveIndexAndTrack(1)}>
          <span className='large_1'>History</span>
        </div>
      </div>
    </div>
  )
}

export default PurchaseCreditsTabs
