import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import Button from 'components/Global/Button'
import { useHistory } from 'react-router-dom'

const FBOAddBankCTA = ({
  onSubmit
}) => {
  const history = useHistory()
  const { errors, handleSubmit } = useFormContext()
  const { loading } = useSelector((state) => state.walletSlice)

  return (
    <>
      <div className='b_18_regular space-above-sm space-below-sm'>Please allow 3 business days for Linqto to review and approve the addition of this account before making a withdrawal.</div>
      <div className='md:flex md:gap-4'>
        <Button
          mode='tertiary'
          onClick={() => history.goBack()}
        >
          Back
        </Button>
        <Button
          onClick={() => handleSubmit(onSubmit)}
          disabled={errors?.length > 0}
          loading={loading}
          type='submit'
        >
          Add Account
        </Button>
      </div>
    </>
  )
}

export default FBOAddBankCTA
