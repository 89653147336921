import { images } from 'assets'
import { useSelector } from 'react-redux'
import { ConditionalRender, Button } from 'components'
import { useHistory } from 'react-router-dom'
import useWindowSize from 'hooks/useWindowSize'
import { MOBILE_WIDTH_SIZE, Mixpanel, toCamelCase } from 'utils'

const BrokerList = () => {
  const history = useHistory()
  const { width } = useWindowSize()
  const isMobile = width < MOBILE_WIDTH_SIZE

  const handleButtonClick = (url, action, entity) => {
    Mixpanel.track(`Click ${toCamelCase(action)} Brokerage`, { entity })
    history.push(url)
  }

  const { brokerInfos, hasCompletedOrder } = useSelector((state) => state.profile)
  return (
    <div className='profile-box'>
      <div className='medium_4'>Brokerage Account Information {!isMobile ? '(used for IPO)' : ''} </div>
      <div className='gray3 medium_1 space-above-xsm'>We’ll need this to distribute your shares in the event of an exit. Note, we can only distribute shares titled in the name of an entity to a brokerage account that is also titled in the name of the same entity.</div>
      <div className='profile-list'>
        <div className='profile-list-header gray3 medium_2'>
          My Brokerages
        </div>
        <ConditionalRender isVisible={hasCompletedOrder}>
          {brokerInfos?.length === 1 && brokerInfos?.[0]?.exists === false &&
          <div className='profile-list-empty-state'>
            <div className='medium_2 gray5'>Designate where you want to receive your shares in the event of an exit.</div>
            <Button onClick={() => history.push('/broker-information')} mode='secondary'>Add Brokerage</Button>
          </div>
          }
          {((brokerInfos?.length === 1 && brokerInfos?.[0]?.exists === true) || brokerInfos?.length > 1) && brokerInfos?.map((f, i) => {
            const url = f.entityId
              ? `/broker-information/${f.entityId}`
              : '/broker-information'
            const action = f.exists ? 'edit' : 'add'
            return (
              <Button key={`broker-info-${i}`} onClick={() => handleButtonClick(url, action, f.name)} className='min-w-full text-left profile-list-item' ariaLabel={`${action} Brokerage`}>
                <span className='medium_1 gray5'>{f.name} </span>
                <span className='medium_2 gray5'>{f.brokerage}</span>
                <span className='medium_2 connectedCobalt list-action'><img alt={action} src={images[`icon-list-${action}`]} />{action}</span>
              </Button>)
          })}
        </ConditionalRender>
        <ConditionalRender isVisible={!hasCompletedOrder}>
          <div className='profile-list-empty-state'>
            <div className='medium_2 gray3'>Make your first purchase to be able to add your brokerage details.</div>
            <Button disabled>Add Brokerage</Button>
          </div>
        </ConditionalRender>
      </div>
    </div>
  )
}

export default BrokerList
