import React from 'react'
import { useFormContext } from 'react-hook-form'
import TooltipLabel from '../TooltipLabel'
import ErrorMessage from './ErrorMessage'

const TextArea = ({
  name,
  label,
  placeholder,
  content = '',
  disabled = false,
  ariaLabel = '',
  onChange = () => {},
  onBlur = () => {},
  handleKeyPress = () => {},
  maxLength = null,
  type = 'text'
}) => {
  const {
    register,
    formState: { errors }
  } = useFormContext()
  return (
    <div className={`input-group ${errors[name] && 'error'}`}>
      <TooltipLabel content={content} label={label} htmlFor={name} />
      <textarea
        disabled={disabled}
        type={type}
        placeholder={placeholder}
        {...register(name, {
          onChange,
          onBlur
        })}
        aria-label={ariaLabel}
        onKeyPress={handleKeyPress}
        maxLength={maxLength}
        id={name}
      />
      {errors[name] && <ErrorMessage message={errors[name].message} />}
    </div>
  )
}

export default TextArea
