import { Modal, Content, Wrapper, Row } from 'components'
import Button from '../../Global/Button'
import ReactHtmlParser from 'react-html-parser'

const EUResidentsModal = ({ hideModal, data: { title, body }, choiceData: { checkbox1, checkbox2, checkbox3, experiencedInvestorChoice } }) => {
  return (
    <Modal crossToClose hideModal={hideModal} modalHeader={title} innerStyle='text-left'>
      <Wrapper>
        <Content>
          <div className='medium_1 message'>{ReactHtmlParser(body)}</div>
          <br/>
          <div className='medium_1 message'>{checkbox1}</div>
          <div className='medium_1 message'>{checkbox2}</div>
          <div className='medium_1 message'>{checkbox3}</div>
          <div className='medium_1 message'>{experiencedInvestorChoice}</div>
        </Content>
        <br/>
        <Row className='mt-2 centered'><Button onClick={hideModal}>Continue to Verify</Button></Row>
      </Wrapper>
    </Modal>
  )
}

export default EUResidentsModal
