import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormProvider, useForm } from 'react-hook-form'
import { Modal } from 'components'
import { schema } from 'schemas/resetPasswordSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { resetPasswordEmail } from 'slices/userSlice'
import { Button, EmailInput } from 'components'
import ResetModalConfirmation from './ResetModalConfirmation'
import { Mixpanel } from 'utils'

const ResetModal = ({ hideModal }) => {
  const [success, setSuccess] = useState(false)
  const dispatch = useDispatch()
  const { submitLoading } = useSelector((state) => state.userSlice)
  const methods = useForm({ mode: 'onTouched', resolver: yupResolver(schema) })
  const { handleSubmit, formState: { isDirty } } = methods

  const onSubmit = (formValues) => {
    dispatch(resetPasswordEmail(formValues)).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        Mixpanel.track('Submit Forgot Password Email')
        setSuccess(true)
      }
    })
  }

  return (
    <Modal
      modalHeader='Reset Password'
      innerStyle='flex-col items-center'
      hideModal={() => hideModal()}
      pressToClose
      crossToClose
    >
      {!success && (
        <FormProvider {...methods}>
          <div className='mb-4 medium_1'>Please enter the email address associated with your account. We&apos;ll send you an email with instructions on how to reset your password.</div>
          <div className='mb-4 medium_1'>If you used Google or Apple to join Linqto, please close this pop-up and use the Google/Apple Sign In buttons.</div>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className='grid mt-0 md:mt-4'>
              <div className='column sixteen sixteen-mobile'>
                <EmailInput
                  name='email'
                  label='Email'
                  ariaLabel='email'
                  disabled={submitLoading}
                />
              </div>
            </div>
            <div className='flex justify-center'>
              <Button
                type='submit'
                loading={submitLoading}
                disabled={submitLoading || !isDirty}
              >
                Submit
              </Button>
            </div>
          </form>
        </FormProvider>
      )}
      {success && <ResetModalConfirmation hideModal={hideModal} />}
    </Modal>
  )
}

export default ResetModal
