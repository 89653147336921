import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { seoTitleTemplate, Mixpanel } from 'utils'
import { PageLoading, ConditionalRender } from 'components'
import { getPurchaseCredits } from 'slices/purchaseCreditsSlice'
import SeoMeta from 'components/Global/SeoMeta'
import PurchaseCreditsTotal from './partials/PurchaseCreditsTotal'
import PurchaseCreditsTabs from './partials/PurchaseCreditsTabs'
import PurchaseCreditsCurrent from './partials/PurchaseCreditsCurrent'
import PurchaseCreditsHistory from './partials/PurchaseCreditsHistory'
import PurchaseCreditsEmpty from './partials/PurchaseCreditsEmpty'

const PurchaseCredits = () => {
  const dispatch = useDispatch()
  const [activeIndex, setActiveIndex] = useState(0)
  const { pageLoading, creditPurchaseEntries, currentCreditEntries } = useSelector((state) => state.purchaseCredits)

  useEffect(() => {
    dispatch(getPurchaseCredits()).then(({ meta, payload }) => {
      if (meta.requestStatus === 'fulfilled') {
        Mixpanel.track('View Purchase Credit Page', {
          'Purchase Credits': payload?.totalPurchaseCredits
        })
      }
    })
  }, [])

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Purchase Credits')} />
        <PageLoading />
      </>
    )
  }
  return (
    <>
      <SeoMeta title={seoTitleTemplate('Purchase Credits')} />
      <div className='page-container wallet-container invest-detail-container'>
        <div className='inner-container'>
          <div className='flex-col'>
            <h1>Purchase Credits</h1>
            <PurchaseCreditsTotal />
            <ConditionalRender isVisible={creditPurchaseEntries?.length > 0 && currentCreditEntries?.length > 0 }>
              <PurchaseCreditsTabs activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
              {activeIndex === 0 && <PurchaseCreditsCurrent />}
              {activeIndex === 1 && <PurchaseCreditsHistory />}
            </ConditionalRender>
            <ConditionalRender isVisible={creditPurchaseEntries?.length === 0 && currentCreditEntries?.length === 0 }>
              <PurchaseCreditsEmpty />
            </ConditionalRender>
          </div>
        </div>
      </div>
    </>)
}
export default PurchaseCredits
