import { verificationMethodOptions } from '../../data'
import { useSelector } from 'react-redux'
import { Mixpanel } from 'utils'
import { useLDFlags } from 'utils/LaunchDarkly'

const ChoosePreferredMethodDropdown = ({ verificationMethod, setVerificationMethod, customOptions, selfAccreditationState, disabled }) => {
  const { assetsAccounts } = useSelector((state) => state.investorStatusSlice)
  const { plaidAccreditation } = useLDFlags(['plaidAccreditation'])
  const options = { ...verificationMethodOptions, ...customOptions }

  const renderOptions = () => {
    switch (selfAccreditationState) {
    case 'NET_WORTH':
      if (!plaidAccreditation) {
        delete options.PLAID
      }
      return options
    case 'ANNUAL_INCOME':
      if (!plaidAccreditation) {
        delete options.PLAID
      }
      return options
    case 'JOINT_INCOME':
      delete options.PLAID
      return options
    default:
      return {}
    }
  }

  const onDropdownChange = (e) => {
    setVerificationMethod(e.target.value)
    Mixpanel.track('Choose Verification Method', { Method: e.target.value })
  }
  return (
    <div className='medium_1 info-container'>
      <label>Choose your preferred verification method</label>
      <select
        value={verificationMethod}
        onChange={(e) => onDropdownChange(e)}
        disabled={assetsAccounts?.length !== 0 || disabled}
        data-testId='verificationMethod-dropdown'
        className='verificationMethod-dropdown'
      >
        <option value='UNKNOWN' disabled>
          Please select an option
        </option>
        {Object.keys(renderOptions()).map((el, id) => (
          <option key={Object.keys(renderOptions())[id]} value={el}>
            {renderOptions()[el]}
          </option>
        ))}
      </select>
    </div>
  )
}

export default ChoosePreferredMethodDropdown
