import PropTypes from 'prop-types'

/**
 * Renders children based on isVisible prop
 * @function ConditionalRender
 * @returns passed children
 */
const ConditionalRender = ({ isVisible, children, ...props }) => {
  if (!isVisible) return null
  else return <div {...props}>{children}</div>
}

ConditionalRender.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  children: PropTypes.node
}

export default ConditionalRender
