import { Modal } from 'components'
import Button from '../../Global/Button'

const NotAccreditedSubmitSuccessModal = ({ hideModal }) => {
  return (
    <Modal crossToClose hideModal={hideModal} modalHeader='Your Profile Is Complete'>
      <div className='medium_1 content'>
        You’re one step away from being able to invest! Click below to add funds to your wallet.
      </div>
      <div className='mt-2 centered'>
        <Button onClick={hideModal}>Add Funds</Button>
      </div>
    </Modal>
  )
}

export default NotAccreditedSubmitSuccessModal
