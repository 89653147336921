import { images } from 'assets'
import Button from 'components/Global/Button'
import { useHistory } from 'react-router-dom'
import { Mixpanel } from 'utils'

const AccreditationVerifiedRevamp = () => {
  const history = useHistory()

  const redirectToCashAccount = () => {
    // change action if user is on web or app/webview
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('navigateToCashAccount')
    } else {
      Mixpanel.track('Onboarding Click Add Funds on Investor Status Page')
      history.push('/cash-account')
    }
  }
  return (
    <div className='accreditation-verified'>
      <img src={images.acc_verified} alt='Accreditation Verified' aria-label='accreditation-verified'/>
      <h1 className='mt-10 mb-6 large_1 centered'>Verified!</h1>
      <div className='w-full mb-10 medium_1'>Congratulations! You’ve verified your accreditation status.</div>
      <div className='centered'>
        <Button onClick={redirectToCashAccount} customClass='full-width'>Add Funds</Button>
      </div>
    </div>
  )
}

export default AccreditationVerifiedRevamp
