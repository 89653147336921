import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ConditionalRender, PageLoading, SelfAttestationUS, Button } from 'components'
import { images } from 'assets'
import { useQuery } from 'hooks/useQuery'
import FinancialAdvisorRevamp from './partialsRevamp/FinancialAdvisorRevamp'
import CRDRevamp from './partialsRevamp/CRDRevamp'
import ProgressBarRevamp from './partialsRevamp/ProgressBarRevamp'
import ContactSupport from 'components/Global/ContactSupport'
import { Mixpanel, useLDFlags, seoTitleTemplate } from 'utils'
import UnderReviewStatusView from './partialsRevamp/UnderReviewStatusView'
import EUResidentsSectionRevamp from './partialsRevamp/EUResidentsSectionRevamp'
import UKResidentsSectionRevamp from './partialsRevamp/UKResidentsSectionRevamp'
import NoRegCountriesSectionRevamp from './partialsRevamp/NoRegCountriesSectionRevamp'
import AccreditationVerifiedRevamp from './partialsRevamp/AccreditationVerifiedRevamp'
import ManualUploadDocumentRevamp from './partialsRevamp/ManualUploadDocumentRevamp'
import SeoMeta from 'components/Global/SeoMeta'
import PlaidBankAccountBanner from './partials/PlaidBankAccountBanner'
import USSuccessModal from './partials/USSuccessModal'
import FinanciallySophisticatedTestRevamp from './FinanciallySophisticated/FinanciallySophisticatedTestRevamp'
import { accreditationOptions, status } from '../data'
import AccreditationTypesModal from './partials/AccreditationTypesModal'
import PlaidDocumentIncomeLink from './partialsRevamp/PlaidDocumentIncomeLink'

import {
  getInvestorStatus,
  getPlaidIncomeAccreditaiton,
  saveSelfAccreditation,
  submitAssetsAccounts
} from 'slices/investorStatusSlice'
import { useHistory, useLocation } from 'react-router-dom'
import PlaidAssetsRevamp from './partialsRevamp/PlaidAssetsRevamp'
import EditProfileDetailsButton from './partialsRevamp/EditProfileDetailsButton'
import NewProgressBar from 'components/Profile/partials/NewProgressBar'

const InvestorStatusRevamp = () => {
  // Need to handel the situation where user has its own accrdiation values saved.
  const query = useQuery()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { accreditationIncomeFlow, plaidAccreditation, selfAttestationUsOnInvestorStatus, suitabilityQuestions } = useLDFlags(['accreditationIncomeFlow', 'plaidAccreditation', 'selfAttestationUsOnInvestorStatus', 'suitabilityQuestions'])
  const { featureFlags } = useSelector(state => state.userSlice)
  const {
    accreditationRegion,
    accreditedCountrySource,
    accreditedCountry,
    accreditedStatus,
    documentAccreditationIsProcessing,
    isProcessing,
    wasAccredited,
    assetsAccounts,
    hasTakenAccreditationQuiz,
    isRetailPurchaser
  } = useSelector((state) => state.investorStatusSlice)

  const [accreditationType, setAccreditationType] = useState(query.get('acc-type') || null)
  const [accreditationSubType, setAccreditationSubType] = useState(query.get('acc-sub-type') || null)
  const [submitPlaidBankAccounts, setSubmitPlaidBankAccounts] = useState(false)
  const [accreditationTypeTitle, setAccreditationTypeTitle] = useState('Select an accreditation method')
  const [navType, setNavType] = useState(query.get('acc-sub-type') || 'nav')
  const [progressBarStep, setProgressBarStep] = useState(3)
  const [progressBarType, setProgressBarType] = useState('half')
  const [pageLoading, setPageLoading] = useState(true)
  const [showPlaidDocumentSuccessModal, setShowPlaidDocumentSuccessModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showAccreditationTypesModal, setShowAccreditationTypesModal] = useState(false)

  const { AccreditationThroughTestAllowed } = featureFlags || {}

  const passQuiz = hasTakenAccreditationQuiz && isRetailPurchaser

  const accreditationNavRegions = {
    USA: {
      ACTION: 'NAVIGATE',
      OPTIONS: ['ANNUAL_INCOME', 'NET_WORTH', 'JOINT_INCOME', 'SECURITIES_LICENSE']
    },
    NONE: {
      ACTION: 'NAVIGATE',
      OPTIONS: ['UPLOAD_DOCUMENT', 'EMAIL_FINANCIAL_ADVISOR']
    },
    ANNUAL_INCOME: {
      ACTION: 'NAVIGATE',
      OPTIONS: plaidAccreditation ? ['PLAID_ASSETS', accreditationIncomeFlow ? 'PLAID_UPLOAD_DOCUMENT' : 'UPLOAD_DOCUMENT', 'EMAIL_FINANCIAL_ADVISOR']
        : [accreditationIncomeFlow ? 'PLAID_UPLOAD_DOCUMENT' : 'UPLOAD_DOCUMENT', 'EMAIL_FINANCIAL_ADVISOR']
    },
    NET_WORTH: {
      ACTION: 'NAVIGATE',
      OPTIONS: plaidAccreditation ? ['PLAID_ASSETS', 'UPLOAD_DOCUMENT', 'EMAIL_FINANCIAL_ADVISOR'] : ['UPLOAD_DOCUMENT', 'EMAIL_FINANCIAL_ADVISOR']
    },
    JOINT_INCOME: {
      ACTION: 'NAVIGATE',
      OPTIONS: ['UPLOAD_DOCUMENT', 'EMAIL_FINANCIAL_ADVISOR']
    }
  }

  const getInvestorStatusPage = async () => {
    const res = await dispatch(getInvestorStatus())
    const { meta, payload } = res
    if (meta.requestStatus === 'fulfilled') {
      if (!payload?.hasKyc && payload?.isSignedIn) {
        history.push('/profile')
      } else {
        Mixpanel.track('Onboarding View Investor Status', { 'Accredited Country': payload?.accreditedCountry })
      }
      if ((payload?.accreditedStatus === 'UNDER_REVIEW' || payload?.documentAccreditationIsProcessing || payload?.isProcessing) && payload?.selfAccreditation) {
        setAccreditationType(payload?.selfAccreditation)
        if (payload?.selfAccreditation === 'SECURITIES_LICENSE') {
          setAccreditationSubType(null)
        } else if (payload?.assetsAccounts?.length > 0) {
          setAccreditationSubType('PLAID_ASSETS')
        } else if (payload?.hasRequestedFinancialAdvisor) {
          setAccreditationSubType('EMAIL_FINANCIAL_ADVISOR')
        } else {
          setAccreditationSubType('UPLOAD_DOCUMENT')
        }
      }
      if (selfAttestationUsOnInvestorStatus && payload?.accreditationRegion === 'USA') {
        setAccreditationSubType('SELF_ATTESTATION')
      }
      /* If loaded in react native webview, post message
         so webview can remove required elements from DOM */
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage('pageLoaded')
      }
      setPageLoading(false)
    }
  }

  useEffect(() => {
    getInvestorStatusPage()
  }, [])

  useEffect(() => {
    const messageListener = window.addEventListener('message', async (nativeEvent) => {
      try {
        const data = JSON.parse(nativeEvent?.data)
        if (data?.type === 'plaid success') {
          dispatch(getInvestorStatus('noRefresh'))
        } else if (data?.type === 'plaid income success') {
          const { payload } = await dispatch(getPlaidIncomeAccreditaiton())
          if (!payload) {
            Mixpanel.track('Onboarding Failed Accreditation', { 'Accredited Status': '$200k Individual Income for 2 Years', 'Accreditaiton Method': 'Plaid' })
          }
          dispatch(getInvestorStatus('noRefresh'))
        }
      } catch (err) { // eslint-disable-line no-unused-vars
        // ignore error intentionally because there are too many error log here.
      }
    })
    return messageListener
  }, [])

  useEffect(() => {
    /* For the react native webview, since the header has already
      rendered, this needs to be removed here and not in React Naive code */
    if (window.ReactNativeWebView) {
      document.getElementById('header-div').style.display = 'none'
    }
  }, [])
  const getProgressBarStep = () => {
    if (accreditedStatus === 'UNDER_REVIEW' || documentAccreditationIsProcessing || isProcessing) return 4
    if (accreditationSubType === 'SELF_ATTESTATION') return 3
    if (accreditationSubType) return 4
    if (accreditationType) return 3
    if (accreditedStatus === 'ACCREDITED') return 4
    return 3
  }
  useEffect(() => {
    updateProgressBar()
  }, [accreditationType, accreditationSubType, accreditationRegion, accreditedStatus, documentAccreditationIsProcessing, isProcessing])

  const updateProgressBar = (selfAttestationSubmitSuccess = false) => {
    if (selfAttestationSubmitSuccess) {
      setProgressBarStep(4)
      setProgressBarType('full')
      return
    }
    setNavType(accreditationSubType || ((accreditationRegion === 'USA' || accreditationRegion === 'NONE') ? 'nav' : ''))
    setAccreditationTypeTitle(accreditationType ? `Verify your ${accreditationOptions[accreditationType]?.TITLE}` : 'Select an accreditation method')
    setProgressBarStep(getProgressBarStep())
    setProgressBarType(accreditedStatus === 'UNDER_REVIEW' || documentAccreditationIsProcessing || isProcessing ? 'half' : accreditationSubType ? 'half' : accreditationType ? 'full' : accreditedStatus === 'ACCREDITED' ? 'full' : 'half')
  }

  useEffect(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(`accreditationType:${progressBarStep}:${accreditationType}`)
    }
  }, [progressBarStep, accreditationType])

  window.onpopstate = () => {
    setAccreditationType(query.get('acc-type') || null)
    setAccreditationSubType(query.get('acc-sub-type') || null)
  }

  const AccreditationNavItem = ({ title, copy, icon, onClick, tags, testId, clickable = true }) => (
    <div className={`acc-nav-item ${!clickable ? 'not-clickable' : ''}`} onClick={() => clickable ? onClick() : null} data-testid={testId} role='button' aria-label={title} tabIndex={0}>
      <img src={images[icon]} alt={title} />
      <div className='acc-nav-copy'>
        <div className='medium_2 gray5'>{title}</div>
        <div className='medium_1 gray3'>{copy}</div>
        {tags?.length > 0 && <div className='acc-nav-tags'>
          {tags?.map((tag, i) => {
            return (
              <div key={i} className={`acc-nav-tag ${tag?.type}`}>
                {tag?.copy}
              </div>
            )
          })}
        </div>}
      </div>
      {clickable && <img src={images.arrow_forward_ios} alt={`Go to ${title}`} className='acc-nav-arrow' />}
    </div>
  )

  const onAccreditationNavClicked = async (url, action) => {
    if (action === 'NAVIGATE') {
      setAccreditationType(url)
      const res = await dispatch(saveSelfAccreditation({ selfAccreditation: url }))
      const { meta } = res
      if (meta.requestStatus === 'fulfilled') {
        window.history.pushState(null, null, `/investor-status?acc-type=${url}`)
        setNavType('nav')
        setProgressBarStep(2)
        setProgressBarType('full')
        setAccreditationTypeTitle(`Verify your ${accreditationOptions[url]?.TITLE}`)
      }
    }
    if (action === 'SUB_NAVIGATE') {
      setNavType(url)
      setProgressBarStep(3)
      setProgressBarType('full')
      setAccreditationSubType(url)
      window.history.pushState(null, null, `/investor-status?acc-type=${accreditationType}&acc-sub-type=${url}`)
    }
  }

  const handleSubmitPlaid = async () => {
    // submit multiple account API
    setLoading(true)
    const res = await dispatch(submitAssetsAccounts())
    const { meta } = res
    if (meta.requestStatus === 'fulfilled') {
      setLoading(false)
      dispatch(getInvestorStatus())
      setSubmitPlaidBankAccounts(true)
    }
  }

  const renderEditProilfeDetailsButton = () => {
    const type = location?.state?.type
    if (accreditationRegion === 'USA' || accreditationRegion === 'NONE') {
      if (navType === 'nav' || navType === 'SELF_ATTESTATION') {
        if (progressBarStep === 3 && !type) {
          return <EditProfileDetailsButton />
        }
      }
    }
    return null
  }

  const renderAccreditationComponent = () => {
    if (accreditationSubType && (accreditationRegion === 'USA' || accreditationRegion === 'NONE')) {
      switch (accreditationSubType) {
      case 'EMAIL_FINANCIAL_ADVISOR':
        return <FinancialAdvisorRevamp />
      case 'UPLOAD_DOCUMENT':
        return <ManualUploadDocumentRevamp type={accreditationType || accreditationRegion}/>
      case 'SECURITIES_LICENSE':
        return <CRDRevamp />
      case 'PLAID_ASSETS':
        return <PlaidAssetsRevamp handleSubmitPlaid={handleSubmitPlaid} loading={loading}/>
      case 'FINANCIAL_SOPHISTICATION':
        return <FinanciallySophisticatedTestRevamp setShowAccreditationTypesModal={setShowAccreditationTypesModal}/>
      case 'SELF_ATTESTATION':
        return <SelfAttestationUS withProgressBar={true} updateProgressBar={updateProgressBar}/>
      default:
        return null
      }
    } else {
      switch (accreditationRegion) {
      case 'EU_SELF':
        return <EUResidentsSectionRevamp />
      case 'UK_SELF':
        return <UKResidentsSectionRevamp />
      case 'OTHER_NOT_REGULATED':
        return <NoRegCountriesSectionRevamp />
      default:
        return null
      }
    }
  }

  const handleBannerClick = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('navigateToInvest')
    } else {
      history.push('/products')
    }
  }

  const handleFSNavItem = () => {
    if (!passQuiz) {
      onAccreditationNavClicked('FINANCIAL_SOPHISTICATION', accreditationOptions.FINANCIAL_SOPHISTICATION.ACTION)
    }
  }

  if (pageLoading || !accreditedStatus) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Accredited Investor')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Accredited Investor')} />
      <div className='page-container investor-status-container'>
        <div className='inner-container'>
          {showAccreditationTypesModal && <AccreditationTypesModal setShowAccreditationTypesModal={setShowAccreditationTypesModal} status={status} />}
          {showPlaidDocumentSuccessModal && <USSuccessModal hideModal={() => setShowPlaidDocumentSuccessModal(false)} />}
          {accreditationSubType === 'PLAID_ASSETS' && wasAccredited === false && assetsAccounts?.length > 0 && <PlaidBankAccountBanner text='We were unable to confirm your $200k accreditation status via your chosen bank. Please use a different method.' />}
          {submitPlaidBankAccounts && <PlaidBankAccountBanner text='Your accreditation verification has been submitted. We will send you an email update in a few minutes.' link='Explore Investments' onClick={handleBannerClick} />}
          {documentAccreditationIsProcessing && <PlaidBankAccountBanner headline='Documents Under Review.' text='We will notify you via email once your accreditation verification is complete.' link='Explore Investments' onClick={handleBannerClick}/>}
          <div className='giant_2' id='investor-status-title'>Investor Profile</div>
          {suitabilityQuestions ? <NewProgressBar activeStep={8} /> : <ProgressBarRevamp step={progressBarStep} type={progressBarType} />}
          <ConditionalRender isVisible={(accreditedStatus === 'NOT_VERIFIED' || accreditedStatus === 'FAILED') && (!documentAccreditationIsProcessing && !isProcessing) }>
            <ConditionalRender isVisible={navType === 'nav'}>
              <ConditionalRender isVisible={!accreditationType} className='verify-acc'>
                <div className='large_1'>Verify Accreditation</div>
                <div className='medium_1 space-above-sm'>Regulations require you to be accredited to invest on Linqto. {' '}
                  <Button className='link primary underlined in-line' onClick={() => setShowAccreditationTypesModal(true)} ariaLabel='Learn more about regulations require you to be accredited to invest on Linqto'>Learn more</Button> </div>
              </ConditionalRender>
              <ConditionalRender isVisible={AccreditationThroughTestAllowed && (!hasTakenAccreditationQuiz || (passQuiz))} className='verify-acc'>
                <div className='large_1 acc-nav-title'>Non-Accredited? (Limited Experience)</div>
                <AccreditationNavItem
                  title={accreditationOptions.FINANCIAL_SOPHISTICATION.TITLE}
                  copy={!passQuiz ? accreditationOptions.FINANCIAL_SOPHISTICATION.COPY : ''}
                  icon={accreditationOptions.FINANCIAL_SOPHISTICATION.ICON}
                  tags={passQuiz ? [{ type: 'quiz-pass', copy: 'Passed Test' }] : []}
                  onClick={() => handleFSNavItem()}
                  clickable = {!passQuiz}
                  testId='acc-nav-item-fs'
                />
              </ConditionalRender>
              {accreditationNavRegions[accreditationType || accreditationRegion]?.OPTIONS && <div className='large_1 acc-nav-title'>{accreditationTypeTitle}:</div>}
              <div className='mb-12'>
                {accreditationNavRegions[accreditationType || accreditationRegion]?.OPTIONS?.map((item, i) => {
                  if(item === 'PLAID_UPLOAD_DOCUMENT') {
                    return <PlaidDocumentIncomeLink 
                      key={i}
                      title={accreditationOptions[item]?.TITLE}
                      copy={accreditationOptions[item]?.COPY}
                      icon={accreditationOptions[item]?.ICON}
                      tags={accreditationOptions[item]?.TAGS}
                      testId={`acc-nav-item-${i + 1}`}
                      setShowPlaidDocumentSuccessModal={setShowPlaidDocumentSuccessModal}
                    />
                  }
                  return (
                    <AccreditationNavItem
                      key={i}
                      title={accreditationOptions[item]?.TITLE}
                      copy={accreditationOptions[item]?.COPY}
                      icon={accreditationOptions[item]?.ICON}
                      tags={accreditationOptions[item]?.TAGS}
                      onClick={() => onAccreditationNavClicked(item, accreditationOptions[item]?.ACTION)}
                      testId={`acc-nav-item-${i + 1}`}
                    />
                  )
                })}
              </div>
            </ConditionalRender>
            {renderAccreditationComponent()}
            {renderEditProilfeDetailsButton()}
          </ConditionalRender>
          <ConditionalRender isVisible={accreditedStatus === 'UNDER_REVIEW' || documentAccreditationIsProcessing || isProcessing}>
            <UnderReviewStatusView accreditationTypeData={accreditationOptions[accreditationType]} accreditationSubTypeData={accreditationOptions[accreditationSubType]} />
          </ConditionalRender>
          <ConditionalRender isVisible={accreditedStatus === 'ACCREDITED'}>
            <AccreditationVerifiedRevamp />
          </ConditionalRender>
          <ConditionalRender isVisible={accreditationSubType !== 'FINANCIAL_SOPHISTICATION'}>
            <div className='medium_1 space-above-lg'>
              Terms of accreditation are determined by your country of{' '}
              {accreditedCountrySource === 'RESIDENCE' ? 'residence' : 'citizenship'}:{' '}
              <span className='medium_2'>{accreditedCountry}</span>
            </div>
          </ConditionalRender>
          <ContactSupport alignment='left' onClick={() => Mixpanel.track('Onboarding Click Contact Support')} />
        </div>
      </div>
    </>
  )
}

export default InvestorStatusRevamp
