import SeoMeta from 'components/Global/SeoMeta'
import Button from 'components/Global/Button'
import { useHistory } from 'react-router-dom'
import { seoTitleTemplate } from 'utils'

const DeleteAccountSuccess = () => {
  const history = useHistory()

  const goToSignIn = async () => {
    history.push('/signin')
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Account Successfully Deleted')} />
      <div className='page-container lg:mt-12 mt-0'>
        <div className='inner-container flex-col items-center text-center'>
          <div className='giant_1 text-accessibleIndigo my-7'>Account Successfully Deleted</div>
          <div className='medium_1 my-7'>
            Your account with Linqto has been successfully deleted.
          </div>
          <div className='medium_1'>
            Some records may be kept on file with Linqto indefinitely per regulation.
          </div>
          <div className='medium_1 my-7'>
            If you wish to invest on the platform, you will need to create a new account.
          </div>
          <Button onClick={goToSignIn}>
            OK
          </Button>
          <div className='medium_1 pt-4'>
            Need help?{' '}
            <Button
              onClick={() => history.push('/contact')}
              className='link primary contact-us'
            >
              Contact Support
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default DeleteAccountSuccess
