import { useEffect, useState } from 'react'
import { PageLoading, ConditionalRender, Button} from 'components'
import { Mixpanel, seoTitleTemplate, formatDecimal, formatShares, Fullstory } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getBundleOrderConfirmedPage } from 'slices/bundleOrderSlice'
import { useQuery } from 'hooks/useQuery'

const BundleOrderItem = ({ order }) => {
  const history = useHistory()
  return (
    <div className='buy-order-bundle-row'>
      <div className='buy-order-row'>
        <div className='buy-order-bundle-name-row medium_4'><img className='buy-order-bundle-company-icon' src={order?.company?.iconUrl} alt={order?.company?.name} /> {order?.company?.name}</div>
      </div>
      <div className='order-confirmed-row'>
        <span>Order ID</span>
        <span>{order?.orderId}</span>
      </div>
      <div className='order-confirmed-row'>
        <span>Estimated Shares</span>
        <span>{formatShares(order?.shares)}</span>
      </div>
      <div className='order-confirmed-row'>
        <span className='medium_2'>Investment Amount</span>
        <span className='medium_2'>{formatDecimal(order?.amount)}</span>
      </div>
      <div className='order-confirmed-row'>
        <Button 
          className='medium_2 primary link underlined' 
          onClick={() => history.push(`/order/${order?.orderId}`)}
          ariaLabel={`View details for order ${order?.orderId}`}>
            View Details
        </Button>
      </div>
    </div>)
}

const BundleOrderConfirmed = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { buyOrderTotal, ordersList, amountToCashAccount, sellOrdersList } = useSelector(state => state.bundleOrder)

  const [pageLoading, setPageLoading] = useState(true)
  const { tradeId } = useParams()
  const query = useQuery()
  const bundleId = query.get('bundleId')

  useEffect(() => {
    (async () => {
      const res = await dispatch(getBundleOrderConfirmedPage({ tradeId }))
      setPageLoading(false)
      const { payload, meta } = res
      if (meta?.requestStatus === 'fulfilled') {
        const bundleCompanies = payload?.ordersList.map(item => ({ Name: item.company?.name, 'Amount selected': item.amount, 'Estimated shares': item.shares }))
        Mixpanel.track('View Bundle Order Confirmed Page', {
          Companies: bundleCompanies,
          'Bundle ID': bundleId
        })
        Fullstory.track('Page View', {
          page_name: 'Multi-Order Bundle Order Confirmed Page',
          companies: bundleCompanies,
          bundle_id: bundleId
        })
      }
    })()
  }, [])

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Order Confirmed')} />
        <PageLoading />
      </>
    )
  }
  return (
    <>
      <SeoMeta title={seoTitleTemplate('Order Confirmed')} />
      <div className='page-container buy-order-container'>
        <div className='inner-container'>
          <div className='buy-order-header'>
            <h1>Order Confirmed</h1>
          </div>
          <div className='order-confirmed-container'>
            <div className='medium_4 buy-order-bundle-row'>Bundle Purchase</div>
            {ordersList?.length > 0 && ordersList?.map((order) => (
              <BundleOrderItem order={order} key={order.orderId}/>
            ))}
            <div className='order-confirmed-row'>
              <span className='medium_4'>Total Investment Amount</span>
              <span className='medium_4'>{formatDecimal(buyOrderTotal)}</span>
            </div>
          </div>
          <div className='medium_1 centered dark space-above-md space-below-md'>Thank you for your purchase.</div>
          <ConditionalRender isVisible={amountToCashAccount > 0}>
            <div className='order-confirmed-amount-container'>
              <div className='order-confirmed-row'>
                <span className='medium_2'>Amount to Cash Account</span>
                <span className='medium_2'>{formatDecimal(amountToCashAccount)}</span>
              </div>
              <div className='medium_1'>A remainder of funds not applied toward purchase will be returned to your cash account.</div>
            </div>
          </ConditionalRender>
          <div className='flex-col max-w-2xl ml-auto mr-auto md:gap-4 md:flex-row'>
            <Button
              mode='secondary'
              onClick={() => {
                Mixpanel.track('Click Make Another Purchase on Buy Order Confirmation Page')
                Fullstory.track('Button', { name: 'Make Another Purchase' })
                history.push('/products')
              }}
            >Make Another Purchase</Button>
            {sellOrdersList?.length === 0 &&
              <Button
                onClick={() => {
                  Mixpanel.track('Click View My Portfolio on Buy Order Confirmation Page')
                  Fullstory.track('Button', { name: 'View My Portfolio' })
                  history.push('/portfolio')
                }}
              >View My Portfolio</Button>
            }
            {sellOrdersList?.length > 0 &&
              <Button
                onClick={() => {
                  Mixpanel.track('Click View Sell Order on Buy Order Confirmation Page')
                  Fullstory.track('Button', { name: 'View Sell Order Details' })
                  if (sellOrdersList?.[0]?.orderId) {
                    history.push(`/sell-order/${sellOrdersList?.[0]?.orderId}`)
                  }
                }}
              >View Sell Order</Button>}
          </div>
        </div>
      </div>
    </>
  )
}

export default BundleOrderConfirmed
