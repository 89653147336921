import { images } from 'assets'

const TrustedContactRemoveLink = ({ handelRemove, deleteLoading }) => {
  return (
    <div className='column eight sixteen-mobile'>
      <a onClick={handelRemove} className={`flex items-center justify-start cursor-pointer ${deleteLoading ? 'text-gray3' : 'text-royal'} hover:text-royal medium_2`}>
        <img className='mr-1' alt='Remove' src={!deleteLoading ? images.remove : images.remove_disabled} /> Remove</a>
    </div>
  )
}

export default TrustedContactRemoveLink
