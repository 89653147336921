import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changePassword } from 'slices/securitySlice'
import FormError from 'components/Global/FormError'
import { PasswordInput, Button, Modal } from 'components'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from 'schemas/changePasswordSchema'

const PsdModal = ({ hideModal }) => {
  const dispatch = useDispatch()
  const { loading } = useSelector((state) => state.securitySlice)
  const [success, setSuccess] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const methods = useForm({
    resolver: yupResolver(schema)
  })
  const { handleSubmit } = methods
  const onSubmit = (formValues) => {
    dispatch(changePassword({ ...formValues })).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        setSuccess(true)
        setTimeout(() => {
          hideModal()
        }, 1000)
      } else {
        setSubmitError(true)
      }
    })
  }

  return (
    <Modal
      innerStyle='psd-modal'
      hideModal={hideModal}
      modalHeader='Change Password'
      crossToClose
    >
      {!success ? (
        <FormProvider {...methods}>
          <form className='grid-old ' onSubmit={handleSubmit(onSubmit)}>
            <div className='column sixteen'>
              <PasswordInput
                name='currentPsd'
                label='Current Password'
                ariaLabel='Current Password'
                type={showCurrentPassword ? 'text' : 'password'}
                viewPassword={() => setShowCurrentPassword((prev) => !prev)}
                disabled={loading}
              />
            </div>
            <div className='column sixteen'>
              <PasswordInput
                name='newPsd'
                label='New Password'
                ariaLabel='New Password'
                type={showNewPassword ? 'text' : 'password'}
                viewPassword={() => setShowNewPassword((prev) => !prev)}
                disabled={loading}
              />
            </div>
            <div className='column sixteen'>
              <PasswordInput
                name='confirmPsd'
                label='Confirm Password'
                ariaLabel='Confirm Password'
                type={showConfirmPassword ? 'text' : 'password'}
                viewPassword={() => setShowConfirmPassword((prev) => !prev)}
                disabled={loading}
              />
            </div>
            <div className='column sixteen'>
              {submitError &&
                <FormError errorMessageHeader='Your current password was incorrect.' errorMessageCopy='Please re-enter and try again.' customClass='full-width'/>}
            </div>
            <div className='column sixteen'>
              <div className='flex items-center ml-auto mr-auto'>
                <Button
                  mode='tertiary'
                  type='button'
                  disabled={loading}
                  onClick={hideModal}
                >
                  Cancel
                </Button>
                <Button
                  type='submit'
                  loading={loading}
                  disabled={loading}
                >
                  Change Password
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      ) : (
        'Password has reset.'
      )}
    </Modal>
  )
}

export default PsdModal
