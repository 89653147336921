import { MOBILE_WIDTH_SIZE } from 'utils'
import { useSelector } from 'react-redux'
import { MFAContentDescription, MFALabel } from '../../../data'
import useWindowSize from 'hooks/useWindowSize'

const MfaModalHeader = ({ type, steps }) => {
  const { currentMfa, email } = useSelector((state) => state.securitySlice)
  const { width } = useWindowSize()

  const isMobile = width <= MOBILE_WIDTH_SIZE

  return (
    <>
      <label className='block text-center'>
        {MFALabel[type][steps - 1]} {type === 'email' && email}
      </label>
      <div className='medium_1'>
        {MFAContentDescription(type, steps, isMobile)}{' '}
        {type === 'sms' && steps === 2 &&
          currentMfa?.value?.includes('***') &&
          currentMfa?.value}
      </div>
    </>
  )
}

export default MfaModalHeader
