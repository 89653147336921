const FormError = ({ errorMessageHeader, errorMessageCopy, customClass = '' }) => {
  return (
    <div className={`error-container ${customClass}`}>
      <div className='error message'>
        <div className='medium_2 header'>{errorMessageHeader}</div>
        <p>{errorMessageCopy}</p>
      </div>
    </div>
  )
}
export default FormError
