const InvestDetailSummaryOfficers = ({ officers }) => {
  const keyOfficersTitles = (titles) => {
    return titles.filter(obj => obj.isCurrent).map(obj => obj.title).join(', ')
  }

  const keyOfficersWorkHistory = (workHistory) => {
    return workHistory.map(obj => obj.companyName).join(', ')
  }
  return (
    <>
      <div className='grid-old unicorn-table invest-table desktop-content'>
        <div className='unicorn-table-header row'>
          <div className='column four medium_2'>
              Name
          </div>
          <div className='column four medium_2'>Work History</div>
          <div className='column four medium_2'>Title</div>
          <div className='column four medium_2'>Status</div>
        </div>
        {officers && officers.map((p, i) =>
          <div key={`keyOfficer_${i}`} className='row unicorn-table-body'>
            <div className='column four medium_1 gray3'>{p.name}</div>
            <div className='column four medium_1 gray3'>{p.workHistory && p.workHistory.length > 0 && keyOfficersWorkHistory(p.workHistory)}</div>
            <div className='column four medium_1 gray3'>{p.companyTitles && p.companyTitles.length > 0 && keyOfficersTitles(p.companyTitles)}</div>
            <div className='column four medium_1 gray3'>{keyOfficersTitles(p.companyTitles).length > 0 && 'Current'}</div>
          </div>
        )}
      </div>
      <div className='unicorn-card-container'>
        {officers && officers.map((p, i) =>
          <div className='unicorn-card' key={`mkeyOfficer_${i}`} >
            <div className='container-margin'>
              <div className='row unicorn-card-row flex'>
                <div className='column eight-mobile medium_2 no-padding-bottom no-padding-horizontal'>{p.name}</div>
                <div className='column eight-mobile medium_1 gray3 text-align-end no-padding-bottom no-padding-horizontal'>{p.companyTitles && p.companyTitles.length > 0 && keyOfficersTitles(p.companyTitles)}</div>
              </div>
              <div className='row unicorn-card-row no-padding-top no-padding-bottom'>
                <span className='sixteen-mobile medium_2 gray3'>Status: {' '}
                  <span className='medium_1 gray3'>{keyOfficersTitles(p.companyTitles).length > 0 && 'Current'}</span>
                </span>
              </div>
              <div className='row unicorn-card-row no-padding-top'>
                <span className='sixteen-mobile medium_2 gray3'>Work History: {' '}
                  <span className='medium_1 gray3'>{p.workHistory && p.workHistory.length > 0 && keyOfficersWorkHistory(p.workHistory)}</span>
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
export default InvestDetailSummaryOfficers
