import Modal from './Modal'
import Button from './Button'

const SuccessModal = ({ message, hideModal }) => {
  return (
    <>
      <Modal
        crossToClose
        modalHeader=''
        hideModal={hideModal}
      >
        <div className='medium_1 content'>{message}</div>
        <div className='inline-flex justify-center'>
          <Button onClick={hideModal}>
            OK
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default SuccessModal
