import React from 'react'
import { useFormContext } from 'react-hook-form'
import TooltipLabel from '../TooltipLabel'
import ErrorMessage from './ErrorMessage'

const NumberInput = ({
  name,
  label,
  className = '',
  placeholder,
  content = '',
  handleKeyPress,
  disabled = false,
  ariaLabel = '',
  onChange = () => {},
  onBlur = () => {},
  isDecimal = false,
  type = 'number',
  min = 0
}) => {
  const {
    register,
    formState: { errors }
  } = useFormContext()
  return (
    <div className={`input-group ${errors[name] && 'error'} ${className}`}>
      <TooltipLabel content={content} label={label} htmlFor={name} />
      <input
        type={type}
        disabled={disabled}
        inputMode={isDecimal ? 'decimal' : 'text'}
        placeholder={placeholder}
        {...register(name, {
          onChange,
          onBlur,
          min
        })}
        onKeyPress={handleKeyPress}
        aria-label={ariaLabel}
        id={name}
      />
      {errors[name] && <ErrorMessage message={errors[name].message} />}
    </div>
  )
}

export default NumberInput
