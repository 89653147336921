import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import TextInput from 'components/Global/Inputs/TextInput'
import Select from 'components/Global/Inputs/Select'
import { getRegions } from 'slices/commonSlice'
import _ from 'lodash'

const TrustedContactAddressInput = ({
  countries,
  getCountriesList,
  getRegionsList,
  trustedContactRegions,
  setTrustedContactRegions
}) => {
  const {
    setValue,
    control,
    watch
  } = useFormContext()

  const {
    trustedContact,
    profileLoading,
    deleteLoading
  } = useSelector((state) => state.profile)

  const dispatch = useDispatch()

  const disabled = profileLoading || deleteLoading

  useEffect(() => {
    if (!_.isEmpty(trustedContact)) {
      const trustedContactAddress = trustedContact?.address
      const validCountry = countries.some(c => c === trustedContactAddress.country)
      if (validCountry) {
        dispatch(getRegions(trustedContactAddress.country)).then(({ meta, payload }) => {
          if (meta.requestStatus === 'fulfilled') {
            setTrustedContactRegions(payload)
            if (!payload.some(state => state === trustedContactAddress.state)) {
              setValue('trustedContactState', '')
            }
          }
        })
      }
    }
  }, [trustedContact])

  const onCountryChange = (val) => {
    setValue('trustedContactState', '', { shouldDirty: true })

    if (val !== '') {
      dispatch(getRegions(val)).then(({ payload }) => {
        setTrustedContactRegions(payload)
      })
    } else {
      setTrustedContactRegions([])
    }
  }

  return (
    <>
      <div className='column eight sixteen-mobile'>
        <TextInput
          name='trustedContactStreet1'
          label='Adress Line 1'
          disabled={disabled}
          ariaLabel='Trusted Contact Street 1'
        />
      </div>
      <div className='column eight sixteen-mobile'>
        <TextInput
          name='trustedContactStreet2'
          label='Address Line 2'
          disabled={disabled}
          ariaLabel='Trusted Contact Street 2'
        />
      </div>
      <div className='column eight sixteen-mobile'>
        <TextInput
          name='trustedContactCity'
          label='City'
          disabled={disabled}
          ariaLabel='Trusted Contact City'
        />
      </div>
      <div className='column eight sixteen-mobile'>
        <TextInput
          name='trustedContactZip'
          label='Postal Code'
          disabled={disabled}
          ariaLabel='Trusted Contact Zip'
        />
      </div>
      <div className='column eight sixteen-mobile'>
        <Controller
          control={control}
          name='trustedContactCountry'
          render={({ field }) => (
            <Select
              field={field}
              label='Country'
              name='trustedContactCountry'
              options={countries?.length > 0 ? countries : [trustedContact?.address?.country] }
              ref={null}
              disabled={disabled}
              onClick={getCountriesList}
              defaultOption={{ value: '', label: 'Country' }}
              handleChange={onCountryChange}
              ariaLabel='Trusted Contact Country'
            />
          )}
        />
      </div>
      <div className='column eight sixteen-mobile'>
        <Controller
          control={control}
          name='trustedContactState'
          render={({ field }) => (
            <Select
              field={field}
              label='State or Province'
              name='trustedContactState'
              options={trustedContactRegions?.length > 0 ? trustedContactRegions : [trustedContact?.address?.state]}
              ref={null}
              disabled={disabled || !watch('trustedContactCountry')}
              defaultOption={{ value: '', label: 'State' }}
              ariaLabel='Trusted Contact State'
              onClick={() => getRegionsList(watch('trustedContactCountry'), 'TrustedContact')}
            />
          )}
        />
      </div>
    </>
  )
}

export default TrustedContactAddressInput
