import { formatDecimal, MOBILE_WIDTH_SIZE, transformPromoFlag } from 'utils'
import { Button } from 'components'
import PropTypes from 'prop-types'
import useWindowSize from 'hooks/useWindowSize'

export const tagStyle = ({type}) => {
  switch(type) {
  case 'TOP_SELLER': return 'text-[#3E1C96] border-[#9E77ED]'
  case 'COMING_SOON': return 'text-[#1849A9] border-[#53B1FD]'
  case 'FULLY_SUBSCRIBED': return 'border-gray2 text-gray3'
  default: return 'border-gray2 text-gray3'
  }
}

const renderThumbnailItem = ({listType, item, hideTag}) => {
  switch(listType) {
  case 'products': 
    return (
      <div className='w-full'>
        <img src={item?.alternateLogoUrl || item?.iconUrl} alt={`${item?.name} logo`} className='w-full h-[148px] md:h-auto object-cover aspect-[1.73] rounded-lg'/>
        {item?.promoFlag !== 'NONE'  && item?.promoFlag !== hideTag && 
        <span className={`absolute border rounded-[4px] sm:top-6 sm:left-6 top-3 left-3 bg-white ${tagStyle(item?.promoFlag)} text-xs px-1 uppercase`}>{transformPromoFlag(item?.promoFlag)}</span> }
      </div>)
    
  case 'bundles': 
    return (
      <div className='flex overflow-hidden'>
        {item?.companies && item?.companies.length > 0 ? item?.companies?.map(({ iconUrl, name }, index) => (
          <img key={index} className={`w-12 h-12 rounded-[50%] ${index !== 0 ? '-ml-3' : ''}`} src={iconUrl} alt={name} data-testid={index} />
        )) : null}
      </div>
    )
  default: return '<div />'
  }
}

const ThumbnailItem = ({ listType, item, hideTag ='', onItemClick, index, section }) => (
  <Button data-testid={`thumb-${listType}-${index}`} className={`flex flex-col rounded-2xl ${listType === 'products' ? 'sm:border-gray6 sm:border sm:p-4 p-0' : 'border border-gray6 p-4'} relative w-full md:w-[31.3%] lg:w-[31.8%] xl:w-[23.1%] overflow-hidden items-start text-left`} onClick = {() => onItemClick(item, section) } ariaLabel={`${item?.name || item?.title}, price: ${formatDecimal(item?.sharePrice) || formatDecimal(item?.price)}`}>
    {renderThumbnailItem({listType, item, hideTag})}
    <div className='flex w-full md:flex-col'>
      {listType === 'products' ? <div className='flex flex-col w-full overflow-hidden'>
        <div className='w-full mt-2 overflow-hidden medium_2 whitespace-nowrap overflow-ellipsis'>{item?.name || item?.title}</div>
        <div className='w-full overflow-hidden text-sm body_1 whitespace-nowrap overflow-ellipsis'>{item?.vertical || item?.companies?.map(obj => obj.name).join(' • ')}</div>
        <div className='w-full md:mt-2 md:text-left md:ml-0'>{(item?.sharePrice || item?.price) ? <div className='medium_2'>{formatDecimal(item?.sharePrice) || formatDecimal(item?.price)} <span className='text-gray3 body_1'>/ Share Price</span> </div> : <div className='medium_2 text-gray3'>N/A</div>}</div>
      </div> : <div className='sm:flex-col flex justify-between w-full'>
        <div>
          <div className='w-full mt-2 overflow-hidden text-lg medium_4 whitespace-nowrap overflow-ellipsis'>{item?.name || item?.title}</div>
          <div className='w-full overflow-hidden text-sm body_1 whitespace-nowrap overflow-ellipsis'>{item?.vertical || item?.companies?.map(obj => obj.name).join(' • ')}</div>
        </div>
        <div className='sm:flex-col sm:mt-2 sm:text-left text-right'>
          <span className='small_1 text-gray3'>Bundle Price</span>
          <div>{(item?.sharePrice || item?.price) ? <div className='medium_2'>{formatDecimal(item?.sharePrice) || formatDecimal(item?.price)}</div> : <div className='medium_2 text-gray3'>N/A</div>}</div>
        </div>
      </div>}
    </div>
  </Button>
)

const ListItem = ({ item, onItemClick, index, section }) => (
  <Button data-testid={`list-products-${index}`} className='flex border rounded-2xl border-gray6 p-4 gap-4 w-full md:w-[48.5%] lg:w-[48.7%] items-center overflow-x-hidden' onClick = {() => onItemClick(item, section)} ariaLabel={`${item?.name || item?.title}, price: ${formatDecimal(item?.sharePrice) || formatDecimal(item?.price)}`}>
    <img src={item?.iconUrl} alt={`${item?.name} logo`} className='w-[48px] h-[48px] rounded-[50%]' />
    <div className='flex flex-col items-start'>
      <div className='text-left body_2 '>{item?.name}</div>
      <div className='text-left body_1'>{item?.vertical}</div>
    </div>
    <div className='ml-auto text-right'><div className='text-gray3 small_1'>Share Price</div>{item?.sharePrice ? <div className='medium_2'>{formatDecimal(item?.sharePrice)}</div> : <div className='medium_2 text-gray3'>N/A</div> }</div>
  </Button>
)

const InvestSection = ({ title, list = [], listType = 'products', listStyle='thumbnail', hideTag='', verticals = [], onItemClick }) => {
  const { width } = useWindowSize()
  
  const maxVerticalCount = () => {
    if (width < 768) return 4;
    if (width < 1024) return 2;
    if (width < 1280) return 3;
    return 4
  }

  return (
    <div className='mb-14 sm:mt-4 -mt-4'>
      {title !== '' && <h2 className='mb-4 large_1 font-CodecColdBold' >{title}</h2>}
      {title === 'Companies' && width <= MOBILE_WIDTH_SIZE && <div className='flex gap-2 pr-6 overflow-x-auto md:pr-0 mb-4' role='group' aria-label='Filter sectors' style={{scrollbarWidth: 'none'}}>
        {verticals?.length > 0 && verticals?.slice(0, maxVerticalCount()).map((vertical, i) =>
          <Button
            className='px-4 rounded-[100px] bg-gray1 flex-[0_0_auto] whitespace-nowrap font-CodecColdRegular h-10 md:h-8 text-sm'
            key={`vertical${i}`}
            data-testid={`vertical-${i}`}
            ariaLabel={`Filter by ${vertical}`} onClick={() => onItemClick(vertical)}>{vertical}</Button>
        )}
        <select
          onChange={(e) => onItemClick(e.target.value)}
          data-testid='sector-select'
          className='appearance-none rounded-[100px] bg-gray1 font-CodecColdRegular border-0 hover:border-0 w-[110px] text-sm pr-0 h-10 md:h-8'
          aria-label='Filter by sectors'>
          <option value=''>Sectors</option>
          <option value='all'>All Sectors</option>
          {verticals && verticals.length > 0 && verticals.map((e, i) => <option value={e} key={`vertical-${i}`}>{e}</option>) }
        </select>
      </div>}
      <div className={`${title === 'Featured' && width <= MOBILE_WIDTH_SIZE ? 'grid grid-cols-2' : 'flex flex-wrap'} min-w-full ${listStyle === 'thumbnail' ? 'sm:gap-7 gap-3' : 'gap-x-5 xl:gap-x-7 gap-y-5'}`}>
        {list.map((item, i) =>
          listStyle === 'thumbnail' ? <ThumbnailItem key={`list_${i}`} listType={listType} item={item} hideTag={hideTag} onItemClick={onItemClick} index={i} section={title} /> :   <ListItem key={`list_${i}`} item={item} onItemClick={onItemClick} index={i} section={title}/>
        )}
      </div>
    </div>)
}

InvestSection.propTypes = {
  listType: PropTypes.oneOf(['products', 'bundles']),
  listStyle:  PropTypes.oneOf(['thumbnail', 'list']),
  list:  PropTypes.array,
  title: PropTypes.string,
  hideTag: PropTypes.string,
  onItemClick: PropTypes.func
}

export default InvestSection

