const AccreditationTerms = ({ accreditedCountry, accreditedCountrySource }) => {
  return (
    <>
      <div className='medium_1 accreditation-terms'>
        Terms of accreditation are determined by your country of{' '}
        {accreditedCountrySource === 'RESIDENCE' ? 'residence' : 'citizenship'}:{' '}
        <span className='medium_2'>{accreditedCountry}</span>
      </div>
      <div className='medium_1 accreditation-terms'>
        If you are not a citizen or resident of this country, or you think you’ve provided incorrect financial information, you can update your information on the previous page.
      </div>
    </>
  )
}

export default AccreditationTerms
