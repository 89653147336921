import { images } from 'assets'

const OriginationFooter = () => {
  return (
    <div className='origination-footer'>
      <h3 className='origination-footer-title'>How It Works</h3>
      <div className='origination-footer-section-container'>
        <div className='origination-footer-section'>
          <img src={images['origination-search']} className='origination-footer-img' alt='Origination Search'/>
          <div className='medium_1'>Search and select the company in which you hold private shares.</div>
        </div>
        <div className='origination-footer-section'>
          <img src={images['origination-hundreds-roll']} className='origination-footer-img' alt='Origination hundreds roll'/>
          <div className='medium_1'>If Linqto wants to buy them, we’ll show you our offer price per share.</div>
        </div>
        <div className='origination-footer-section'>
          <img src={images['origination-lightbulb-money']} className='origination-footer-img' alt='Origination lightbulb money'/>
          <div className='medium_1'>Simply create an account, finalize and submit your sell order.  Our team will contact you to handle all the closing details.</div>
        </div>
      </div>
    </div>
  )
}

export default OriginationFooter
