import { Modal } from 'components'
import Button from '../../Global/Button'

const SubmitSuccessModal = ({ hideModal, header, children }) => {
  return (
    <Modal
      crossToClose
      hideModal={hideModal}
      modalHeader={header}>
      {children}
      <div className='inline-flex justify-center'>
        <Button onClick={hideModal}>OK</Button>
      </div>
    </Modal>
  )
}

export default SubmitSuccessModal
