const FinanciallySophisticatedProgressBar = ({ currentPage, total, totalItems = 20, isMobile }) => {
  const start = currentPage + (currentPage - 1) * total
  const end = start + total

  return (
    <div className='progressbar-wrapper'>
      <div className='gray3 medium_1'>{isMobile ? `Question ${currentPage} of ${total}` : `Questions ${start} - ${end} of ${totalItems}`}</div>
      <div className='progressbar-line-bg'>
        <div className='progressbar-line' style={{ width: `${(currentPage / total) * 100}%` }}/>
      </div>
    </div>
  )
}

export default FinanciallySophisticatedProgressBar
