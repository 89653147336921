import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'slices/commonSlice'
import api from '../apis/private.js'
import parseBroker from 'actions/parsing/parsingBroker.js'

const initialState = {
  pageLoading: false,
  submitLoading: false,
  initialValues: {
    accountName: '',
    accountNumber: '',
    confirmAccountNumber: '',
    dtc: '',
    firmName: '',
    specialInstructions: ''
  }
}

export const getBrokerInfo = createAsyncThunk('getBrokerInfo', (entityId, { dispatch, fulfillWithValue, rejectWithValue }) => {
  const url = entityId ? `/page/userBrokerInfo?entityId=${entityId}` : '/page/userBrokerInfo'
  return api
    .get(url)
    .then(res => {
      return fulfillWithValue(res.data)
    })
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return rejectWithValue(err.response)
    })
})

export const submitBrokerInfo = createAsyncThunk('submitBrokerInfo', (formValues, { dispatch, rejectWithValue }) => {
  return api
    .post('/page/userBrokerInfo', { brokerInfo: formValues })
    .catch(err => {
      if (err.response) {
        dispatch(handleServerError(err.response.data.error))
      }
      return rejectWithValue(err.response)
    })
})

export const brokerSlice = createSlice({
  name: 'broker',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getBrokerInfo.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getBrokerInfo.fulfilled, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload,
          initialValues: { ...parseBroker(payload) }
        }
      })
      .addCase(getBrokerInfo.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(submitBrokerInfo.pending, (state) => {
        state.submitLoading = true
      })
      .addCase(submitBrokerInfo.fulfilled, (state) => {
        state.submitLoading = false
      })
      .addCase(submitBrokerInfo.rejected, (state) => {
        state.submitLoading = false
      })
  }
})

export default brokerSlice.reducer
