import moment from 'moment'

const UnicornDetailsKeyPartners = ({ partners }) => {
  return (
    <>
      <div className='desktop-content'>
        <div className='grid-old unicorn-table'>
          <div className='row unicorn-table-header'>
            <div className='column three' style={{ paddingLeft: 0 }}>Date</div>
            <div className='column three'>Type</div>
            <div className='column three'>Business Partner</div>
          </div>
          { partners.map((f, i) =>
            <div key={`keyPartners_${i}`} className='row unicorn-table-body'>
              <div className='three column'>{f.date && moment(f.date).format('MM/DD/YYYY')}</div>
              <div className='three column'>{f.type}</div>
              <div className='three column'>{f.name}</div>
            </div>
          )}
        </div>
      </div>
      <div className='unicorn-card-container'>
        {partners && partners.map((f, i) =>
          <div className='unicorn-card' key={`mfundingRound_${i}`} >
            <div className='grid-old'>
              <div className='row unicorn-card-row'>
                <div className='column sixteen'>{f.date && moment(f.date).format('MM/DD/YYYY')}</div>
              </div>
              <div className='sixteen column row unicorn-card-row-line-sepretaor' />
              <div className='row unicorn-card-row'>
                <div className='column eight'><strong>Type: </strong></div>
                <div className='column eight'>{f.type}</div>
              </div>
              <div className='row unicorn-card-row'>
                <div className='column eight'><strong>Business Partner: </strong></div>
                <div className='column eight'>{f.name}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default UnicornDetailsKeyPartners
