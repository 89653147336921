import { Content } from 'components'
import { Mixpanel } from 'utils'

const ProductDocuments = ({ documents, type = 'order-details', selectedAccount }) => {
  const handleViewDocument = (name) => {
    if (type === 'order-details') {
      Mixpanel.track('View Document on Order Details Page', { 'Document Type': name })
    } else if (type === 'order-confirmation') {
      Mixpanel.track('View Document on Buy Order Review Page', { 'Document Type': name })
    } else if (type === 'bundle-order') {
      Mixpanel.track('View Document on Bundle Buy Order Review Page', { 'Document Type': name })
    }
  }

  return (
    <Content className='agreements'>
      {documents?.length > 0 && documents?.map((d) => {
        return (
          <a
            onClick={() => handleViewDocument(d.name)}
            className='agreement'
            key={d.name}
            href={`${d.url}${selectedAccount?.entityId && type !== 'recurring-investment' ? `&entityId=${selectedAccount.entityId}` : ''}`}
            target='_blank'
            rel='noopener noreferrer'
            aria-label={d.name}
            role='button'
            tabIndex={0}
          >
            <span className='medium_2' >{d.name}</span>
          </a>
        )
      })}
    </Content>
  )
}

export default ProductDocuments
