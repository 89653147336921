import { Wrapper, Image } from 'components'
import useWindowSize from 'hooks/useWindowSize'
import { useSelector } from 'react-redux'
import { MOBILE_WIDTH_SIZE } from 'utils'

const MobileInvestDetailHeader = (companyName) => (
  <h3 className='company-details__subheader accessibleIndigo'>Invest in {companyName}</h3>
)

const WebInvestDetailHeader = (vertical, founded, headquarters, website) => (
  <>
    <span className='medium_2 company-details__vertical gray3'>{vertical}</span>
    <div><span className='medium_2 gray3'>Founded:</span> <span className='medium_1 gray3'>{founded}</span></div>
    <div><span className='medium_2 gray3'>Headquarters:</span> <span className='medium_1 gray3'>{headquarters?.city}, {headquarters?.state}</span></div>
    <a target='_blank' rel='noopener noreferrer' href={`https://${website}`} className='medium_1 gray3'>{website}</a>
  </>
)

const InvestDetailHeader = () => {
  const { width } = useWindowSize()
  const { details } = useSelector(state => state.investSlice)

  const { founded, vertical, headquarters, website, name: companyName } = details || {}

  let additionalCopy = ''

  if (width <= MOBILE_WIDTH_SIZE) {
    additionalCopy = MobileInvestDetailHeader(companyName)
  } else {
    additionalCopy = WebInvestDetailHeader(vertical, founded, headquarters, website)
  }

  return (
    <Wrapper className='invest-header'>
      <div className='company-available'>
        <div className='company-details'>
          <Image
            className='invest-logo'
            alt={details.name}
            src={details.logoUrl}
          />
          {additionalCopy}
        </div>
      </div>
    </Wrapper>
  )
}
export default InvestDetailHeader
