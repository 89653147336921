import Modal from 'components/Global/Modal'
import { useSelector } from 'react-redux'
import { PageLoading, ConditionalRender } from 'components'

const AddFundsModal = ({ hideModal }) => {
  const { loading, bank } = useSelector(
    (state) => state.walletSlice
  )

  return (
    <Modal
      modalHeader='Wire Transfer Instructions'
      hideModal={hideModal}
      crossToClose
      clickToClose
      innerStyle='text-left'
    >
      <ConditionalRender isVisible={loading}>
        <PageLoading type='modal'/>
      </ConditionalRender>
      <ConditionalRender isVisible={!loading}>
        <p>
          You can add funds by sending a wire transfer from your bank account.
          You’ll need the following information:
        </p>
        <div className='flex-col'>
          <div className='mt-4'>
            <p className='header'>Bank Information </p>
            <div className='flex justify-start'>
              <p className='lg:min-w-44 min-w-[50%]'>Bank Country:</p> <p className='lg:min-w-44 min-w-[50%]'>United States of America</p>
            </div>
            <div className='flex justify-start'>
              <p className='lg:min-w-44 min-w-[50%]'>Routing Number:</p> <p className='lg:min-w-44 min-w-[50%]'>021000021</p>
            </div>
            <div className='flex justify-start'>
              <p className='lg:min-w-44 min-w-[50%]'>SWIFT/BIC:</p> <p className='lg:min-w-44 min-w-[50%]'>CHASUS33</p>
            </div>
            <div className='flex justify-start'>
              <p className='lg:min-w-44 min-w-[50%]'>Bank Name:</p> <p className='lg:min-w-44 min-w-[50%]'>JPMorgan Chase Bank N.A.</p>
            </div>
            <div className='flex justify-start'>
              <p className='lg:min-w-44 min-w-[50%]'>Bank Address:</p> <p className='lg:min-w-44 min-w-[50%]'>383 Madison Ave</p>
            </div>
            <div className='flex justify-start'>
              <p className='lg:min-w-44 min-w-[50%]'></p>
              <p className='lg:min-w-44 min-w-[50%]'>New York, NY 10017</p>
            </div>
          </div>
          <div className='mt-4'>
            <p className='header'>Recipient Information</p>
            <div className='flex justify-start'>
              <p className='lg:min-w-44 min-w-[50%]'>Recipient Name:</p> <p className='lg:min-w-44 min-w-[50%]'>Linqto Liquidshares LLC</p>
            </div>
            <div className='flex justify-start'>
              <p className='lg:min-w-44 min-w-[50%]'>Recipient Address:</p> <p className='lg:min-w-44 min-w-[50%]'><span>101 Metro Drive, </span><span>Suite 335</span></p>
            </div>
            <div className='flex justify-start'>
              <p className='lg:min-w-44 min-w-[50%]'></p> <p className='lg:min-w-44 min-w-[50%]'>San Jose, CA 95110</p>
            </div>
            <div className='flex justify-start'>
              <p className='lg:min-w-44 min-w-[50%]'>Account Number:</p> <p className='lg:min-w-44 min-w-[50%]'>{bank?.accountNumber}</p>
            </div>
            <div className='flex justify-start'>
              <p className='lg:min-w-44 min-w-[50%]'>Memo:</p> <p className='lg:min-w-44 min-w-[50%]'>F.B.O. {bank?.accountName}</p>
            </div>
          </div>
          <div className='mt-4'>
            <p>Notes:</p>
            <ul>
              <li>
                <strong>{bank?.accountName}</strong> must be listed as an owner of the
                bank account.
              </li>
              <li>
                Your bank may charge you a fee for sending a wire transfer or
                converting currency to USD. Please keep this in mind when
                determining the transfer amount. Contact your bank for fee
                inquiries.
              </li>
              <li>
              Wire Transfers are typically received and processed within 1 business day. We&apos;ll notify you via email when your deposit is available to use.
              </li>
            </ul>
          </div>
        </div>
      </ConditionalRender>
    </Modal>
  )
}

export default AddFundsModal
