import { Wrapper } from 'components'
import InvestDetailAboutHeader from './InvestDetailAboutHeader'
import InvestDetailAboutKeyFacts from './InvestDetailAboutKeyFacts'
import NewsAndInsights from './NewsAndInsights'
import { useSelector } from 'react-redux'
import InvestDetailWhyInvestInUs from './InvestDetailWhyInvestInUs'
const InvestDetailAbout = () => {
  const { details } = useSelector(state => state.investSlice)
  const { keyFacts, name } = details || {}
  return (
    <Wrapper className='invest-tab' id='about'>
      <div className='grid-old outer-container'>
        <h1 className='accessibleIndigo column sixteen outer-container about-margin'>About {name}</h1>
        <InvestDetailAboutHeader />
        <InvestDetailWhyInvestInUs />
        <div className='row outer-container container-margin'>
          <NewsAndInsights />
          <div className='column sixteen'>
            {keyFacts &&
            <>
              <div className='subheader-title accessibleIndigo'>
                <p className='large_1'>Key Facts</p>
              </div>
              <div className='desc'>
                <InvestDetailAboutKeyFacts keyFacts={keyFacts} />
              </div>
            </>
            }
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
export default InvestDetailAbout
