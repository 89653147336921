import Modal from 'components/Global/BaseModal'
import Button from 'components/Global/Button'
import { getDomain } from 'utils'
/**
 * @param {function} onClick
 * @param {boolean} loading
 * @returns Form CRS Modal JSX
 */
const FormCRSModal = ({ onClick, loading }) => {
  return (
    <Modal modalHeader='Form CRS'>
      <p className='medium_1'>
        As a broker dealer, Linqto is required to provide its users with a Customer Relationship Summary. By clicking “I Agree”, you acknowledge that you have read and agree to our&nbsp;
        <a className='underline medium_2 connectedCobalt pointer' target='_blank' rel='noopener noreferrer' href={getDomain('/crs', true)}>CRS Terms</a>.</p>
      <div className='inline-flex justify-center'>
        <Button onClick={onClick} loading={loading}>I Agree</Button>
      </div>
    </Modal>
  )
}

export default FormCRSModal
