const OriginationCopy = () => {
  return (
    <>
      <div className='medium_1'>
        <div className='large_1'>Why sell your shares to Linqto?</div>
        You don&apos;t have to wait for a broker to find someone to buy your shares. No negotiating price. No broker&apos;s commission. You only need to decide if you like Linqto&apos;s  price. </div>
    </>
  )
}

export default OriginationCopy
