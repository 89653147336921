import { useState } from 'react'
import AccreditationTypesModal from '../partials/AccreditationTypesModal'
import { status } from '../../data'
import { Button } from 'components'

const AccreditationHeaderRevamp = () => {
  const [showAccreditationTypesModal, setShowAccreditationTypesModal] = useState(false)
  return (
    <>
      <div className='large_1'>Verify Accreditation</div>
      <div className='medium_1 space-above-sm'>Regulations require you to be accredited to invest on Linqto. {' '}
        <Button className='link primary underlined in-line' onClick={() => setShowAccreditationTypesModal(true)} ariaLabel='Learn more about regulations require you to be accredited'>Learn more</Button> </div>
      {showAccreditationTypesModal &&
        <AccreditationTypesModal
          setShowAccreditationTypesModal={setShowAccreditationTypesModal}
          status={status}
        />
      }
    </>
  )
}

export default AccreditationHeaderRevamp
