import * as braze from '@braze/web-sdk'

const brazeAPIKey = {
  'dev.linqto.com': {
    key: 'b6537278-f994-46ae-937a-1a8a36cb477c',
    endpoint: 'sdk.iad-05.braze.com'
  },
  'dev.linqto.io': {
    key: 'b6537278-f994-46ae-937a-1a8a36cb477c',
    endpoint: 'sdk.iad-05.braze.com'
  },
  'dev.app.linqto.com': {
    key: 'b6537278-f994-46ae-937a-1a8a36cb477c',
    endpoint: 'sdk.iad-05.braze.com'
  },
  'app.dev.linqto.com': {
    key: 'b6537278-f994-46ae-937a-1a8a36cb477c',
    endpoint: 'sdk.iad-05.braze.com'
  },
  'app.dev.linqto.io': {
    key: 'b6537278-f994-46ae-937a-1a8a36cb477c',
    endpoint: 'sdk.iad-05.braze.com'
  },
  'alpha.linqto.com': {
    key: 'b6537278-f994-46ae-937a-1a8a36cb477c',
    endpoint: 'sdk.iad-05.braze.com'
  },
  'alpha.app.linqto.com': {
    key: 'b6537278-f994-46ae-937a-1a8a36cb477c',
    endpoint: 'sdk.iad-05.braze.com'
  },
  'app.alpha.linqto.com': {
    key: 'b6537278-f994-46ae-937a-1a8a36cb477c',
    endpoint: 'sdk.iad-05.braze.com'
  },
  'app.growth.linqto.com': {
    key: 'b6537278-f994-46ae-937a-1a8a36cb477c',
    endpoint: 'sdk.iad-05.braze.com'
  },
  'app.growth.linqto.io': {
    key: 'b6537278-f994-46ae-937a-1a8a36cb477c',
    endpoint: 'sdk.iad-05.braze.com'
  },
  'app.trade.linqto.com': {
    key: 'b6537278-f994-46ae-937a-1a8a36cb477c',
    endpoint: 'sdk.iad-05.braze.com'
  },
  'app.trade.linqto.io': {
    key: 'b6537278-f994-46ae-937a-1a8a36cb477c',
    endpoint: 'sdk.iad-05.braze.com'
  },
  'beta.linqto.com': {
    key: 'b6537278-f994-46ae-937a-1a8a36cb477c',
    endpoint: 'sdk.iad-05.braze.com'
  },
  'app.beta.linqto.com': {
    key: 'b6537278-f994-46ae-937a-1a8a36cb477c',
    endpoint: 'sdk.iad-05.braze.com'
  },
  'beta.app.linqto.com': {
    key: 'b6537278-f994-46ae-937a-1a8a36cb477c',
    endpoint: 'sdk.iad-05.braze.com'
  },
  'www.linqto.com': {
    key: '530c7563-594e-45d2-9290-5a1251c77123',
    endpoint: 'sdk.iad-05.braze.com'
  },
  'app.linqto.com': {
    key: '530c7563-594e-45d2-9290-5a1251c77123',
    endpoint: 'sdk.iad-05.braze.com'
  }
}

const Braze = (() => {
  return {
    isInitialized: false,
    initialize: () => {
      try {
        braze.initialize(brazeAPIKey[window.location.host].key, {
          baseUrl: brazeAPIKey[window.location.host].endpoint,
          enableLogging: window.location.host !== 'app.linqto.com',
          serviceWorkerLocation: '/serviceWorker.js'
        })
        braze.automaticallyShowInAppMessages()
        braze.subscribeToContentCardsUpdates()
      } catch (err) {
        console.log('err', err)
      }
      braze.openSession()
      Braze.isInitialized = true
      braze.requestPushPermission()
    },
    identify: (id) => {
      braze.changeUser(id)
    },
    track: (eventName, properties = {}) => {
      braze.logCustomEvent(eventName, properties)
    },
    setUserAttribute: (attributes = {}) => {
      braze.getUser().setCustomUserAttribute(attributes)
    },
    triggerInAppMsg: (message) => {
      message = new braze.SlideUpMessage(message)
      message.slideFrom = braze.InAppMessage.SlideFrom.TOP
      braze.showInAppMessage(message)
    },
    retrieveContentCards: () => {
      const brazeCards = braze.getCachedContentCards()
      return brazeCards
    }
  }
})()
export { Braze }
