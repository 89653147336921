import RegionCountryDropdown from 'components/Global/Inputs/RegionCountryDropdown'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { getRegions } from 'slices/commonSlice'
import DocumentTypeDropdown from './DocumentTypeDropdown'
import UploadDocument from './UploadDocument'
import UserIdentityAndAddress from './UserIdentityAndAddress'

const documentTypes = [{ label: 'Passport', value: 'PASSPORT' }, { label: 'Driver\'s License', value: 'DRIVERS_LICENSE' }, { label: 'Other', value: 'OTHER' }]

const ManualVerificationForm = () => {
  const dispatch = useDispatch()
  const { countries } = useSelector(state => state.commonSlice)
  const { watch, setValue, formState: { isSubmitting } } = useFormContext()

  const idType = watch('idType')
  const showAdditionalContent = !!watch('country')

  const fetchRegions = async (e) => {
    const country = e?.target?.value
    if (country !== '') {
      await dispatch(getRegions(country))
      setValue('state', '')
    }
  }

  let documentUploadJSX = ''
  let userIdentityAndAddressJSX = ''

  if (showAdditionalContent) {
    documentUploadJSX = (
      <>
        {idType === 'PASSPORT' &&
        <>
          <div className='sub-header'>Upload Your Identification Documents</div>
          <UploadDocument documentName='Passport' name='idFrontFile' disabled={isSubmitting} />
        </>}
        {(idType === 'DRIVERS_LICENSE' || idType === 'OTHER') && (<>
          <div className='sub-header'>Upload the front of ID document, right side up.</div>
          <UploadDocument documentName={'Driver\'s License (Front Side)'} name='idFrontFile' disabled={isSubmitting} />
          <div className='sub-header'>Back side {idType === 'OTHER' && <span>(if document is two-sided)<span className='optional'>(optional)</span></span>}</div>
          <UploadDocument documentName={'Driver\'s License (Back Side)'} name='idBackFile' disabled={isSubmitting} />
        </>)}
        <div className='sub-header'>Upload self-photo</div>
        <UploadDocument documentName='Self-photo.' name='selfieFile' disabled={isSubmitting} />
      </>
    )
    userIdentityAndAddressJSX = <UserIdentityAndAddress />
  }

  return (
    <>
      <div className='grid-old no-margin-bottom'>
        <div className='three column eight-tablet sixteen-mobile'>
          <RegionCountryDropdown items={countries} type='countries' name='country' label='Select your country of residence' ariaLabel='country-dropdown' disabled={isSubmitting} onChange={fetchRegions} />
        </div>
        {showAdditionalContent && <div className='column ten eight-tablet sixteen-mobile'>
          <DocumentTypeDropdown items={documentTypes} name='idType' label='Government-issued photo ID type (must contain legal name and date of birth)' ariaLabel='id-type' disabled={isSubmitting} className='max-length'/>
        </div>}
      </div>
      {documentUploadJSX}
      {userIdentityAndAddressJSX}
    </>
  )
}

export default ManualVerificationForm
